import {
  ADD_ITEM_CART_DETAIL,
  REMOVE_ITEM_CART_DETAIL,
  EMPTY_CART_DETAIL,
  UPDATE_ITEM_CART_DETAIL,
  CART_DETAIL_RECEIVED,
  ORDER_PLACE_DETAIL_RECEIVED,
  EMPTY_ORDER_PLACE_DETAIL,
  CART_TAX_DETAIL_RECEIVED,
  EMPTY_CART_TAX_DETAIL,
  CART_DETAIL_STATUS_CHANGED,
  CHANGE_ORDER_SOCKET_EMIT,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_ORDER_HISTORY_DETAIL,
  VOUCHER_LIST_DETAIL_RECEIVED,
  EMPTY_VOUCHER_LIST_DETAIL,
  RECENT_ORDER_ID_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  GET_APPLY_VOUCHER_DETAIL,
  APPLY_VOUCHER_DETAIL_RECEIVED,
  EMPTY_APPLY_VOUCHER_DETAIL,
  TOGGLE_CART_PAGE,
  REMOVE_ITEM_VOUCHER_DETAIL,
  REORDER_ORDER_IDS_RECEIVED,
  EMPTY_REORDER_ORDER_IDS,
  ADD_PROMOCODE,
  EMPTY_PROMOCODE,
  CHANGE_ORDER_TIP,
  POS_CART_DETAIL_RECEIVED,
  EMPTY_POS_CART_DETAIL,
  SHOW_COMPLETE_PAGE_TOGGLE,
} from "../actions/types";

const initialState = {
  cart_detail: [],
  pos_cart_detail: [],
  recent_order_detail: null,
  recent_order_id: "",
  order_history_detail: null,
  voucher_list_detail: null,
  order_socket_emit: false,
  show_cart_popup: false,
  total_cart_amount: null,
  total_service_charge: null,
  total_sale_tax: null,
  total_discount: null,
  total_tip: 0,
  applied_voucher: [],
  reorder_item_ids: [],
  applied_promocode: null,
  show_complete_page: false,
};

const cartDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_CART_DETAIL:
      return { ...state, cart_detail: [...state.cart_detail, action.item] };
    case UPDATE_ITEM_CART_DETAIL:
      return {
        ...state,
        cart_detail: [
          ...state.cart_detail.slice(0, action.index),
          action.item,
          ...state.cart_detail.slice(action.index + 1),
        ],
      };
    case REMOVE_ITEM_CART_DETAIL:
      return {
        ...state,
        cart_detail: state.cart_detail.filter(
          (item, index) => index !== action.index
        ),
      };
    case CART_DETAIL_RECEIVED:
      return { ...state, cart_detail: action.cart_detail };
    case TOGGLE_CART_PAGE:
      return { ...state, show_cart_popup: action.payload };
    case CART_DETAIL_STATUS_CHANGED:
      return {
        ...state,
        cart_detail: state.cart_detail.map((item) => {
          item.is_added = true;
          return item;
        }),
      };
    case EMPTY_CART_DETAIL:
      return { ...state, cart_detail: [] };
    case POS_CART_DETAIL_RECEIVED:
      return { ...state, pos_cart_detail: action.cart_detail };
    case EMPTY_POS_CART_DETAIL:
      return { ...state, pos_cart_detail: [] };
    case EMPTY_REORDER_ORDER_IDS:
      return { ...state, reorder_item_ids: [] };
    case REORDER_ORDER_IDS_RECEIVED:
      return { ...state, reorder_item_ids: action.payload };
    case ORDER_PLACE_DETAIL_RECEIVED:
      return { ...state, recent_order_detail: action.payload };
    case EMPTY_ORDER_PLACE_DETAIL:
      return { ...state, recent_order_detail: null };
    case RECENT_ORDER_ID_RECEIVED:
      return { ...state, recent_order_id: action.payload };
    case EMPTY_RECENT_ORDER_ID:
      return { ...state, recent_order_id: "" };
    case ADD_PROMOCODE:
      return { ...state, applied_promocode: action.payload };
    case EMPTY_PROMOCODE:
      return { ...state, applied_promocode: null };
    case CART_TAX_DETAIL_RECEIVED:
      return {
        ...state,
        total_cart_amount: action.total_cart_amount ?? null,
        total_service_charge: action.total_service_charge ?? null,
        total_sale_tax: action.total_sale_tax ?? null,
        total_discount: action.total_discount ?? null,
        total_tip: action.total_tip ?? 0,
      };
    case EMPTY_CART_TAX_DETAIL:
      return {
        ...state,
        total_cart_amount: null,
        total_service_charge: null,
        total_sale_tax: null,
        total_discount: null,
        total_tip: 0,
      };
    case ORDER_HISTORY_DETAIL_RECEIVED:
      return { ...state, order_history_detail: action.payload };
    case CHANGE_ORDER_TIP:
      return { ...state, total_tip: action.payload };
    case EMPTY_ORDER_HISTORY_DETAIL:
      return { ...state, order_history_detail: null };
    case VOUCHER_LIST_DETAIL_RECEIVED:
      return { ...state, voucher_list_detail: action.payload };
    case EMPTY_VOUCHER_LIST_DETAIL:
      return { ...state, voucher_list_detail: null };
    case APPLY_VOUCHER_DETAIL_RECEIVED:
      return { ...state, applied_voucher: action.payload };
    case EMPTY_APPLY_VOUCHER_DETAIL:
      return { ...state, applied_voucher: [] };
    case SHOW_COMPLETE_PAGE_TOGGLE:
      return { ...state, show_complete_page: action.payload };
    case REMOVE_ITEM_VOUCHER_DETAIL:
      return {
        ...state,
        applied_voucher: state.applied_voucher.filter(
          (item, index) => index !== action.index
        ),
      };
    case CHANGE_ORDER_SOCKET_EMIT:
      return { ...state, order_socket_emit: action.payload };
    default:
      return state;
  }
};
export default cartDetailReducer;
