import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";

import AccountPopup from "../Components/AccountPopup";
import RatingInput from "../Components/RatingInput";
import {
  changeLoadingStatus,
  clearCacheStatus,
  getSelectedItemDetail,
  orderPlaceDetail,
  showNotificationWithTimeout,
  toggleShowCompletePage,
  updateRatingDetail,
} from "../../Redux/actions";
import {
  getModifierActionSign,
  getModifierActionName,
  checkNegNum,
  makeNegToPosNum,
  sortCartItems,
  parseRound,
  checkArray,
  checkNull,
} from "../../helpers";

import {} from "../../Assets";
import { APPLY_VOUCHER_DETAIL_RECEIVED } from "../../Redux/actions/types";

function Completed() {
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer.user_detail
  );
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const total_tip = useSelector((state) => state.cartDetailReducer?.total_tip);
  useEffect(() => {
    // dispatch(changeLoadingStatus(false));
    dispatch(showNotificationWithTimeout("Order Completed!", "success"));
    dispatch(clearCacheStatus(true));
    document.body.classList.add("completed");
    return () => {
      dispatch(toggleShowCompletePage(false));
      document.body.classList.remove("completed");
    };
  }, []);

  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount
  );
  const [totalServiceCharge, setTotalServiceCharge] =
    useState(defaultServiceCharge);
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalTip, setTotalTip] = useState(`${total_tip}`);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);

  let defaultDiscountTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    let defaultPromoDiscount = 0;
    // promotion discount
    if (
      cart_detail &&
      checkArray(
        cart_detail.filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
      )
    ) {
      cart_detail
        .filter((p) => p?.promotions?.type !== "Re-Price")
        .map((item) => {
          defaultPromoDiscount =
            defaultPromoDiscount +
            parseFloat(item?.promotions?.discountPerItem ?? 0) *
              parseInt(item?.quantity ?? 0);
        });
    }
    // promotion discount END
    let defaultTotalDiscount = 0;
    //applied_voucher
    if (applied_voucher && applied_voucher.length > 0) {
      const newApplyVoucher = applied_voucher.map((applyVoucher) => {
        if (applyVoucher?.Type == "Cash") {
          const discountAmt = parseFloat(applyVoucher?.TypeValue ?? 0);
          applyVoucher.discountAmt = discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        } else if (applyVoucher?.Type == "Discount") {
          const discountAmt =
            (parseFloat(applyVoucher?.TypeValue ?? 0) *
              defaultDiscountTotalCartAmount) /
            100;
          applyVoucher.discountAmt = discountAmt;
          defaultDiscountTotalCartAmount =
            defaultDiscountTotalCartAmount - discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        }
        return applyVoucher;
      });
      dispatch({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload: newApplyVoucher,
      });
    }
    if (applied_promocode && applied_promocode !== null) {
      defaultTotalDiscount = defaultTotalDiscount + getPromotionTotalDis();
    }
    // applied_voucher END
    setTotalDiscount(defaultTotalDiscount);
    setPromoDiscount(defaultPromoDiscount);
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [showAccountPopUp, cart_detail]);

  const jwtSecret = process?.env?.REACT_APP_JWT_SECRET;

  const history = useHistory();

  function getSameBundleItemIndex(item, bundle) {
    let res = -1;
    if (item?.BundledMenuItem?.length > 0) {
      for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
        const element = item.BundledMenuItem[index];
        if (
          element.id != bundle.id &&
          element.option_series_id == bundle.option_series_id
        ) {
          res = index;
          break;
        }
      }
    }
    return res;
  }

  const ratingChangeHandler = useCallback(
    (value) => {
      const merchantId = user_detail?.merchant_id;
      const customerId = user_detail?.id;
      dispatch(
        updateRatingDetail({
          rating: value ?? "",
          merchant_id: merchantId ?? "",
          customer_id: customerId ?? "",
          order_id: recent_order_detail?.order_list?.[0]?.order_offline_id??"", 
          review: "", 
        })
      );
    },
    [user_detail]
  );

  function getModifierItems(item) {
    let res = null;
    if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
      res = item.modifierArray
        .filter((p) => p.action != "discount")
        .map((modifier, modifierIndex) => {
          const priceTag = `(${getModifierActionSign(modifier.action)}${
            table_detail?.currency ?? ""
          }${parseFloat(modifier.unit_price).toFixed(2)})`;
          console.log("modifier.unit_price", modifier.unit_price);
          return (
            <ul className="other-options" key={modifierIndex}>
              {modifier.unit_price !== 0 && (
                <li>
                  {`${getModifierActionName(modifier.action)} ${modifier.name}`}{" "}
                  ({checkNegNum(modifier.unit_price) ? "- " : ""}
                  {`${table_detail?.currency ?? ""}`}
                  {parseFloat(
                    checkNegNum(modifier.unit_price)
                      ? makeNegToPosNum(modifier.unit_price)
                      : modifier.unit_price
                  ).toFixed(2)}
                  )
                </li>
              )}
              {modifier.unit_price === 0 && <li>{`${modifier.name}`}</li>}
            </ul>
          );
        });
    } else if (
      item?.menu_item_type == "bundled" &&
      item?.BundledMenuItem?.length > 0
    ) {
      res = item.BundledMenuItem.filter((bunItem) => {
        let filterRes = true;
        if (
          item?.hide_fixed_items_qr == "yes" &&
          bunItem?.option_series_name == "Fixed Items" &&
          bunItem?.bundledMenuitemModifers?.length == 0
        ) {
          filterRes = false;
        }
        return filterRes;
      })
        .sort(
          (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
        )
        .map((bundle, bundleIndex) => {
          let modiferArr = null;
          if (bundle?.bundledMenuitemModifers?.length > 0) {
            modiferArr = bundle.bundledMenuitemModifers
              .filter((p) => p.action != "discount")
              .map((modifier, modifierIndex) => {
                const priceTag =
                  modifier.unit_price > 0
                    ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                    : "";
                return (
                  <ul className="other-options" key={modifierIndex}>
                    <li>
                      {modifier.unit_price !== 0 && (
                        <>
                          {`${getModifierActionName(modifier.action)} ${
                            modifier.name
                          }`}{" "}
                          ({checkNegNum(modifier.unit_price) ? "- " : ""}
                          {`${table_detail?.currency ?? ""}`}
                          {parseFloat(
                            checkNegNum(modifier.unit_price)
                              ? makeNegToPosNum(modifier.unit_price)
                              : modifier.unit_price
                          ).toFixed(2)}
                          )
                        </>
                      )}
                      {modifier.unit_price === 0 && (
                        <>{`${
                          !["add", "remove", "swap"].includes(modifier.action)
                            ? "" + modifier.action + ""
                            : ""
                        }${modifier.name}`}</>
                      )}
                      {`${
                        modifierIndex + 1 !=
                        bundle.bundledMenuitemModifers.length
                          ? ","
                          : ""
                      }`}
                    </li>
                  </ul>
                );
              });
          }
          const bundlePriceTag =
            bundle.amount > 0
              ? `(@${parseFloat(bundle.amount).toFixed(2)})`
              : "";
          const bundleQuantityTag =
            bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
          let alreadyExist = getSameBundleItemIndex(item, bundle);
          const showComma =
            alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
          return (
            <ul className="other-options" key={bundleIndex}>
              <li>
                {showComma && alreadyExist > -1 ? "," : ""}
                {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
                {bundle?.bundle_menu_item_name}
                {`${bundleQuantityTag}`}
                {`${bundlePriceTag}`}
                {modiferArr}
              </li>
            </ul>
          );
        });
    }
    return res;
  }

  function getCartItems() {
    if (checkArray(cart_detail) && checkArray(cart_detail.filter(p=>p.is_added == true))) {
      return sortCartItems(cart_detail.filter(p=>p.is_added == true)).map(function (item, itemIndex) {
        return (
          <div className="new_items" key={itemIndex}>
            <div
              className={`menu-box row ${
                item?.is_added == true ? "added" : ""
              }`}
            >
              <div className="d-flex orderMenu">
                <figure className="menu-img">
                  <ImageTag
                    src={item.image}
                    defaultimage={table_detail?.Merchant?.logo}
                    removeImage={true}
                  />
                </figure>
                <div className="col-7 menu-content pl-0">
                  <h4>
                    <span className="txt-green">{item.quantity}x</span>{" "}
                    {item.pos_name} @{parseFloat(item.item_amount).toFixed(2)}{" "}
                  </h4>
                  {getModifierItems(item)}
                </div>
                <div className="col-2 px-0 text-right">
                  <p className="pricing-lg">
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseFloat(item.total).toFixed(2)}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  }

  const getPromotionTotalDis = () => {
    let res = getBasketTotalAmount(true);
    let promotionDiscount = 0;
    if (applied_promocode && applied_promocode !== null) {
      if (applied_promocode.type == "Amount") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Percentage") {
        promotionDiscount = (res * parseFloat(applied_promocode.amount)) / 100;
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Re-Price") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = promotionDiscount;
      }
    }
    return res;
  };

  function getBasketTotalAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {parseRound(res)}
      </>
    );
  }

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className="wrapper cart_wrapper custom_pb"
          // onScroll={scrolledElement}
          // {...handlers}
        >
          <Header
            backTitle={"Add New Items"}
            onTabProfile={() => setShowAccountPopUp(1)}
            hideHeader={true}
            // style={{
            //   height: `220px`,
            // }}
            backPath={undefined}
            rtComponent={
              <>
                <div className="hotel_name">
                  <div className="circle-img">
                    <figure className="">
                      <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                    </figure>
                  </div>
                  <div className="naming-sec text-center">
                    <h4> {table_detail?.Merchant?.account_name} </h4>
                    <p>
                      {" "}
                      Table #{
                        table_detail?.PosTableMaster?.table_number
                      } Basket{" "}
                    </p>
                  </div>

                  <div className="desc-sec-top">
                    <h4> Your order is complete! </h4>
                    <p>
                      {" "}
                      You can start a new order by scaning a fresh table QR
                      code.{" "}
                    </p>
                  </div>
                  <div className="mt-3 w-100">
                    <div className="rating_row">
                      <p className="text-center">
                        Tap to rate your experience, slide for half star
                      </p>
                      <div className="rating_outer">
                        <RatingInput
                          // star={parseFloat(user_detail?.rating ?? 0)}
                          star={0}
                          ratingChangeHandler={ratingChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <div className={`outerScroll ${hideHeader ? "scrollup" : ""}`}>
            <div className="col-12 pt-5 p-3">
              <h5 className="md-title bold"> Order Summary </h5>
            </div>
            <section className="middle-sec cart-sec-ms new_items_page">
              {getCartItems()}
            </section>
            {/* {table_detail?.SubCategory &&
            table_detail.SubCategory.findIndex(
              (subCat) =>
                subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
            ) > -1 && (
              <section className="recommended">
                <h3 className="title"> You Might Also Like </h3>
                <div className="row also-like-sec">{getPopularItems()}</div>
              </section>
            )} */}
            <section className="billing-details mb-5">
              <ul className="billing-list">
                <li>
                  {" "}
                  <span> Sub Total </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`} */}
                    {parseRound(
                      totalCartAmount + totalDiscount + promoDiscount
                    )}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Discounts </span>{" "}
                  <strong>
                    {" "}
                    (<small>{`${table_detail?.currency ?? ""}`}</small>
                    {`${parseRound(totalDiscount + promoDiscount)}`})
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Service Charges </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalServiceCharge)}
                  </strong>
                </li>

                <li>
                  {" "}
                  <span> Tax </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalSaleTax)}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Gratuity </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalTip).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalTip)}
                  </strong>
                </li>
                <li className="total">
                  {" "}
                  <span> Total </span>{" "}
                  <strong> {getBasketTotalAmount()}</strong>
                </li>
              </ul>
            </section>
            {/* account modal */}

            <AccountPopup
              show={showAccountPopUp}
              onCancel={() => setShowAccountPopUp(0)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default Completed;
