import React, { Suspense, useState, useEffect, useRef } from "react";
import { DeviceUUID } from "device-uuid";
import { connect, useDispatch } from "react-redux";
import PageVisibility from "react-page-visibility";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  changeOrderSocketEmit,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  clearCacheStatus,
  changeStatusCartDetail,
  emptyTableDetail,
  getTableDetail,
  changeLoadingStatus,
  changeTipDetail,
  checkOpenOrder,
  changePosCartDetail,
  posOrderRecieve,
} from "../Redux/actions";
import io from "socket.io-client";
import Loading from "../Scenes/Components/Loading";
import ToastMessage from "../Scenes/Components/ToastMessage";
import { LoginScreen } from "../Scenes/AuthScreens";
import NotFoundPage from "../Scenes/Components/NotFoundPage";
import {
  setPageDetails,
  getCartSubmittedItems,
  getSessionStorageOrDefault,
  checkExpireMerchantTime,
  getPosCartSubmittedItems,
} from "../helpers";
import { authRoutes, tableRoutes } from "../Utils";
import moment from "moment";

const Main = ({
  user_detail,
  table_detail,
  show_session_expired_page,
  cart_detail,
  selected_item_detail,
  page_title,
  loading,
  recent_order_detail,
  order_socket_emit,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  changeStatusCartDetail,
  emptyTableDetail,
  getTableDetail,
  checkOpenOrder,
  clear_cache,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [throttleTimeout, setThrottleTimeout] = useState(null);
  const throttleTimeoutRef = useRef(throttleTimeout);
  throttleTimeoutRef.current = throttleTimeout;

  console.log("user_detail", user_detail);
  console.log("page_title", page_title);
  var uuid = "not-valid";
  const merchantId = table_detail?.Merchant?.id;
  const tableNumber = table_detail?.PosTableMaster?.table_number ?? "";
  const tableId = table_detail?.PosTableMaster?.qr_code_id;
  console.log("loading", loading);

  const checkExpiredSession = () => {
    // check service period
    const servicePeriodExpire = getSessionStorageOrDefault(
      "servicePeriodExpire",
      undefined
    );
    if (servicePeriodExpire) {
      console.log("servicePeriodExpire :>> ", servicePeriodExpire);
      const isExpiredServicePeriod = checkExpireMerchantTime(
        servicePeriodExpire,
        table_detail?.Merchant?.timezone_offset ?? "00:00"
      );
      if (isExpiredServicePeriod && !loading) {
        sessionExpiredHandler();
        console.log("isExpiredServicePeriod :>> ", isExpiredServicePeriod);
        // getTableDetail(tableId, uuid, true);
      }
    }
    // check service period END
  };

  const checkInactiveSession = () => {
    // check service period
    const inactiveSession = getSessionStorageOrDefault(
      "session_start_time",
      undefined
    );
    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    if (inactiveSession) {
      const inactiveSessionTime = moment(inactiveSession, timeFormat);
      const isExpired = moment().isAfter(inactiveSessionTime);
      console.log("inactiveSession :>> ", isExpired, inactiveSession);
      if (isExpired && !loading) {
        sessionExpiredHandler();
        // getTableDetail(tableId, uuid, true);
      }
    } else {
      sessionStorage.setItem(
        "session_start_time",
        JSON.stringify(moment().add(20, "minutes").format(timeFormat))
      );
    }
    // check service period END
  };

  useEffect(() => {
    setPageDetails({
      title: page_title,
    });
    const projectType = process?.env?.REACT_APP_TYPE;
    console.log("projectType=====>", projectType, projectType == "production");
    let socketUrl = "https://uatsocket.gogmgo.com:2020";
    if (projectType == "production") {
      socketUrl = "https://erpsocket.gogmgo.com:2020";
    }
    // const newSocket = io(`http://13.229.17.190:2020`, {
    //   rejectUnauthorized: false,
    //   transports: ["websocket"], // use WebSocket first, if available
    // });
    const newSocket = io(`${socketUrl}`, {
      rejectUnauthorized: false,
      transports: ["websocket"], // use WebSocket first, if available
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  // useEffect(() => {
  //   checkExpiredSession();
  //   if (recent_order_detail === null) {
  //     checkInactiveSession();
  //   }
  // }, [table_detail, cart_detail, selected_item_detail, recent_order_detail]);

  // useEffect(() => {
  //   if (show_session_expired_page) {
  //     sessionExpiredHandler();
  //   }
  // }, [show_session_expired_page]);

  // useEffect(() => {
  //   if (
  //     loading === false &&
  //     !clear_cache &&
  //     table_detail &&
  //     table_detail !== null
  //   ) {
  //     checkOpenOrder(merchantId, uuid, tableNumber);
  //   }
  // }, [selected_item_detail]);

  const throttle = (func, limit) => {
    return function () {
      const args = arguments;
      const context = this;
      if (throttleTimeoutRef.current === null) {
        const trottleTime = setTimeout(() => {
          func.apply(context, args);
          setThrottleTimeout(null);
        }, limit);
        setThrottleTimeout(trottleTime);
      }
    };
  };
  const throttleNotification = throttle(function () {
    showNotificationWithTimeout("Order Completed!", "success");
  }, 3000);

  const completeOrderHandler = () => {
    dispatch(changeLoadingStatus(true));
    dispatch(clearCacheStatus(true));
    // deletRecentAndCartOrder();
    throttleNotification();
    window.location.href = "../completed";
  };
  const sessionExpiredHandler = () => {
    dispatch(changeLoadingStatus(true));
    dispatch(clearCacheStatus(true));
    window.location.href = "../session-expired";
  };
  console.log("socket", socket);
  if (socket) {
    socket.on("connect", function () {
      console.log("connected");
    });

    socket.on(`pos_to_ordrr_${user_detail?.id}`, (...args) => {
      console.log("order_socket", args);
      if (
        !loading &&
        args?.[0]?.["message"]?.["order_offline_id"] !== undefined &&
        recent_order_detail?.order_list?.length > 0 &&
        recent_order_detail?.order_list?.findIndex(
          (p) =>
            p.order_offline_id != "" &&
            p.order_offline_id == args?.[0]?.["message"]?.["order_offline_id"]
        ) > -1
      ) {
        const pos_order = args?.[0]?.["message"];
        if (!loading) {
          dispatch(posOrderRecieve(pos_order));
        }
        // if (!loading && pos_order?.order_status == "complete") {
        //   completeOrderHandler();
        // }
      }
    });
    socket.on("item_availability_" + merchantId, (...args) => {
      console.log("item_availability_", args);
      if (!loading) {
        getTableDetail(tableId, uuid);
      }
    });
    // socket.on("disconnect", () => this.disconnect());
    socket.on("connect_error", (err) => {
      console.log("connect_error due to", err);
      // console.log(`connect_error due to ${err.message}`);
    });
  }

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      if (isPageVisible == false) {
        checkOpenOrder(merchantId, uuid, tableNumber);
      }
      setIsPageVisible(isVisible);
    } else {
      setIsPageVisible(false);
    }
  };

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <Suspense fallback={<Loading show={true} />}>
        <Loading show={loading} />
        <ToastMessage />
        <div>
          <Router history={history}>
            <Switch>
              <Route exact path={"/"} component={LoginScreen} />
              {authRoutes.map((route, index) => {
                return (
                  <Route
                    exact
                    path={route.path}
                    component={route.component}
                    key={index}
                  />
                );
              })}
              {!(user_detail === null || user_detail === undefined) &&
                tableRoutes.map((route, index) => {
                  return (
                    <Route
                      exact
                      path={route.path}
                      component={route.component}
                      key={index}
                    />
                  );
                })}
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </div>
      </Suspense>
    </PageVisibility>
  );
};
const mapStateToProps = (state) => ({
  isLogin: state.tableDetailReducer.isLogin,
  user_detail: state.authDetailReducer.user_detail,
  table_detail: state.tableDetailReducer.table_detail,
  show_session_expired_page: state.tableDetailReducer.show_session_expired_page,
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  loading: state.loaderReducer.loading,
  page_title: state.tableDetailReducer.page_title,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  order_socket_emit: state.cartDetailReducer.order_socket_emit,
  clear_cache: state.tableDetailReducer.clear_cache,
});

const mapDispatchToProps = {
  changeOrderSocketEmit,
  changeStatusCartDetail,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  emptyTableDetail,
  getTableDetail,
  checkOpenOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
