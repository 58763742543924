import {
  SEND_OTP_DETAIL_RECEIVED,
  EMPTY_OTP_DETAIL,
  USER_DETAIL_RECEIVED,
  EMPTY_USER_DETAIL,
  EMAIL_INPUT_RECEIVED,
  OTP_INPUT_RECEIVED,
  SHOW_OTP_INPUT_TOGGLE,
  CARD_DETAIL_RECEIVED,
  EMPTY_CARD_DETAIL,
  MEMBER_DETAIL_RECEIVED,
  EMPTY_MEMBER_DETAIL,
  PHONE_INPUT_RECEIVED,
  PHONE_CODE_INPUT_RECEIVED,
  PHONE_PIN_INPUT_RECEIVED,
  SHOW_PHONE_PIN_INPUT_TOGGLE,
  SHOW_PHONE_AGREEMENT_INPUT_TOGGLE,
  PHONE_AGREEMENT_INPUT_RECEIVED,
  PHONE_AGREEMENT_DETAIL_RECEIVED,
  AVOCADO_DETAIL_RECEIVED,
  AVOCADO_MEMBER_LIST_RECEIVED,
  RATING_INPUT_RECEIVED,
  ADD_DETAIL_CARD_TOKEN,
  XGATE_INPUT_RECEIVED,
  XGATE_INPUT_EMPTY,
  XGATE_DETAILS_RECEIVED,
  XGATE_DETAILS_EMPTY,
  XGATE_COUPON_RECEIVED,
  XGATE_COUPON_EMPTY,
  XGATE_POINTS_RECEIVED,
  XGATE_POINTS_EMPTY,
  TOGGLE_XGATE_POINTS_POPUP,
} from "../actions/types";

const initialState = {
  otp_detail: null,
  user_detail: null,
  card_detail: null,
  avocado_detail: null,
  avocado_member_list: [],
  member_detail: null,
  email_input: "",
  otp_input: "",
  phone_input: "",
  phone_code_input: "",
  phone_pin_input: "",
  show_otp_input: false,
  show_phone_pin_input: false,
  phone_agreement_detail: "",
  phone_agreement_input: false,
  show_phone_agreement_input: false,
  xgate_inputs: {
    mobile: "",
    card: "",
    email: "",
  },
  xgate_details: null,
  xgate_coupons: null,
  xgate_points: null,
  xgate_points_popup: false,
};

const authDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_DETAIL_RECEIVED:
      return { ...state, otp_detail: action.payload };
    case EMPTY_OTP_DETAIL:
      return { ...state, otp_detail: null };
    case USER_DETAIL_RECEIVED:
      return {
        ...state,
        user_detail: action.payload,
        email_input: "",
        otp_input: "",
        show_otp_input: false,
      };
    case RATING_INPUT_RECEIVED:
      return {
        ...state,
        user_detail: { ...state.user_detail, rating: action.payload },
      };
    case XGATE_INPUT_RECEIVED:
      return {
        ...state,
        xgate_inputs: {
          ...state.xgate_inputs,
          [action.input]: action.value,
        },
      };
    case XGATE_DETAILS_RECEIVED:
      return {
        ...state,
        xgate_details: action.payload,
      };
    case XGATE_DETAILS_EMPTY:
      return {
        ...state,
        xgate_details: null,
      };
    case XGATE_COUPON_RECEIVED:
      return {
        ...state,
        xgate_coupons: action.payload,
      };
    case XGATE_COUPON_EMPTY:
      return {
        ...state,
        xgate_coupons: null,
      };
    case XGATE_POINTS_RECEIVED:
      return {
        ...state,
        xgate_points: action.payload,
      };
    case XGATE_POINTS_EMPTY:
      return {
        ...state,
        xgate_points: null,
      };
    case XGATE_INPUT_EMPTY:
      return {
        ...state,
        xgate_inputs: {
          mobile: "",
          card: "",
        },
      };
    case TOGGLE_XGATE_POINTS_POPUP:
      return {
        ...state,
        xgate_points_popup: action.payload,
      };
    case EMPTY_USER_DETAIL:
      return { ...state, user_detail: null };
    case EMAIL_INPUT_RECEIVED:
      return { ...state, email_input: action.input };
    case OTP_INPUT_RECEIVED:
      return { ...state, otp_input: action.input };
    case PHONE_INPUT_RECEIVED:
      return { ...state, phone_input: action.input };
    case PHONE_CODE_INPUT_RECEIVED:
      return { ...state, phone_code_input: action.input };
    case PHONE_PIN_INPUT_RECEIVED:
      return { ...state, phone_pin_input: action.input };
    case PHONE_AGREEMENT_INPUT_RECEIVED:
      return { ...state, phone_agreement_input: action.input };
    case PHONE_AGREEMENT_DETAIL_RECEIVED:
      return { ...state, phone_agreement_detail: action.input };
    case AVOCADO_DETAIL_RECEIVED:
      return { ...state, avocado_detail: action.payload };
    case AVOCADO_MEMBER_LIST_RECEIVED:
      return { ...state, avocado_member_list: action.payload };
    case SHOW_PHONE_PIN_INPUT_TOGGLE:
      return { ...state, show_phone_pin_input: action.input };
    case SHOW_PHONE_AGREEMENT_INPUT_TOGGLE:
      return { ...state, show_phone_agreement_input: action.input };
    case SHOW_OTP_INPUT_TOGGLE:
      return { ...state, show_otp_input: action.input };
    case CARD_DETAIL_RECEIVED:
      return {
        ...state,
        card_detail: action.payload,
      };
    case EMPTY_CARD_DETAIL:
      return { ...state, card_detail: null };
    case ADD_DETAIL_CARD_TOKEN:
      return {
        ...state,
        user_detail: { ...state.user_detail, card_token: action.payload },
      };
    case MEMBER_DETAIL_RECEIVED:
      return {
        ...state,
        member_detail: action.payload,
      };
    case EMPTY_MEMBER_DETAIL:
      return { ...state, member_detail: null };
    default:
      return state;
  }
};
export default authDetailReducer;
