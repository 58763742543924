import React, { useCallback, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { LoyalityAccountScreen, LoyalityHistoryScreen } from "../Screens/.";
import { getOrderHistoryDetail } from "../../Redux/actions";
import Vouchers from "../Screens/Vouchers";
import { Tabbar } from "./Tabbar";
import ImageTag from "../Components/ImageTag";

const AccountPopup = ({ show, onCancel, tabType }) => {
  const dispatch = useDispatch();
  const defaultActiveTab = tabType !== undefined ? tabType : 0;
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const avocadoDetail = useSelector(
    (state) => state?.authDetailReducer?.avocado_detail
  );
  const cardDetail = useSelector(
    (state) => state?.authDetailReducer?.card_detail
  );
  const xgateDetails = useSelector(
    (state) => state?.authDetailReducer?.xgate_details
  );

  const switchTab = useCallback((value) => {
    setActiveTab(value);
  }, []);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  useEffect(() => {
    if (document.getElementById(`root`)) {
      if (show) {
        document.getElementById(`root`).classList.add("modal_active");
      } else {
        document.getElementById(`root`).classList.remove("modal_active");
      }
    }
    console.log("showwwww", show);

    if (show === 1) {
      dispatch(getOrderHistoryDetail(merchantId));
    }
  }, [show]);
  function getTabData() {
    let res = null;
    if (activeTab == 0) {
      res = <LoyalityAccountScreen />;
    } else if (activeTab == 1) {
      res = <Vouchers />;
    } else if (activeTab == 2) {
      res = <LoyalityHistoryScreen />;
    }
    return res;
  }

  const getmemberNumber = ()=>{
    let res="N/A";
    if(xgateDetails?.membership?.code){
      res = xgateDetails?.membership?.code;
    }else if(cardDetail?.CardInfo?.CardNo){
      res = cardDetail?.CardInfo?.CardNo;
    }
    return res;
  }
  return (
    <div className={`accountModal loyality_ms ${show ? "show" : ""}`}>
      <section className="varification-sec">
        <a href="#" className="cross-icon" onClick={() => onCancel()}></a>
        <div className="row mx-0 px-3 justify-content-start">
          <div className="circle-img col-2 mx-0">
            <figure className="">
              <ImageTag
                src={tableReducer?.table_detail?.Merchant?.logo}
                alt=""
              />
            </figure>
          </div>
          <div className="naming-sec col-10">
            <h4>
              {" "}
              {tableReducer?.table_detail?.Merchant?.account_name ?? ""}{" "}
            </h4>
            <p className="md-txt h5">
              {" "}
              Member #
              <span className="h3">
                {getmemberNumber()}
              </span>
            </p>
          </div>
        </div>
      </section>

      <Tabbar activeTab={activeTab} switchTab={switchTab} />

      {getTabData()}
    </div>
  );
};

export default AccountPopup;
