import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCartNewItemCount } from "../../helpers";
import { TOGGLE_CART_PAGE } from "../../Redux/actions/types";

function Header({
  backTitle,
  rtComponent,
  backPath,
  onTabProfile,
  style,
  hideHeader = false,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;
  const cartCount = getCartNewItemCount(cart_detail);
  return (
    <header style={style}>
      <div className="container">
        {!hideHeader && (
          <div className="row">
            <div
              onClick={() =>
                backPath && backPath !== ""
                  ? history.push(backPath.toString())
                  : history.goBack()
              }
              className="col-6"
            >
              <a
                href="#"
                className="back"
                onClick={() =>
                  backPath && backPath !== ""
                    ? history.push(backPath.toString())
                    : history.goBack()
                }
              >
                {" "}
                {backTitle}{" "}
              </a>
            </div>
            <div className="col-6 notify">
              <a
                href="#"
                className="notification"
                onClick={() => {
                  dispatch({ type: TOGGLE_CART_PAGE, payload: true });
                  history.push("/");
                }}
              >
                {cartCount > 0 ? (
                  <span className="counts"> {cartCount} </span>
                ) : null}
              </a>
              {loyaltyEnabled && (
                <a href="#" onClick={() => onTabProfile()} className="profile">
                  {" "}
                </a>
              )}
            </div>
          </div>
        )}
        {rtComponent ? rtComponent : null}
      </div>
    </header>
  );
}

export default Header;
