import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";

import AccountPopup from "../Components/AccountPopup";
import { getSelectedItemDetail, orderPlaceDetail } from "../../Redux/actions";
import {
  getModifierActionSign,
  getModifierActionName,
  checkNegNum,
  makeNegToPosNum,
  sortCartItems,
  getCartBody,
  parseTwoDigitNumber,
  parseRound,
  checkArray,
  checkNull,
} from "../../helpers";

import {} from "../../Assets";
import TipPopup from "../Components/TipPopup";
import { APPLY_VOUCHER_DETAIL_RECEIVED } from "../../Redux/actions/types";

function Confirmation({
  loading,
  table_detail,
  cart_detail,
  show_complete_page,
  recent_order_detail,
  user_detail,
  orderPlaceDetail,
  getSelectedItemDetail,
  total_tip,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const recent_order_id = useSelector(
    (state) => state.cartDetailReducer?.recent_order_id
  );
  const pos_cart_detail = useSelector(
    (state) => state.cartDetailReducer?.pos_cart_detail
  );
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const show_session_expired_page = useSelector(
    (state) => state.tableDetailReducer?.show_session_expired_page
  );
  const showPostTaxPrice =
  table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
    ? true
    : false;
  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const [showTipPopup, setShowTipPopup] = useState(0);
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount
  );
  const [totalServiceCharge, setTotalServiceCharge] =
    useState(defaultServiceCharge);
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalTip, setTotalTip] = useState(`$${total_tip}`);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const { xgate_details: xgateDetails, xgate_coupons: xgateCoupons } =
    useSelector((state) => state?.authDetailReducer);

  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);

  let defaultDiscountTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  useEffect(() => {
    if (show_complete_page) {
      history.push("/completed");
    }
  }, [show_complete_page]);

  useEffect(() => {
    if (show_session_expired_page) {
      history.push("/session-expired");
    }
  }, [show_session_expired_page]);

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    let defaultPromoDiscount = 0;
    // promotion discount
    if (
      cart_detail &&
      checkArray(
        cart_detail.filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
      )
    ) {
      cart_detail
        .filter((p) => p?.promotions?.type !== "Re-Price")
        .map((item) => {
          defaultPromoDiscount =
            defaultPromoDiscount +
            parseFloat(item?.promotions?.discountPerItem ?? 0) *
              parseInt(item?.quantity ?? 0);
        });
    }
    // promotion discount END
    let defaultTotalDiscount = 0;
    //applied_voucher
    if (applied_voucher && applied_voucher.length > 0) {
      const newApplyVoucher = applied_voucher.map((applyVoucher) => {
        if (applyVoucher?.Type == "Cash") {
          const discountAmt = parseFloat(applyVoucher?.TypeValue ?? 0);
          applyVoucher.discountAmt = discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        } else if (applyVoucher?.Type == "Discount") {
          const discountAmt =
            (parseFloat(applyVoucher?.TypeValue ?? 0) *
              defaultDiscountTotalCartAmount) /
            100;
          applyVoucher.discountAmt = discountAmt;
          defaultDiscountTotalCartAmount =
            defaultDiscountTotalCartAmount - discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        }
        return applyVoucher;
      });
      dispatch({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload: newApplyVoucher,
      });
    }
    if (applied_promocode && applied_promocode !== null) {
      defaultTotalDiscount = defaultTotalDiscount + getPromotionTotalDis();
    }
    // applied_voucher END
    setTotalDiscount(defaultTotalDiscount);
    setPromoDiscount(defaultPromoDiscount);
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
    setTotalTip(total_tip);
  }, [showAccountPopUp, cart_detail, total_tip]);

  const jwtSecret = process?.env?.REACT_APP_JWT_SECRET;

  function getSameBundleItemIndex(item, bundle) {
    let res = -1;
    if (item?.BundledMenuItem?.length > 0) {
      for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
        const element = item.BundledMenuItem[index];
        if (
          element.id != bundle.id &&
          element.option_series_id == bundle.option_series_id
        ) {
          res = index;
          break;
        }
      }
    }
    return res;
  }

  function getModifierItems(item) {
    let res = null;
    if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
      res = item.modifierArray
        .filter((p) => p.action != "discount")
        .map((modifier, modifierIndex) => {
          const priceTag = `(${getModifierActionSign(modifier.action)}${
            table_detail?.currency ?? ""
          }${parseFloat(modifier.unit_price).toFixed(2)})`;
          console.log("modifier.unit_price", modifier.unit_price);
          return (
            <ul className="other-options" key={modifierIndex}>
              {modifier.unit_price !== 0 && (
                <li>
                  {`${getModifierActionName(modifier.action)} ${modifier.name}`}{" "}
                  ({checkNegNum(modifier.unit_price) ? "- " : ""}
                  {`${table_detail?.currency ?? ""}`}
                  {parseFloat(
                    checkNegNum(modifier.unit_price)
                      ? makeNegToPosNum(modifier.unit_price)
                      : modifier.unit_price
                  ).toFixed(2)}
                  )
                </li>
              )}
              {modifier.unit_price === 0 && <li>{`${modifier.name}`}</li>}
            </ul>
          );
        });
    } else if (
      item?.menu_item_type == "bundled" &&
      item?.BundledMenuItem?.length > 0
    ) {
      res = item.BundledMenuItem.filter((bunItem) => {
        let filterRes = true;
        if (
          item?.hide_fixed_items_qr == "yes" &&
          bunItem?.option_series_name == "Fixed Items" &&
          bunItem?.bundledMenuitemModifers?.length == 0
        ) {
          filterRes = false;
        }
        return filterRes;
      })
        .sort(
          (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
        )
        .map((bundle, bundleIndex) => {
          let modiferArr = null;
          if (bundle?.bundledMenuitemModifers?.length > 0) {
            modiferArr = bundle.bundledMenuitemModifers
              .filter((p) => p.action != "discount")
              .map((modifier, modifierIndex) => {
                const priceTag =
                  modifier.unit_price > 0
                    ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                    : "";
                return (
                  <ul className="other-options" key={modifierIndex}>
                    <li>
                      {modifier.unit_price !== 0 && (
                        <>
                          {`${getModifierActionName(modifier.action)} ${
                            modifier.name
                          }`}{" "}
                          ({checkNegNum(modifier.unit_price) ? "- " : ""}
                          {`${table_detail?.currency ?? ""}`}
                          {parseFloat(
                            checkNegNum(modifier.unit_price)
                              ? makeNegToPosNum(modifier.unit_price)
                              : modifier.unit_price
                          ).toFixed(2)}
                          )
                        </>
                      )}
                      {modifier.unit_price === 0 && (
                        <>{`${
                          !["add", "remove", "swap"].includes(modifier.action)
                            ? `${modifier.action} `
                            : ""
                        }${modifier.name}`}</>
                      )}
                      {`${
                        modifierIndex + 1 !=
                        bundle.bundledMenuitemModifers.length
                          ? ","
                          : ""
                      }`}
                    </li>
                  </ul>
                );
              });
          }
          const bundlePriceTag =
            bundle.amount > 0
              ? `(@${parseFloat(bundle.amount).toFixed(2)})`
              : "";
          const bundleQuantityTag =
            bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
          let alreadyExist = getSameBundleItemIndex(item, bundle);
          const showComma =
            alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
          return (
            <ul className="other-options" key={bundleIndex}>
              <li>
                {showComma && alreadyExist > -1 ? "," : ""}
                {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
                {bundle?.bundle_menu_item_name}
                {`${bundleQuantityTag}`}
                {`${bundlePriceTag}`}
                {modiferArr}
              </li>
            </ul>
          );
        });
    }
    return res;
  }

  function getCartItems() {
    if (checkArray(cart_detail) && checkArray(cart_detail.filter(p=>p.is_added == true))) {
      return sortCartItems(cart_detail.filter(p=>p.is_added == true)).map(function (item, itemIndex) {
        const itemPrice =
          item?.promotions && item?.promotions?.type !== "Re-Price"
            ? item?.promotions?.accutalPrice
            : item?.item_amount;
        const discountedTotalPrice =
          parseFloat(
            item?.promotions && item?.promotions?.type !== "Re-Price"
              ? item?.promotions?.discountPerItem
              : 0
          ) * item.quantity;
        return (
          <div className="new_items" key={itemIndex}>
            <div
              className={`menu-box row ${
                item?.is_added == true ? "added" : ""
              }`}
            >
              <div className="d-flex orderMenu">
                <figure className="menu-img">
                  <ImageTag
                    src={item.image}
                    defaultimage={table_detail?.Merchant?.logo}
                    removeImage={true}
                  />
                </figure>
                <div className="col-7 menu-content pl-0">
                  <h4>
                    <span className="txt-green">{item.quantity}x</span>{" "}
                    {item.pos_name} @{parseFloat(itemPrice).toFixed(2)}{" "}
                  </h4>
                  {getModifierItems(item)}
                </div>
                <div className="col-2 px-0 text-right">
                  <p className="pricing-lg">
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseFloat(item.total + discountedTotalPrice).toFixed(
                      2
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  }
  function orderPlaceHandler(onlyTip = false, newTip = totalTip) {
    let body = getCartBody({
      recent_order_id,
      cart_detail,
      pos_cart_detail,
      user_detail,
      table_detail,
      totalTip: newTip,
      applied_voucher,
      totalDiscount,
      applied_promocode,
      xgateDetails,
      onlyTip,
    });
    dispatch(orderPlaceDetail(body, onlyTip));
  }

  const tipChangeHandler = (newTipVal) => {
    setTotalTip(newTipVal);
    if (recent_order_id !== "") {
      orderPlaceHandler(true, newTipVal);
    }
    setShowTipPopup(0);
  };

  const getPromotionTotalDis = () => {
    let res = getBasketTotalAmount(true);
    let promotionDiscount = 0;
    if (applied_promocode && applied_promocode !== null) {
      if (applied_promocode.type == "Amount") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Percentage") {
        promotionDiscount = (res * parseFloat(applied_promocode.amount)) / 100;
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Re-Price") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = promotionDiscount;
      }
    }
    return res;
  };

  function getBasketTotalAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {parseRound(res)}
      </>
    );
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) => subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter((item) => item.is_popular == 1).map(function (
            item,
            itemKey
          ) {
            res.push(item);
          });
        }
      });
    }
    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              getSelectedItemDetail(item);
              if (item?.menu_item_type == "bundled")
                history.push("/bundle-add-item");
              else history.push("/add-item");
            }}
          >
            <figure className="menu-img mx-auto mb-3">
              <ImageTag
                src={item?.image}
                defaultimage={table_detail?.Merchant?.logo}
                alt=""
              />
            </figure>
            <h5 className="sm-h5"> {item?.menu_item_name ?? ""} </h5>
            <p>
              {" "}
              @
              {showPostTaxPrice? item?.dinein_price?.DineInPostTax !== undefined
                ? parseRound(item?.dinein_price?.DineInPostTax)
                : "0.00" :item?.dinein_price?.DineIn !== undefined
                ? parseRound(item?.dinein_price?.DineIn)
                : "0.00"}
            </p>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function getBasketTotalQuantity() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      res = cart_detail.reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
    }
    return <div className="items-count"> {res} </div>;
  }

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className="wrapper cart_wrapper custom_pb"
          onScroll={scrolledElement}
          {...handlers}
        >
          <Motion
            defaultStyle={{ x: 300 }}
            style={{
              x: spring(hideHeader === false ? 300 : 0, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <Header
                backTitle={"Add New Items"}
                onTabProfile={() => setShowAccountPopUp(1)}
                style={{
                  height: `${x}px`,
                }}
                backPath={
                  table_detail?.PosTableMaster?.qr_code_id !== undefined
                    ? `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                    : undefined
                }
                rtComponent={
                  <>
                    <div className="hotel_name">
                      <div className="circle-img">
                        <figure className="">
                          <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                        </figure>
                      </div>
                      <div className="naming-sec text-center">
                        <h4> {table_detail?.Merchant?.account_name} </h4>
                        <p>
                          {" "}
                          Table #{
                            table_detail?.PosTableMaster?.table_number
                          }{" "}
                          Basket{" "}
                        </p>
                      </div>
                      <div className="desc-sec-top">
                        <h4>
                          {" "}
                          {table_detail?.QrOrdering?.order_received_message &&
                          table_detail?.QrOrdering?.order_received_message !==
                            ""
                            ? table_detail?.QrOrdering?.order_received_message
                            : "Your order is in the kitchen!"}
                        </h4>
                        <p>
                          {" "}
                          Want more? Feel free to click the Add New Items
                          buttons or re-scan the QR to add further items to your
                          order.{" "}
                        </p>
                      </div>
                      <div className="col-12 py-3 px-0">
                        <h5 className="md-title bold"> Order Summary </h5>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </Motion>
          <Motion
            defaultStyle={{ x: 0 }}
            style={{
              x: spring(hideHeader === false ? 0 : 60, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <Header
                backTitle={"Add New Items"}
                onTabProfile={() => setShowAccountPopUp(1)}
                style={{
                  height: `${x}px`,
                  position: "fixed",
                  top: "0px",
                  width: "100%",
                  zIndex: 99,
                  backgroundColor: "#f5f6f7",
                }}
                backPath={
                  table_detail?.PosTableMaster?.qr_code_id !== undefined
                    ? `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                    : undefined
                }
                rtComponent={<></>}
              />
            )}
          </Motion>
          <div className={`outerScroll ${hideHeader ? "scrollup" : ""}`}>
            <section className="middle-sec cart-sec-ms new_items_page">
              {getCartItems()}
            </section>
            {/* {table_detail?.SubCategory &&
            table_detail.SubCategory.findIndex(
              (subCat) =>
                subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
            ) > -1 && (
              <section className="recommended">
                <h3 className="title"> You Might Also Like </h3>
                <div className="row also-like-sec">{getPopularItems()}</div>
              </section>
            )} */}
            <section className="billing-details mb-5">
              <ul className="billing-list">
                <li>
                  {" "}
                  <span> Sub Total </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`} */}
                    {parseRound(
                      totalCartAmount + totalDiscount + promoDiscount
                    )}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Discounts </span>{" "}
                  <strong>
                    {" "}
                    (<small>{`${table_detail?.currency ?? ""}`}</small>
                    {`${parseRound(totalDiscount + promoDiscount)}`})
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Service Charges </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalServiceCharge)}
                  </strong>
                </li>

                <li>
                  {" "}
                  <span> Tax </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalSaleTax)}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Gratuity </span>{" "}
                  <input
                    type="text"
                    className="type-text"
                    value={`$${parseTwoDigitNumber(totalTip)}`}
                    onClick={() => {
                      setShowTipPopup(1);
                    }}
                    readOnly={true}
                  />
                </li>
                <li className="total">
                  {" "}
                  <span> Total </span>{" "}
                  <strong> {getBasketTotalAmount()}</strong>
                </li>
              </ul>
            </section>
            <section className="bottom-sec">
              <div className="green-sec py-3">
                {/* <div className="col-2">{getBasketTotalQuantity()}</div> */}
                <div
                  className="col-12 text-center"
                  onClick={() => {
                    history.push(
                      `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                    );
                  }}
                >
                  <strong> Go Back & Add New Items </strong>
                </div>
                <div className="col-2"></div>
              </div>
            </section>
            {/* account modal */}

            <AccountPopup
              show={showAccountPopUp}
              onCancel={() => setShowAccountPopUp(0)}
            />
            <TipPopup
              show={showTipPopup}
              totalTip={totalTip}
              onCancel={() => setShowTipPopup(0)}
              tipChangeHandler={tipChangeHandler}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

const mapStateToProps = (state) => ({
  table_detail: state.tableDetailReducer.table_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  show_complete_page: state.cartDetailReducer.show_complete_page,
  total_tip: state.cartDetailReducer.total_tip,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  user_detail: state.authDetailReducer.user_detail,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  getSelectedItemDetail: getSelectedItemDetail,
  orderPlaceDetail: orderPlaceDetail,
};

Confirmation = connect(mapStateToProps, mapDispatchToProps)(Confirmation);

export default Confirmation;
