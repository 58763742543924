import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { countDecimals, parseTwoDigitNumber } from "../../helpers";
import {
  changeTipDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import NumberFormat from "react-number-format";

const TipPopup = ({ show, onCancel, totalTip, tipChangeHandler }) => {
  const dispatch = useDispatch();
  // const table_detail = useSelector(
  //   (state) => state.tableDetailReducer?.table_detail
  // );

  useEffect(() => {
    if (document.getElementById(`root`)) {
      if (show) {
        document.getElementById(`root`).classList.add("modal_active");
      } else {
        document.getElementById(`root`).classList.remove("modal_active");
      }
    }
    setAmount(parseTwoDigitNumber(totalTip));
  }, [show]);

  const [amount, setAmount] = useState(parseTwoDigitNumber(totalTip));

  const closePopup = () => {
    // setPromoInput("");
    onCancel();
  };

  return (
    <div className={`promoModal loyality_ms ${show ? "show" : ""}`}>
      <section className="varification-sec">
        <a href="#" className="cross-icon" onClick={() => closePopup()}></a>
        <div className="row mx-0 px-3 justify-content-start">
          <div className="naming-sec col-12 px-0">
            <h4>Add Gratuity</h4>
            {/* <p className="mt-2 md-txt h5">
              {" "}
              Please provide the valid promotion code and submit.
            </p> */}
          </div>
        </div>
        <div className="row mx-3 justify-content-end">
          <NumberFormat
            prefix={"$"}
            // displayType="text"
            className="input-text mt-5 mb-3"
            placeholder="Enter the amount"
            value={amount > 0 ? amount : ""}
            inputmode="decimal"
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              // formattedValue = $2,223
              // value ie, 2223
              setAmount(value);
            }}
          />
          <button
            type="button"
            className="btn green-btn my-3"
            onClick={() => {
              if (amount && !isNaN(amount))
                tipChangeHandler(
                  parseFloat(amount.toString().replace("$", ""))
                );
            }}
          >
            Add
          </button>
        </div>
      </section>
    </div>
  );
};

export default TipPopup;
