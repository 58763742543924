import moment from "moment";
import axios from "axios";
import { encode } from "base-64";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import uniqid from "uniqid";

const getNewDeviceId = async () => {
  const fpPromise = FingerprintJS.load({
    apiKey: "bjqpKdV8NBZaxve6JPwz",
    region: "ap"
  });
  const fp = await fpPromise;
  const result = await fp.get();
  return result;
};

export const getDeviceId = async () => {
  let res = "";
  const checkExist = getSessionStorageOrDefault("deviceId", undefined);
  if (checkExist) {
    return checkExist;
  } else {
    const keyPromise = await getNewDeviceId().then((resp) => {
      const visitorId = resp.visitorId ?? "";
      sessionStorage.setItem("deviceId", JSON.stringify(visitorId));
      return visitorId;
    });
    console.log("checkExistdeviceId", res);
    return keyPromise;
  }
};

export function capitalizeFirstLetter(string) {
  if (string && typeof string === "string" && string !== "") {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export function truncateString(str, n) {
  return str.length > n ? str.substr(0, n - 1) + ".." : str;
}

function replaceDateStringFrom(date) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = date.replace("/Date(", "");
      res = res.replace(")/", "");
      res = moment(parseInt(res));
    }
  } catch (e) {}
  return res;
}

export const scrollToDiv = (id, top = false) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo({
      left: 0,
      top: top ? 0 : element.scrollHeight,
      behavior: "smooth",
    });
  }
};

export async function avocadoAuthToken() {
  // Advocado API
  const ad_api_url = process?.env?.REACT_APP_AVOCADO_API_URL;
  const ad_username = process?.env?.REACT_APP_AVOCADO_API_USERNAME;
  const ad_password = process?.env?.REACT_APP_AVOCADO_API_PASSWORD;
  let myHeaders = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
  });
  myHeaders.set(
    "Authorization",
    "Basic " + encode(ad_username + ":" + ad_password)
  );
  const json = await fetch(`${ad_api_url}/oauth2/tokenCredential`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      grant_type: "client_credentials",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

export async function orderHistoryApi({
  token,
  merchantId,
  customerId,
  deviceId,
}) {
  // Advocado API
  const base_url = process?.env?.REACT_APP_API_URL;
  let myHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    token: token,
    // "Access-Control-Allow-Origin": "*",
  };
  const json = await fetch(`${base_url}/qr_code_apis/get_order_history`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({
      merchant_id: merchantId,
      customer_id: customerId,
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

export function getReOrderItemIds(data) {
  let allItems = [];
  if (data && data.length > 0) {
    data.map((order, orderIndex) => {
      if (order?.PosOrderItem && order?.PosOrderItem.length > 0) {
        order?.PosOrderItem.map((item, itemIndex) => {
          const modifierArr =
            item?.PosOrderItemDetail && item?.PosOrderItemDetail.length > 0
              ? pluckArray(item?.PosOrderItemDetail, "pos_modifier_id")
              : [];
          const bundleItemArr =
            item?.PosOrderBundleItem && item?.PosOrderBundleItem.length > 0
              ? item?.PosOrderBundleItem.map((bundleItem) => {
                  const resItem = {
                    menu_item_id: bundleItem?.menu_item_id,
                    modifiers_id: pluckArray(
                      bundleItem?.PosOrderItemDetail,
                      "pos_modifier_id"
                    ),
                  };
                  return resItem;
                })
              : [];
          const checkExist = allItems.findIndex(
            (p) => p.menu_item_id == item?.menu_item_id
          );
          if (checkExist > -1) {
            allItems[checkExist].count = allItems[checkExist].count + 1;
            // allItems[checkExist].bundle_item_ids = bundleItemArr;
          } else {
            allItems.push({
              menu_item_id: item?.menu_item_id,
              count: 1,
              created_on: parseStringToDate(
                order?.PosOrder?.order_create_date,
                "YYYY-MM-DD HH-mm-ss",
                "dddd, MMMM Do"
              ),
              quantity: parseInt(item?.menu_item_quantity),
              modifier_ids: modifierArr,
              bundle_item_ids: bundleItemArr,
            });
          }
        });
      }
    });
  }
  allItems.sort(function (a, b) {
    return b.count - a.count;
  });
  if (allItems.length > 0) {
    allItems = allItems.slice(0, 10);
  }
  return allItems;
}

export function getUnixDateFormat(dateNum, format = "MMMM D, YYYY") {
  let res = "N/A";
  const getDate = replaceDateStringFrom(dateNum);
  if (getDate && getDate !== undefined) {
    res = getDate.format(format);
  }
  return res;
}
export function getCartNewItemCount(cartItems) {
  let res = 0;
  if (cartItems && cartItems?.length > 0) {
    res = cartItems
      .filter((p) => p?.is_added == false)
      .reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
  }
  return res;
}

export function getTypeOfVoucher(type, voucher) {
  let res = false;
  let todayDate = moment();
  const fromDateObj = replaceDateStringFrom(voucher?.ValidTo);
  if (fromDateObj && fromDateObj !== undefined) {
    // console.log("todayDate:", todayDate.format("MMMM D, YYYY"));
    // console.log("fromDateObj:", fromDateObj.format("MMMM D, YYYY"));
    // console.log("cjeckkk:", fromDateObj.diff(todayDate, "days"));
    if (type == 0 && fromDateObj.diff(todayDate, "days") > 0) {
      res = true;
    } else if (
      type == 1 &&
      fromDateObj.diff(todayDate, "days") > 0 &&
      voucher?.IsRedeemable == false
    ) {
      res = true;
    } else if (type == 2 && fromDateObj.diff(todayDate, "days") < 0) {
      res = true;
    }
  }
  return res;
}

export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function getModifierActionName(modifierAction) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "Add";
      break;
    case "remove":
      res = "Remove";
      break;
    case "swap":
      res = "Swap";
      break;
    default:
      break;
  }
  return res;
}

export function checkNull(string, val = "") {
  if (typeof string === "number") {
    string = string.toString();
  }

  return string && string !== "" && string !== null ? string : val;
}

export function checkIsNum(num, val = 0) {
  try {
    if (typeof num === "string") {
      num = parseFloat(num);
    }
  } catch (error) {}

  return num && typeof num == "number" ? num : val;
}

export function parseTwoDigitNumber(val) {
  if (val !== undefined && !isNaN(parseFloat(val)) && isFinite(val)) {
    if (countDecimals(val) > 0) {
      return parseFloat(val).toFixed(3).slice(0, -1);
    }
    return `${val}.00`;
  }
  return `0.00`;
}

export function getBundleItemOptionsPrice(bundleItem, currency = "") {
  let res = null;
  if (
    bundleItem?.action_price !== null &&
    bundleItem?.action_price !== "" &&
    bundleItem?.amount !== "" &&
    bundleItem?.amount !== null
  ) {
    res = (
      <span className="price-sm">
        {parseFloat(bundleItem.amount) !== 0
          ? checkNegNum(bundleItem.amount)
            ? "-"
            : "+"
          : ""}
        {`${currency}`}
        {parseFloat(bundleItem.amount) < 0
          ? makeNegToPosNum(bundleItem.amount)
          : parseFloat(bundleItem.amount).toFixed(2)}
      </span>
    );
  } else if (
    bundleItem?.unit_price !== null &&
    bundleItem?.unit_price !== "" &&
    parseFloat(bundleItem?.unit_price) > 0
  ) {
    res = (
      <span className="price-sm">
        {"+"}
        {`${currency}`}
        {parseFloat(bundleItem.unit_price).toFixed(2)}
      </span>
    );
  }
  return res;
}

export function getModifierActionSign(modifierAction) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "+";
      break;
    case "remove":
      res = "-";
      break;
    case "swap":
      res = "+";
      break;
    default:
      break;
  }
  return res;
}

export function checkNegNum(num) {
  return parseFloat(num) < 0;
}

export function makeNegToPosNum(num) {
  return -1 * parseFloat(num);
}

export function getModifierTypeName(modifierType, modifier) {
  let res = "";
  res = modifier?.modifier_name ?? "";
  // if (modifier?.action == "size") {
  //   res = modifier?.modifier_name ?? "";
  // } else {
  //   switch (modifierType) {
  //     case "ingredient":
  //       res = `${modifier?.Ingredient?.ingredient_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapIngredient?.ingredient_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     case "recipe":
  //       res = `${modifier?.Recipe?.recipe_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapRecipe?.recipe_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  return res;
}

export function setPageDetails(data = {}) {
  if (data.title) {
    document.title = data.title;
  }
  if (data.favIcon) {
    document.getElementById("fav-icon").setAttribute("href", data.favIcon);
    document
      .getElementById("apple-touch-icon")
      .setAttribute("href", data.favIcon);
  }
}

export function pluckArray(arr, key) {
  return arr.map((i) => i[key]);
}

export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function checkItemExistInCart(item, cart) {
  let res = -1;
  if (cart?.length > 0) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function checkBundleItemExistInCart(item, cart) {
  let res = -1;
  if (
    cart?.length > 0 &&
    ((item?.modifierArray && item?.modifierArray?.length > 0) ||
      (item?.BundledMenuItem && item?.BundledMenuItem?.length > 0))
  ) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray) &&
        JSON.stringify(item?.selectedBundleItem) ==
          JSON.stringify(element?.selectedBundleItem)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function getItemById(itemId, subId, table_detail) {
  let res = null;
  if (table_detail?.SubCategory?.length > 0) {
    const subIndex = table_detail?.SubCategory?.findIndex((p) => p.id == subId);
    if (subIndex > -1) {
      const subCat = table_detail?.SubCategory?.[subIndex];
      const itemIndex = subCat?.MenuItem?.findIndex((p) => p.id == itemId);
      if (itemIndex > -1) {
        res = subCat?.MenuItem?.[itemIndex];
      }
    }
  }
  return res;
}

export function getPosCartSubmittedItems(orders, table_detail) {
  let items = [];
  if (orders?.menu_item && orders?.menu_item?.length > 0) {
    orders?.menu_item?.map((order) => {
      let item = order;
      items.push(item);
    });
  }
  return items;
}
export function getCartSubmittedItems(orders, table_detail) {
  let items = [];
  if (orders?.menu_item && orders?.menu_item?.length > 0) {
    orders?.menu_item?.map((order) => {
      const menuItemQyt = order?.menu_item_quantity
        ? parseFloat(order?.menu_item_quantity)
        : 1;
      const quantityMultipleBy = order?.quantityMultipleBy
        ? parseFloat(order?.quantityMultipleBy)
        : 1;
      const exactQyt = parseFloat(menuItemQyt / quantityMultipleBy);
      const menuItemPrice = order?.menu_item_price
        ? parseFloat(order?.menu_item_price)
        : 0;
      const menuDoubleActualAmout =
        checkArray(order.promotions) &&
        order.promotions.findIndex((p) => p?.type == "Re-Price") > -1
          ? order?.menu_item_price
          : order?.doubleActualAmout
          ? parseFloat(order?.doubleActualAmout)
          : 0;
      const menuTotalPrice = menuDoubleActualAmout
        ? parseFloat(menuDoubleActualAmout * exactQyt)
        : 0;
      const menuPriceForOne = menuItemPrice;
      // console.log("cheksadkkkkkkk=>>>exactQyt", exactQyt);
      // console.log("cheksadkkkkkkk=>>>menuPriceForOne", menuPriceForOne);
      // console.log("cheksadkkkkkkk=>>>diffInTotalPrice", diffInTotalPrice);
      // console.log("cheksadkkkkkkk=>>>quantityMultipleBy", quantityMultipleBy);
      const modifierArray =
        order?.modifiers?.length > 0
          ? order?.modifiers?.map((modifier) => {
              return {
                id: modifier?.modifier_id ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                action: modifier?.action ?? "",
                name: modifier?.name ?? "",
                unit_price: parseFloat(modifier?.modifier_price ?? 0),
                modifier_price: parseFloat(modifier?.modifier_price ?? 0),
                modifier_quantity: parseFloat(modifier?.modifier_quantity ?? 0),
              };
            })
          : [];

      // for discount modifier
      if (order?.discountModifierID !== "") {
        const modiItem = getItemById(
          order?.menu_item_id,
          order?.subCategoryid,
          table_detail
        );
        // console.log("modiItem", modiItem);
        if (modiItem !== null) {
          let disModifierIndex = -1;
          let disModifierItem = null;
          if (modiItem?.menu_item_type === "standard") {
            disModifierIndex = modiItem?.PosMenuitemsModifier?.findIndex(
              (p) => p.id == order?.discountModifierID
            );
            if (disModifierIndex > -1) {
              disModifierItem =
                modiItem?.PosMenuitemsModifier?.[disModifierIndex];
            }
          } else {
            disModifierIndex = modiItem?.DiscountModifiers?.findIndex(
              (p) => p.id == order?.discountModifierID
            );
            if (disModifierIndex > -1) {
              disModifierItem = modiItem?.DiscountModifiers?.[disModifierIndex];
            }
          }
          if (disModifierIndex > -1 && disModifierItem !== null) {
            modifierArray.push({
              id: disModifierItem?.id ?? "",
              modifier_id: disModifierItem?.id ?? "",
              modifier_group_id: disModifierItem?.modifier_group_id ?? "",
              action: disModifierItem?.action ?? "",
              name: disModifierItem?.modifier_name ?? "",
              unit_price: parseFloat(disModifierItem?.unit_price ?? 0),
              modifier_price: parseFloat(disModifierItem?.unit_price ?? 0),
              modifier_quantity: parseFloat(
                disModifierItem?.amount_per_item ?? 0
              ),
            });
          }
        }
      }
      // for discount modifier end

      const BundledMenuItemArray =
        order?.BundledMenuItem?.length > 0
          ? order?.BundledMenuItem?.map((bundledItem) => {
              const bundleModifiers =
                bundledItem?.bundledMenuitemModifers?.length > 0
                  ? bundledItem?.bundledMenuitemModifers.map(
                      (bundleModifier) => {
                        return {
                          id: bundleModifier?.id ?? "",
                          modifier_id: bundleModifier?.modifier_id ?? "",
                          modifier_group_id:
                            bundleModifier?.modifier_group_id ?? "",
                          action: bundleModifier?.action ?? "",
                          name: bundleModifier?.name ?? "",
                          unit_price: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.unit_price)
                            : 0,
                          modifier_price: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.modifier_price)
                            : 0,
                          modifier_quantity: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.modifier_quantity)
                            : 0,
                          menu_item_id: bundleModifier?.menu_item_id ?? "",
                        };
                      }
                    )
                  : [];
              return {
                action_price: bundledItem?.action_price ?? "",
                amount: bundledItem?.amount
                  ? parseFloat(bundledItem?.amount)
                  : 0,
                amount_without_modifiers: bundledItem?.amount_without_modifiers
                  ? parseFloat(bundledItem?.amount_without_modifiers)
                  : 0,
                bundleName: bundledItem?.bundleName ?? "",
                sort: bundledItem?.sort ?? "",
                type: bundledItem?.type ?? "",
                category_id: bundledItem?.category_id ?? "",
                sub_category_id: bundledItem?.sub_category_id ?? "",
                perBundleQty: bundledItem?.perBundleQty ?? "0",
                bundle_menu_item_id: bundledItem?.bundle_menu_item_id
                  ? parseFloat(bundledItem?.bundle_menu_item_id)
                  : 0,
                bundle_menu_item_name: bundledItem?.bundle_menu_item_name ?? "",
                menu_item_pos_name: bundledItem?.menu_item_pos_name ?? "",
                bundledMenuitemModifers: bundleModifiers,
                comment: bundledItem?.comment,
                id: bundledItem?.id ? parseFloat(bundledItem?.id) : 0,
                bundle_hide_fixed_items:
                  bundledItem?.bundle_hide_fixed_items ?? "no",
                is_fired: bundledItem?.is_fired == 1 ? true : false,
                is_hold: bundledItem?.is_hold == 1 ? true : false,
                fired_by: bundledItem?.fired_by ?? "",
                firetime: bundledItem?.firetime ?? "",
                offlineID: bundledItem?.offlineID ?? "",
                option_series_id: bundledItem?.option_series_id
                  ? parseFloat(bundledItem?.option_series_id)
                  : 0,
                option_series_name: bundledItem?.option_series_name ?? "",
                quantity: bundledItem?.quantity
                  ? parseFloat(bundledItem?.quantity)
                  : 0,
                void: bundledItem?.void ? parseFloat(bundledItem?.void) : 0,
              };
            })
          : [];
      let item = {
        id: order?.menu_item_id,
        offline_id: order?.offlineID,
        name: order?.menu_item_name,
        pos_name: order?.pos_name,
        bundleName: order?.menu_item_name ?? "",
        bundlePosName: order?.bundlePosName ?? "",
        image: order?.image ?? "",
        is_added: true,
        void: order?.void ?? 0,
        is_service_charge: order?.service_charge == 1 ? true : false,
        is_sales_tax: order?.sales_tax == 1 ? true : false,
        quantity: exactQyt,
        amount: menuItemPrice,
        amount_without_modifiers: parseFloat(menuPriceForOne),
        item_amount: menuItemPrice,
        comment: order?.comment,
        posordering: order?.posordering ?? 0,
        total: menuTotalPrice,
        actual_menu_price: parseFloat(order?.actual_menu_price ?? 0),
        menu_item_price: parseFloat(order?.menu_item_price ?? 0),
        firetime: order?.firetime ?? null,
        createDate: order?.createDate ?? "",
        is_fired: order?.is_fired == 1 ? true : false,
        is_hold: order?.is_hold == 1 ? true : false,
        menu_item_type: order?.menu_item_type ?? "",
        menu_price: order?.menu_price ?? "",
        service_charge_amount: order?.serviceChargeAmount
          ? parseFloat(order?.serviceChargeAmount)
          : 0,
        sales_tax_amount: order?.salesTaxAmount
          ? parseFloat(order?.salesTaxAmount)
          : 0,
        catID: order?.catID ?? "",
        sub_category_id: order?.subCategoryid ?? "",
        modifierArray:
          modifierArray?.length > 0
            ? modifierArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
        BundledMenuItem:
          BundledMenuItemArray?.length > 0
            ? BundledMenuItemArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
        selectedBundleItem:
          BundledMenuItemArray?.length > 0
            ? BundledMenuItemArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
      };
      if (order?.menu_item_type == "bundled") {
        item.hide_fixed_items = order?.hide_fixed_items ?? "no";
        item.hide_fixed_items_qr = order?.hide_fixed_items_qr ?? "no";
      }
      if (checkArray(order?.promotions) && order?.promotions?.[0]) {
        item.promotions = order?.promotions?.[0];
        item.promotions.accutalPrice = parseFloat(order?.menu_item_price ?? 0);
      }
      items.push(item);
    });
  }
  return items;
}

export function parseRound(num, digits = 2) {
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    if (countDecimals(num) > 2 && lastDecimalPlace(num) == 5) {
      stringNum = addLastDigitToNum(stringNum);
    }
    num = (
      Math.round(parseFloat(stringNum) * padEndingZeros(1, digits + 1)) /
      padEndingZeros(1, digits + 1)
    ).toFixed(digits);
  }
  // console.log("testtttttttt",num,padEndingZeros(1, digits+1));
  return num;
}

function makeRandomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomString() {
  return uniqid() + makeRandomString(5) + "." + moment().valueOf();
}

export function getUniTimeString() {
  const millis = Date.now();
  const time = millis / 1000;
  return time;
}

export function countDecimals(value) {
  if (value % 1 != 0) return value.toString().split(".")?.[1].length;
  return 0;
}

export function lastDecimalPlace(value) {
  if (value % 1 != 0) return parseFloat(value.toString().slice(-1));
  return 0;
}

export function addLastDigitToNum(num, value = 1) {
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    num = parseFloat(stringNum + `${value}`);
  }
  return num;
}

export function padEndingZeros(num, size) {
  var s = num + "";
  while (s.length < size) s = s + "0";
  return s;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function checkExpiredSession(table_detail) {
  let isExpiredServicePeriod = false;
  // check service period
  const servicePeriodExpire = getSessionStorageOrDefault(
    "servicePeriodExpire",
    undefined
  );
  if (servicePeriodExpire) {
    console.log("servicePeriodExpire :>> ", servicePeriodExpire);
    isExpiredServicePeriod = checkExpireMerchantTime(
      servicePeriodExpire,
      table_detail?.Merchant?.timezone_offset ?? "00:00"
    );
  }
  return isExpiredServicePeriod;
  // check service period END
}

export function checkInactiveSession() {
  // check service period
  let isExpired = false;
  const inactiveSession = getSessionStorageOrDefault(
    "session_start_time",
    undefined
  );
  const timeFormat = "YYYY-MM-DD HH:mm:ss";
  if (inactiveSession) {
    const inactiveSessionTime = moment(inactiveSession, timeFormat);
    isExpired = moment().isAfter(inactiveSessionTime);
    console.log("inactiveSession :>> ", isExpired, inactiveSession);
  } else {
    sessionStorage.setItem(
      "session_start_time",
      JSON.stringify(moment().add(20, "minutes").format(timeFormat))
    );
  }
  return isExpired;
  // check service period END
}

export function getCartBody({
  recent_order_id,
  cart_detail,
  pos_cart_detail,
  user_detail,
  table_detail,
  totalTip,
  applied_voucher,
  totalDiscount,
  applied_promocode,
  xgateDetails,
  onlyTip = false,
}) {
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  let items = [];
  const offlineId =
    recent_order_id !== undefined && recent_order_id !== ""
      ? recent_order_id
      : getRandomString();
  const isNewOrder =
    recent_order_id !== undefined && recent_order_id !== "" ? "no" : "yes";
  if (
    cart_detail &&
    onlyTip !== true &&
    cart_detail.filter((p) => p.is_added !== true).length > 0
  ) {
    cart_detail
      .filter((p) => p.is_added !== true)
      .map((item) => {
        const modifierDetail = [];
        let discountModifierId = "";
        let discountModifierQty = null;
        if (item.modifierArray.length > 0) {
          item.modifierArray.map((modifier, index) => {
            if (modifier?.action == "discount") {
              discountModifierQty = modifier?.modifier_quantity ?? 1;
              discountModifierId = modifier?.modifier_id ?? "";
            } else {
              modifierDetail.push({
                name: modifier?.name ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                modifier_price: modifier?.modifier_price ?? 0,
                actual_modifier_price: modifier?.modifier_price ?? 0,
                action: modifier?.action ?? "",
                // modifier_quantity: modifier?.modifier_quantity ?? 1,
                modifier_quantity: item?.quantity ?? 1,
              });
            }
          });
        }
        let promoItem = item?.promotions;
        const amountWithoutModifiers = promoItem?.accutalPrice
          ? parseFloat(promoItem?.accutalPrice)
          : item?.amount_without_modifiers;
        const menuItemPrice =
          discountModifierQty !== null
            ? amountWithoutModifiers / discountModifierQty
            : amountWithoutModifiers ?? 0;

        let itemDetail = {
          BundledMenuItem:
            item?.menu_item_type == "bundled" &&
            item?.BundledMenuItem?.length > 0
              ? item?.BundledMenuItem
              : [],
          comment: item?.comment ?? "",
          image: item?.image ?? "",
          discount: 0,
          doubleActualAmout:
            promoItem?.type == "Re-Price" ? menuItemPrice : item?.amount ?? 0,
          firetime: item?.firetime ?? null,
          createDate: item?.createDate ?? "",
          is_fired: item?.is_fired ?? false,
          is_hold: item?.is_hold ?? false,
          menu_item_id: item?.id ?? "",
          menu_class_name: item?.menu_class_name ?? "",
          menu_item_name: item?.name ?? "",
          pos_name: item?.pos_name,
          void: item?.void,
          posordering: item?.posordering ?? 0,
          bundleName:
            item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
          discountModifierID: discountModifierId,
          menu_item_price: menuItemPrice,
          menu_item_quantity:
            discountModifierQty !== null && item?.quantity
              ? discountModifierQty * item?.quantity
              : item?.quantity ?? 0,
          quantityMultipleBy:
            discountModifierQty !== null ? discountModifierQty : 1,
          menu_item_type: item?.menu_item_type ?? "",
          offlineID: item?.offline_id,
          promo141Quantity: 0,
          promotionTotaldiscount: promoItem?.promotionTotaldiscount ?? 0,
          promotions: promoItem ? [promoItem] : [],
          salesTaxAmount: item?.sales_tax_amount ?? 0,
          sales_tax: item?.is_sales_tax == true ? 1 : 0,
          serviceChargeAmount: item?.service_charge_amount ?? 0,
          service_charge: item?.is_service_charge == true ? 1 : 0,
          menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
          subCategoryid: item?.sub_category_id ?? "",
          catID: item?.catID ?? "",
          modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
        };
        if (item?.menu_item_type == "bundled") {
          itemDetail.bundlePosName = item?.bundlePosName ?? "";
          itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
          itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";
          itemDetail.menu_item_price =
            discountModifierQty !== null
              ? item?.amount / discountModifierQty
              : item?.amount ?? 0;
          itemDetail.actual_menu_price =
            discountModifierQty !== null
              ? item?.actual_menu_price / discountModifierQty
              : item?.actual_menu_price ?? 0;
          itemDetail.kitchen_print_preference =
            item?.kitchen_print_preference ?? null;
          itemDetail.modifiers = [];
        }
        // promotion changes
        if (promoItem) {
          // itemDetail obj changes
          if (promoItem?.type == "Re-Price") {
            if (item?.menu_item_type == "bundled") {
              itemDetail.menu_item_price = promoItem.amount;
              itemDetail.actual_menu_price = promoItem?.accutalPrice;
              itemDetail.doubleActualAmout =
                promoItem?.accutalPrice + item?.modifersPrice;
            } else {
              itemDetail.menu_item_price = promoItem.discountPerItem;
            }
          }
          // itemDetail obj changes END

          promoItem.discountPerItem =
            promoItem?.type == "Re-Price" ? 0 : promoItem.discountPerItem;
          promoItem.promotionTotaldiscount =
            promoItem.discountPerItem * item?.quantity;
          // loyality voucher
          const voucherKey = applied_voucher.findIndex(
            (p) => p.Code == promoItem?.code
          );
          if (voucherKey > -1) {
            const voucherItem = applied_voucher[voucherKey];
            promoItem.isLoyaltyVoucher = "yes";
            promoItem.voucherName = voucherItem?.Code;
          }
          // loyality voucher END
        }
        // promotion changes END

        items.push(itemDetail);
      });
  }
  if (pos_cart_detail && pos_cart_detail.length > 0) {
    items = [...items, ...pos_cart_detail];
  }

  const loyalityCardName =
    applied_voucher.length > 0
      ? applied_voucher.map((voucher) => voucher?.VoucherNo)
      : [];
  let totalPromoArr = [];
  if (
    checkArray(applied_voucher) &&
    checkArray(applied_voucher.filter((p) => p.Type == "Discount"))
  ) {
    const newTotalPromoArr = applied_voucher
      .filter((p) => p.Type == "Discount")
      .map((voucher) => {
        return {
          amount: voucher?.TypeValue ?? 0,
          code: voucher?.VoucherNo ?? "",
          combo_allowed: "",
          id: getRandomString(),
          isLoyaltyVoucher: "",
          name: voucher?.Name ?? "",
          promotionTotaldiscount: voucher?.discountAmt ?? 0,
          type: "loyalty",
          voucherName: "",
        };
      });
    totalPromoArr = [...newTotalPromoArr];
  }
  if (applied_promocode && applied_promocode !== null) {
    const newTotalPromoArr = [
      {
        amount: applied_promocode?.amount ?? "0",
        code: applied_promocode?.code ?? "",
        combo_allowed: applied_promocode?.combo_allowed ?? "",
        id: getRandomString(),
        isLoyaltyVoucher: applied_promocode?.loyalty_only_promotion ?? "",
        name: applied_promocode?.name ?? "",
        promotionTotaldiscount: totalDiscount ?? 0,
        type: applied_promocode?.type ?? "",
        voucherName: applied_promocode?.promotion_name,
      },
    ];
    totalPromoArr = [...totalPromoArr, ...newTotalPromoArr];
  }
  let body = {
    uuid: user_detail.device_id,
    qr_customer_id: user_detail.id,
    merchant_id: table_detail?.Merchant?.id,
    is_new_order: isNewOrder,
    no_of_seats: table_detail?.PosTableMaster?.maximum_seat,
    order_offline_id: offlineId,
    order_time: moment().format("YYYY/MM/DD HH:mm:ss"),
    promotions: totalPromoArr,
    payment_type: [],
    order_status: "new",
    is_refund: false,
    service_charge: defaultServiceCharge,
    finishTime: null,
    tips: totalTip,
    refund: 0,
    paid_amount: 0,
    sales_tax: defaultTotalSaleTax,
    order_total: defaultTotalCartAmount,
    table_no: table_detail?.PosTableMaster?.table_number ?? "",
    channel_name: "Walk-in",
    finalTotalAmt:
      defaultTotalCartAmount +
      defaultTotalSaleTax +
      defaultServiceCharge +
      totalTip,
    receiver: {
      customer_name: null,
      customer_phone: null,
      customer_email: user_detail?.email ?? null,
      customer_address: null,
    },
    menu_item: items,
  };
  if (xgateDetails?.membership?.code) {
    body.loyaltyToken = xgateDetails?.membership?.code;
  }
  if (applied_voucher && applied_voucher.length > 0) {
    body.pointToRedeem = applied_voucher.reduce(function (sum, current) {
      return sum + parseFloat(current.TypeValue);
    }, 0);
    body.redeemPointDiscount = totalDiscount;
    body.loyaltyToken = user_detail?.card_token ?? "";
    body.loyaltyCardNumber = user_detail?.CardNo ?? "";
    body.cvc = user_detail?.card_cvc ?? "";
    body.loyaltyVouchers =
      loyalityCardName.length > 0 ? loyalityCardName.join(",") : "";
  }

  return body;
}

export function sortCartItems(cart) {
  if (cart && cart !== null) {
    let newCart = [...cart];
    let fireTimeCart = [];
    let normalCart = [];
    if (
      newCart.findIndex(
        (p) => p.firetime && p.firetime != "" && p.firetime != null
      ) > -1
    ) {
      fireTimeCart = newCart
        .filter((p) => p.firetime && p.firetime != "" && p.firetime != null)
        .sort(function (a, b) {
          return (
            moment(a.firetime).format("x") - moment(b.firetime).format("x")
          );
        });
    }
    normalCart = newCart
      .filter((p) => !p.firetime || p.firetime == "" || p.firetime == null)
      .sort(function (a, b) {
        return (
          moment(a.createDate).format("x") - moment(b.createDate).format("x")
        );
      });
    return fireTimeCart.concat(normalCart);
  }
  return cart;
}

export function parseStringToDate(
  date,
  from = "YYYY-MM-DD HH-mm-ss",
  to = "YYYY-MM-DD"
) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = moment(date, from).format(to);
    }
  } catch (e) {}
  return res;
}

export function parseStringToFloat(num, to = 2) {
  let res = 0;
  try {
    if (num && num !== null) {
      const parseNum = parseFloat(num);
      if (parseNum) {
        res = parseNum.toFixed(to);
      }
    }
  } catch (e) {}
  return res;
}

export function groupByArray(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export const imageExists = (url) =>
  new Promise(function (resolve, reject) {
    var img = new Image();
    img.src = url;

    img.onload = function () {
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
  });

export function checkSubCatAvailable(subCat, table_detail = null) {
  let res = false;
  const dates = subCat?.ApplicablePeriod;
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD HH:mm:ss");
  const currDate = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD");
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date()).utc().utcOffset(timezone).format("dddd");

  if (dates && dates !== null) {
    if (dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayArr = element?.applicable_days;
        let holidayVerified = false;
        if (
          table_detail?.TodayHoliday === true
          // || table_detail?.EveHoliday === true
        ) {
          holidayVerified = false;
          if (
            table_detail?.TodayHoliday === true &&
            element?.public_holiday == "Yes"
          ) {
            holidayVerified = true;
          }
          // if (
          //   table_detail?.EveHoliday === true &&
          //   element?.eve_public_holiday == "Yes"
          // ) {
          //   holidayVerified = true;
          // }
        }
        // if (subCat?.id == 176) {
        //   console.log("subCatttttt99999:",element,element?.start_time &&
        //   element?.end_time,
        //   element?.start_date,
        //   element?.end_date, holidayVerified,dates);
        // }
        if (
          element?.start_time &&
          element?.end_time &&
          element?.start_date &&
          element?.end_date &&
          ((dayArr.length > 0 && dayArr.indexOf(currDay) > -1) ||
            holidayVerified)
        ) {
          const beforeDay = moment(element?.start_date, dayFormat);
          const afterDay = moment(element?.end_date, dayFormat);
          let beforeTime = moment(element?.start_time, timeFormat);
          let afterTime = moment(element?.end_time, timeFormat);
          if (beforeTime.isAfter(afterTime)) {
            // if before time is greater then after time add one day
            const fullFormat = "yyyy-MM-DD HH:mm:ss";
            beforeTime = moment(`${currDate} ${beforeTime.format(timeFormat)}`);
            afterTime = moment(
              `${moment(currDate, dayFormat)
                .add(1, "days")
                .format(dayFormat)} ${beforeTime.format(timeFormat)}`
            );
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currDateTime, fullFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          } else {
            // if (subCat?.id == 176) {
            //   console.log("subCatttttt0000:", currDate, beforeDay.format(dayFormat), afterDay.format(dayFormat));
            // }
            // if (subCat?.id == 176) {
            //   console.log("subCatttttt111:", currTime, beforeTime.format(timeFormat), afterTime.format(timeFormat));
            // }
            // if (subCat?.id == 176) {
            //   console.log("subCatttttt222:", moment(currDate, dayFormat).isBetween(
            //     beforeDay,
            //     afterDay,
            //     null,
            //     "[]"
            //   ),moment(currTime, timeFormat).isBetween(
            //     beforeTime,
            //     afterTime,
            //     null,
            //     "[]"
            //   ));
            // }
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currTime, timeFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          }
        }
      }
    } else {
      res = true;
    }
  }
  // if (subCat?.id == 176) {
  //   console.log("subCatttttt:", res);
  // }
  if (
    !(
      res &&
      checkArray(subCat?.MenuItem) &&
      subCat?.MenuItem.findIndex((p) =>
        checkItemAvailable(p, timezone, table_detail)
      ) > -1
    )
  ) {
    res = false;
  }
  return res;
}

export function checkArray(arr, res = true, val = false) {
  return arr && Array.isArray(arr) && arr.length > 0 ? res : val;
}

export function checkItemAvailable(
  item,
  timezone = "00:00",
  table_detail = null
) {
  let res = false;
  // const day_format = "YYYY-MM-DD";
  // const day_currTime = moment(new Date())
  //   .utc()
  //   .utcOffset(timezone)
  //   .format(day_format);
  // if (
  //   item?.servicePeriod?.[0]?.period_start !== "" &&
  //   item?.servicePeriod?.[0]?.period_end
  // ) {
  //   const beforeDay = moment(
  //     item?.servicePeriod?.[0]?.period_start,
  //     day_format
  //   );
  //   const afterDay = moment(item?.servicePeriod?.[0]?.period_end, day_format);
  //   res = moment(day_currTime, day_format).isBetween(beforeDay, afterDay);
  // }
  // return res;

  const format = "HH:mm:ss";
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("ddd")
    .toLowerCase();
  let isInTimeSlot = false;
  // if (item?.id == "22063") {
  //   console.log(
  //     "testttttttttttttt:",
  //     beforeTime.format(format),
  //     afterTime.format(format),
  //     currTime
  //   );
  // }
  if (checkArray(item?.applicablePeriod)) {
    for (let index = 0; index < item?.applicablePeriod?.length; index++) {
      const key = Object.keys(item?.applicablePeriod[index])[0];
      const element = item?.applicablePeriod[index][key];

      const timeString = element?.time;
      const dayString = element?.availableday;
      const timeArr = timeString.split("-");
      const dayArr = dayString.split(",");

      if (timeArr.length > 1) {
        const beforeTime = moment(timeArr?.[0], format);
        const afterTime = moment(timeArr?.[1], format);
        if (
          moment(currTime, format).isBetween(beforeTime, afterTime, null, "[]")
        ) {
          isInTimeSlot = true;
          if (dayArr.length > 0 && dayArr.indexOf(currDay) > -1) {
            res = true;
            break;
          }
        }
      }
    }
  } else {
    res = true;
  }

  if (
    !res &&
    !isInTimeSlot &&
    table_detail?.service_period?.period_name &&
    item?.applicablePeriod.findIndex((p) => {
      let curRes = false;
      const conDayStr =
        p?.[table_detail?.service_period?.period_name]?.availableday;
      const curDayArr = conDayStr ? conDayStr.split(",") : [];
      // if (item?.id == "15561") {
      //   console.log(
      //     "checkkkkkkk",
      //     curDayArr,
      //     currDay,
      //     table_detail?.service_period?.period_name
      //   );
      // }
      if (curDayArr.indexOf(currDay) > -1) curRes = true;
      return curRes;
    }) > -1
  ) {
    res = true;
  }
  return res;
}

export function checkExpireMerchantTime(date, timezone = "00:00") {
  let res = false;
  const day_format = "yyyy-MM-DD HH:mm:ss";
  const currTime = moment(
    moment(new Date()).utc().utcOffset(timezone).format(day_format),
    day_format
  );
  const merchantStartDate = moment(date, "yyyy-MM-DD 00:00:00");
  const merchantEndDate = moment(
    `${moment(date, "yyyy-MM-DD")
      .add(1, "days")
      .format("yyyy-MM-DD")} 05:00:00`,
    day_format
  );
  // console.log(
  //   "tsdfasfagasddf",
  //   currTime.isBetween(merchantStartDate, merchantEndDate),
  //   currTime.format(day_format),
  //   merchantStartDate.format(day_format),
  //   merchantEndDate.format(day_format)
  // );
  if (!currTime.isBetween(merchantStartDate, merchantEndDate)) {
    res = true;
  }
  return res;
}

export function checkVoucherAvailable(
  dates,
  table_detail = null,
  timezone = "00:00"
) {
  let res = false;
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = "yyyy-MM-DD HH:mm:ss";
  const currDate = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD");
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD HH:mm:ss");
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date()).utc().utcOffset(timezone).format("dddd");

  if (dates && dates !== null) {
    let holidayVerified = false;
    if (
      table_detail?.TodayHoliday === true
      // || table_detail?.EveHoliday === true
    ) {
      holidayVerified = false;
      if (
        table_detail?.TodayHoliday === true &&
        dates?.public_holiday == "Yes"
      ) {
        holidayVerified = true;
      }
      // if (
      //   table_detail?.EveHoliday === true &&
      //   dates?.eve_public_holiday == "Yes"
      // ) {
      //   holidayVerified = true;
      // }
    }
    if (dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayString = element?.days;
        const timeArr = [element?.start_datetime, element?.end_datetime];
        const dayArr = dayString.split(",");
        // console.log("elementelement", dayArr.indexOf(currDay), currDay, dayArr);

        if (
          timeArr.length > 1 &&
          element?.start_datetime &&
          element?.end_datetime &&
          ((dayArr.length > 0 && dayArr.indexOf(currDay) > -1) ||
            holidayVerified)
        ) {
          const beforeDay = moment(
            element?.start_datetime.split(" ")?.[0],
            dayFormat
          );
          const afterDay = moment(
            element?.end_datetime.split(" ")?.[0],
            dayFormat
          );
          let beforeTime = moment(
            element?.start_datetime.split(" ")?.[1],
            timeFormat
          );
          let afterTime = moment(
            element?.end_datetime.split(" ")?.[1],
            timeFormat
          );
          if (beforeTime.isAfter(afterTime)) {
            // if before time is greater then after time add one day
            const fullFormat = "yyyy-MM-DD HH:mm:ss";
            beforeTime = moment(`${currDate} ${beforeTime.format(timeFormat)}`);
            afterTime = moment(
              `${moment(currDate, dayFormat)
                .add(1, "days")
                .format(dayFormat)} ${beforeTime.format(timeFormat)}`
            );
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currDateTime, fullFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          } else {
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currTime, timeFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          }
        }
      }
    } else {
      res = true;
    }
  }
  return res;
}

// Axios API call

// let getToken = async () => await AsyncStorage.getItem("access-token");
let getToken;

const client = axios.create({
  baseURL: "",
  // auth: { Authorization: "Bearer " + { getToken } },
});

export const apiCall = function (method, route, body = null, token = null) {
  getToken = token;
  const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  let reqObj = {
    method,
    url: `${route}`,
    data: body,
  };
  if (token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Content-Type"] = `application/json`;
    client.defaults.headers.common[
      "SoapAction"
    ] = `https://matrixapis/JSONCommand2`;
    console.log("common-headers", client.defaults.headers.common);

    // client.defaults.headers.common["origin"] = `http://localhost:3000`;
    // client.defaults.headers.common[
    //   "Content-Type"
    // ] = `application/x-www-form-urlencoded`;
    // client.defaults.proxy.host = "http://www.demoapp.com";
    // reqObj.headers = {
    //   // "Content-Type": `application/json`,
    //   // Accept: `application/json`,
    //   Authorization: `Bearer ${token}`,
    //   // SoapAction: "https://matrixapis/JSONCommand2",
    //   // "Access-Control-Allow-Credentials": "true",
    //   // "Access-Control-Allow-Methods":
    //   //   "GET, POST, PATCH, PUT, DELETE, OPTIONS, HEAD",
    //   // "Access-Control-Allow-Origin": "*",
    //   // "Access-Control-Allow-Headers":
    //   //   "Origin, X-Requested-With, Content-Type, Accept",
    // };
  }
  console.log("reqObj", reqObj);

  return client(reqObj).then(onSuccess).catch(onError);
};
