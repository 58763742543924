import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import { useSwipeable, config } from "react-swipeable";
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { useClearCache } from "react-clear-cache";
import {
  getTableDetail,
  emptyTableDetail,
  getTableDetailByFilter,
  getSelectedItemDetail,
  updateSearchText,
  emptyChangeTableAlreadyInuse,
  changeTableNotFound,
  emptyEditedItemDetail,
  updateRatingDetail,
  checkOpenOrder,
  dismissItemAvailableModal,
  emptyUserDetail,
  toggleXgatePointsPopup,
  changeRedirectHome,
} from "../../Redux/actions";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Cart from "./Cart";
import UnableToLoadData from "../Components/UnableToLoadData";
import PleaseWait from "../Components/PleaseWait";
import ImageTag from "../Components/ImageTag";
import RatingInput from "../Components/RatingInput";
import {
  pluckArray,
  getSessionStorageOrDefault,
  checkItemAvailable,
  truncateString,
  getCartNewItemCount,
  deepCopy,
  checkVoucherAvailable,
  checkSubCatAvailable,
  checkArray,
  checkExpiredSession,
  checkInactiveSession,
  checkIsNum,
  scrollToDiv,
} from "../../helpers";
import {
  poweredLogo1PngIcon,
  filter as filterImg,
  search_icon as searchImg,
  close_dark,
  reorder,
  ordrrLogoNew,
  searchIcon,
} from "../../Assets";
import { Motion, spring, presets } from "react-motion";
import AccountPopup from "../Components/AccountPopup";
import Modal from "../Components/Modal";
import XgatePointsPopup from "../Components/XgatePointsPopup";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Home({
  getTableDetail,
  table_detail,
  items_86_detail,
  user_detail,
  card_detail,
  reorder_item_ids,
  show_cart_popup,
  loading,
  filters,
  search_input,
  search_text,
  emptyTableDetail,
  filter_tag_detail,
  cart_detail,
  show_complete_page,
  selected_item_detail,
  getTableDetailByFilter,
  getSelectedItemDetail,
  emptyEditedItemDetail,
  updateSearchText,
  emptyChangeTableAlreadyInuse,
  changeTableNotFound,
  show_table_already_inuse_modal,
  show_table_not_found_modal,
  updateRatingDetail,
  clear_cache,
  checkOpenOrder,
  show_item_unavailable_modal,
  show_item_unavailable_text,
  emptyUserDetail,
  recent_order_detail,
  show_session_expired_page,
  xgate_points_popup,
  toggleXgatePointsPopup,
  changeRedirectHome,
}) {
  const merchantId = table_detail?.Merchant?.id;
  const tableNumber = table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = "not-valid";
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;

  const handleSetActive = (to, element) => {
    if (document.getElementById(`${to}`)) {
      document.getElementById(`${to}`).scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  };
  const isTableDataLoaded = getSessionStorageOrDefault(
    "isTableDataLoaded",
    false
  );

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);

  const subCategoryLength = table_detail?.SubCategory?.length;
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [lastScrollHeight, setLastScrollHeight] = useState([]);
  const subCatLength =
    table_detail?.SubCategory && table_detail?.SubCategory.length > 0
      ? table_detail?.SubCategory.length
      : 0;
  const isFilterOrSearchApplied =
    (filters && filters !== "") || (search_input && search_input !== "")
      ? true
      : false;
  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const [showCartPage, setShowCartPage] = useState(show_cart_popup ?? false);
  const [pageNumber, setPageNumber] = useState(
    subCatLength > 5 ? 5 : subCatLength
  );
  const [wrapperClass, setWrapperClass] = useState("");
  const [hideHeader, setHideHeader] = useState(false);
  const [menuHeight, setMenuHeight] = useState(false);
  const [applyFilters, setApplyFilters] = useState(filters);
  const [applySearch, setApplySearch] = useState(search_input);
  const [selectSubCat, setSelectSubCat] = useState(0);
  const [showFilterCon, setShowFilterCon] = useState(false);
  const [promotionArr, setPromotionArr] = useState([]);
  const [promotionDisplayArr, setPromotionDisplayArr] = useState([]);
  const [reorderArr, setReorderArr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const showPostTaxPrice =
    table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
      ? true
      : false;

  const getPostSaleTaxPrice = (selectItem, val) => {
    console.log("selectItemaweac:", selectItem);
    let res = parseFloat(checkIsNum(val, 0));
    if (selectItem?.dinein_price?.sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };
  const getPostSaleTaxCartPrice = (selectItem, val) => {
    let res = parseFloat(checkIsNum(val, 0));
    if (selectItem?.is_sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };

  const checkLatestVersion = () => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  };

  const items86Arr = checkArray(items_86_detail)
    ? pluckArray(items_86_detail, "id")
    : [];
  console.log("items86Arr", items86Arr);
  console.log("promotionDisplayArr", promotionDisplayArr);

  // Promotions array

  const makePromotionItem = (promotion) => {
    let copyPromotionArr = [];
    const isValidVoucher = checkVoucherAvailable(
      promotion?.datetime,
      table_detail,
      table_detail?.Merchant?.timezone_offset ?? "00:00"
    );
    if (promotion?.MenuItem?.length > 0 && isValidVoucher) {
      promotion.MenuItem.map((itemKey, promoItemIndex) => {
        const promoSubKey = table_detail?.SubCategory?.findIndex((p) => {
          let promRes = false;
          const subIdCheck =
            itemKey?.categorisation == "SubCategory"
              ? itemKey?.sub_category_id
              : itemKey?.sub_class_id;
          if (p.id == subIdCheck) {
            promRes = true;
          }
          return promRes;
        });
        const promoItemKey = table_detail?.SubCategory?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.id);
        if (
          promoSubKey > -1 &&
          checkSubCatAvailable(
            table_detail?.SubCategory?.[promoSubKey],
            table_detail
          ) &&
          table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
        ) {
          const promoItem = deepCopy(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          const accutalPrice = parseFloat(promoItem?.dinein_price?.DineIn ?? 0);
          let promoPrice = 0;
          let discountPrice = 0;
          if (promotion.type == "Amount") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice =
              accutalPrice - discountPrice >= 0
                ? accutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Percentage") {
            discountPrice = (accutalPrice * parseFloat(promotion.amount)) / 100;
            promoPrice =
              accutalPrice - discountPrice >= 0
                ? accutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Re-Price") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice = discountPrice;
          }
          promoItem.dinein_price.DineIn = promoPrice;
          const accutalPostTaxPrice = parseFloat(
            promoItem?.dinein_price?.DineInPostTax ?? 0
          );
          let promoPostTaxPrice = 0;
          let discountPostTaxPrice = 0;
          if (promotion.type == "Amount") {
            discountPostTaxPrice = parseFloat(promotion.amount);
            promoPostTaxPrice = getPostSaleTaxPrice(
              promoItem,
              accutalPrice - discountPostTaxPrice >= 0
                ? accutalPrice - discountPostTaxPrice
                : 0
            );
          } else if (promotion.type == "Percentage") {
            discountPostTaxPrice =
              (accutalPostTaxPrice * parseFloat(promotion.amount)) / 100;
            promoPostTaxPrice =
              accutalPostTaxPrice - discountPostTaxPrice >= 0
                ? accutalPostTaxPrice - discountPostTaxPrice
                : 0;
          } else if (promotion.type == "Re-Price") {
            discountPostTaxPrice = parseFloat(promotion.amount);
            promoPostTaxPrice = getPostSaleTaxPrice(
              promoItem,
              discountPostTaxPrice
            );
          }
          console.log(
            "discountPostTaxPrice",
            promotion.type,
            discountPostTaxPrice,
            promoPostTaxPrice
          );
          promoItem.dinein_price.DineInPostTax = promoPostTaxPrice;
          promoItem.promotions = {
            id: promotion.id,
            type: promotion.type,
            code: promotion.code,
            name: promotion.promotion_name,
            auto_apply: promotion.auto_apply,
            accutalPrice: accutalPrice,
            accutalPostTaxPrice: accutalPostTaxPrice,
            promotionTotaldiscount: discountPrice,
            amount: promotion.amount,
            discountPerItem: parseFloat(discountPrice),
            combo_allowed: promotion.combo_allowed,
            isLoyaltyVoucher:
              promotion?.loyalty_only_promotion == "1" ? "yes" : "no",
            voucherName: "",
          };
          copyPromotionArr.push(promoItem);
        }
      });
    }
    return copyPromotionArr;
  };

  const getPromotionItemDetails = () => {
    let copyPromotionArr = [];
    if (
      table_detail?.Promotions &&
      table_detail?.Promotions.filter(
        (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
      ).length > 0
    ) {
      const copyPromotions = deepCopy(
        table_detail?.Promotions.filter(
          (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
        )
      );
      copyPromotions.map((promotion, promoKey) => {
        const newProArr = makePromotionItem(promotion);
        if (newProArr.length > 0) {
          copyPromotionArr = [...copyPromotionArr, ...newProArr];
        }
      });
    }
    if (user_detail?.promotions && user_detail?.promotions.length > 0) {
      const copyCopounPromotions = deepCopy(user_detail?.promotions);
      copyCopounPromotions.map((promotion, promoKey) => {
        const newProArr = makePromotionItem(promotion);
        if (newProArr.length > 0) {
          copyPromotionArr = [...copyPromotionArr, ...newProArr];
        }
      });
    }
    setPromotionArr(copyPromotionArr);
  };

  // reorder array
  const makeReorderItems = (reorder) => {
    let copyReorderArr = [];

    if (reorder_item_ids?.length > 0) {
      reorder_item_ids.map((itemKey, promoItemIndex) => {
        const promoSubKey = table_detail?.SubCategory?.findIndex(
          (p) => p.MenuItem.findIndex((q) => q.id == itemKey?.menu_item_id) > -1
        );
        const promoItemKey = table_detail?.SubCategory?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.menu_item_id);
        if (
          promoSubKey > -1 &&
          table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey] &&
          checkItemAvailable(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey],
            table_detail?.Merchant?.timezone_offset ?? "00:00",
            table_detail
          )
        ) {
          const promoItem = deepCopy(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          promoItem.recomItem = itemKey;
          copyReorderArr.push(promoItem);
        }
      });
    }
    if (copyReorderArr.length > 0)
      setReorderArr([...reorderArr, ...copyReorderArr]);
  };

  // console.log("promotionArr", promotionArr);

  // infinite scroller
  const [hasMore, setHasMore] = useState(true);
  const [sliceHasMore, setSliceHasMore] = useState(3);
  const [hasMoreChanged, setHasMoreChanged] = useState(false);
  const [scrollerItems, setScrollerItems] = useState(
    table_detail?.SubCategory?.slice(0, sliceHasMore)
  );
  const searchInputContainer = useRef(null);
  const history = useHistory();
  let { id } = useParams();
  const prevHasMoreChanged = usePrevious(hasMoreChanged);
  const cartCount = getCartNewItemCount(cart_detail);

  useEffect(() => {
    if (
      loading === false &&
      (show_table_not_found_modal === false || isTableDataLoaded === false) &&
      (table_detail === null || table_detail === undefined)
    ) {
      getTableDetail(id, deviceId);
    } else if (
      loading === false &&
      ((table_detail?.PosTableMaster?.qr_code_id &&
        table_detail?.PosTableMaster?.qr_code_id !== id) ||
        clear_cache)
    ) {
      clearSessionDetails();
    } else if (loading === false && isTableDataLoaded === false) {
      emptyTableDetail();
    }
    // else if (
    //   loading === false &&
    //   !clear_cache &&
    //   table_detail &&
    //   table_detail !== null
    // ) {
    // checkOpenOrder(merchantId, deviceId, tableNumber);
    // }

    // redirect login if not
    if (
      user_detail === null ||
      user_detail === undefined ||
      show_table_not_found_modal === true
    ) {
      history.push("/login");
    }
  }, [show_table_not_found_modal, user_detail, clear_cache]);

  useEffect(() => {
    /* check expired session */
    const checkExpired = checkExpiredSession(table_detail);
    const checkInactive =
      recent_order_detail === null ? checkInactiveSession() : false;
    if (checkExpired || checkInactive) {
      history.push("/session-expired");
    }
    /* check expired session END*/
  }, [cart_detail]);

  useEffect(() => {
    if (show_session_expired_page) {
      history.push("/session-expired");
    }
  }, [show_session_expired_page]);

  useEffect(() => {
    if (show_complete_page) {
      history.push("/completed");
    }
  }, [show_complete_page]);

  useEffect(() => {
    let newPromoDisplayArr = [];
    if (
      checkArray(promotionArr) &&
      table_detail?.PromotionalItems !== null &&
      checkArray(Object.keys(table_detail?.PromotionalItems))
    ) {
      const copyPromotionArr = deepCopy(promotionArr);
      Object.keys(table_detail?.PromotionalItems).map((promoInx) => {
        const promoMenuIds = table_detail?.PromotionalItems[promoInx];
        if (checkArray(promoMenuIds)) {
          promoMenuIds.map((promoMenuId) => {
            const promomenuIndex = copyPromotionArr.findIndex(
              (p) => p.id == promoMenuId && p.promotions.id == promoInx
            );
            if (promomenuIndex > -1) {
              const promoItem = copyPromotionArr[promomenuIndex];
              if (
                filterPromoItemTableDetails(promoItem) &&
                checkItemAvailable(
                  promoItem,
                  table_detail?.Merchant?.timezone_offset ?? "00:00",
                  table_detail
                )
              ) {
                newPromoDisplayArr.push(promoItem);
              }
            }
          });
        }
      });
    } else {
      const copyPromotionArr = deepCopy(promotionArr);
      newPromoDisplayArr = copyPromotionArr.filter(
        (promoItem) =>
          filterPromoItemTableDetails(promoItem) &&
          checkItemAvailable(
            promoItem,
            table_detail?.Merchant?.timezone_offset ?? "00:00",
            table_detail
          )
      );
    }
    setPromotionDisplayArr(newPromoDisplayArr);
  }, [promotionArr]);

  const scrollToElementDiv = (additionHeight = 253) => {
    const targetEl = document.getElementById(
      `element_item_id_${selected_item_detail?.id}`
    );
    const targetDiv = document.getElementById("menu-sections");
    targetDiv.scrollTo({
      left: 0,
      top: targetEl.offsetTop - additionHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    changeRedirectHome(false);
    getPromotionItemDetails();
    makeReorderItems();
    checkLatestVersion();
    if (selected_item_detail?.id !== undefined && (!checkArray(reorderArr) || checkArray(promotionDisplayArr))) {
      if (
        document.getElementById(`element_item_id_${selected_item_detail?.id}`)
      ) {
        scrollToElementDiv();
      }
    }

    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  useEffect(() => {
    if (
      selected_item_detail?.id !== undefined &&
      (checkArray(reorderArr) || checkArray(promotionDisplayArr))
    ) {
      if (
        document.getElementById(`element_item_id_${selected_item_detail?.id}`)
      ) {
        scrollToElementDiv();
      }
    }
  }, [reorderArr, promotionDisplayArr]);

  // useEffect(() => {
  //   if (hasMoreChanged !== prevHasMoreChanged) {
  //     const newSubItem = table_detail?.SubCategory?.[selectSubCat];
  //     if (newSubItem) {
  //       const subItemEl = document.getElementById(`sub_id_${newSubItem.id}`);
  //       if (subItemEl) {
  //         subItemEl.click();
  //       }
  //     }
  //   }
  // }, [scrollerItems]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  const fetchMoreData = () => {
    if (scrollerItems.length === subCategoryLength) {
      setHasMore(false);
      return;
    }
    setSliceHasMore(sliceHasMore + 3);
    setScrollerItems(table_detail?.SubCategory?.slice(0, sliceHasMore + 3));
  };

  function checkItemHasTag(item) {
    let res = filters && filters !== "" ? false : true;
    const filterArr = filters && filters !== "" ? filters.split(",") : [];
    const applyFilterArr =
      filterArr.length > 0 && filter_tag_detail && filter_tag_detail !== ""
        ? filter_tag_detail.filter((item) =>
            filterArr.includes(item.id.toString())
          )
        : [];
    const filterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => !p.name.includes("Contains"))
            .map((filter) => {
              return `${filter.id}`;
            })
        : [];
    const containfilterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => p.name.includes("Contains"))
            .map((filter) => {
              return `${filter.id}`;
            })
        : [];

    if (
      containfilterSymbols.length > 0 &&
      filterSymbols.length == 0 &&
      item?.allergy_tags?.length == 0
    ) {
      res = true;
    } else if (res == false && item?.allergy_tags?.length > 0) {
      const allergies = item.allergy_tags;
      for (let i = 0; i < allergies.length; i++) {
        const element = allergies[i];
        if (
          containfilterSymbols.length > 0 &&
          containfilterSymbols.includes(`${element}`)
        ) {
          res = false;
          break;
        } else if (
          filterSymbols.includes(`${element}`) ||
          (filterSymbols.length == 0 &&
            containfilterSymbols.length > 0 &&
            !containfilterSymbols.includes(`${element}`))
        ) {
          res = true;
          if (containfilterSymbols.length == 0) break;
        }
      }
    }
    return res;
  }

  function checkItemHasMatchingModifiers(item) {
    let res = true;
    if (item && search_input && search_input !== "") {
      res = false;
      const searchInputText = search_input.toLowerCase();
      if (item?.menu_item_name.toLowerCase().indexOf(searchInputText) > -1) {
        res = true;
      } else if (
        item?.PosMenuitemsModifier &&
        item?.PosMenuitemsModifier.length > 0 &&
        item?.PosMenuitemsModifier.findIndex(
          (p) =>
            p?.Recipe?.recipe_name?.toLowerCase().indexOf(searchInputText) >
              -1 ||
            p?.Ingredient?.ingredient_name
              ?.toLowerCase()
              .indexOf(searchInputText) > -1
        ) > -1
      ) {
        res = true;
      } else if (
        // description
        item?.description &&
        item?.description.toLowerCase().indexOf(searchInputText) > -1
      ) {
        res = true;
      }
    }
    return res;
  }

  function checkItemHasMatch(subCat, item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function checkPromoItemHasMatch(item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
      if (res) console.log("item:", item?.menu_item_name, res);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        "promotions".indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function filterSubCatTableDetails(subCat) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant &&
      table_detail.SubCategory &&
      table_detail.SubCategory.length > 0
    ) {
      return (
        subCat?.MenuItem.findIndex(
          (p) =>
            checkItemHasMatch(subCat, p) &&
            checkItemAvailable(
              p,
              table_detail?.Merchant?.timezone_offset ?? "00:00",
              table_detail
            )
        ) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }
  function filterPromoCatTableDetails() {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END

      return (
        promotionArr.findIndex((p) => checkPromoItemHasMatch(p)) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          "promotion".toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }

  function filterItemTableDetails(subCat, item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant &&
      table_detail.SubCategory &&
      table_detail.SubCategory.length > 0
    ) {
      return checkItemHasMatch(subCat, item);
    }
    return true;
  }

  function filterPromoItemTableDetails(item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END
      return checkPromoItemHasMatch(item);
    }
    return true;
  }

  const toggleCartPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      setShowCartPage(!showCartPage);
      if (!showCartPage) {
        checkOpenOrder(merchantId, deviceId, tableNumber);
      }
    },
    [showCartPage]
  );

  const ratingChangeHandler = useCallback(
    (value) => {
      const merchantId = user_detail?.merchant_id;
      const customerId = user_detail?.id;
      updateRatingDetail({
        rating: value ?? "",
        merchant_id: merchantId ?? "",
        customer_id: customerId ?? "",
        order_id: recent_order_detail?.order_list?.[0]?.order_offline_id ?? "",
        review: "",
      });
    },
    [user_detail]
  );

  console.log("id", id);

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;

    // if (isFilterOrSearchApplied) {
    //   setLastScrollHeight([]);
    //   setPageNumber(subCatLength > 5 ? 5 : subCatLength);
    // } else {
    //   if (lastScrollHeight.indexOf(headerPageScrollHeight) === -1) {
    //     setLastScrollHeight([...lastScrollHeight, headerPageScrollHeight]);
    //   }
    // }

    // console.log(
    //   "Check scrolll:",
    //   lastScrollTop,
    //   headerPageOffset,
    //   headerPageClientHeight,
    //   headerPageScrollHeight
    // );

    // if (headerPageOffset > lastScrollTop) {
    //   // down scroll
    //   const showNextPage =
    //     headerPageOffset + headerPageClientHeight >=
    //     headerPageScrollHeight - 20;
    //   if (table_detail?.SubCategory && showNextPage) {
    //     if (pageNumber + 5 < table_detail?.SubCategory.length) {
    //       setPageNumber(pageNumber + 5);
    //     } else {
    //   setPageNumber(table_detail?.SubCategory.length);
    //     }
    //   }
    // } else {
    //   // up scroll
    //   if (
    //     pageNumber > 5 &&
    //     lastScrollHeight.length > 1 &&
    //     headerPageOffset + headerPageClientHeight <
    //       lastScrollHeight[lastScrollHeight.length - 2]
    //   ) {
    //     if (table_detail?.SubCategory.length < 5) {
    //       setPageNumber(table_detail?.SubCategory.length);
    //     } else {
    //       setPageNumber(pageNumber - 5);
    //     }
    //  setLastScrollHeight(
    //       lastScrollHeight.slice(0, lastScrollHeight.length - 2)
    //     );
    //   }
    // }

    if (
      headerPageOffset &&
      headerPageOffset > 50 &&
      headerPageScrollHeight > headerPageClientHeight - 168 &&
      hideHeader === false
    ) {
      setHideHeader(true);
      setWrapperClass("scroll_active");
    } else if (headerPageOffset === 0 && hideHeader === true) {
      setHideHeader(false);
      setWrapperClass("");
    }
    // if (isFilterOrSearchApplied) {
    //   setLastScrollTop(0);
    // } else {
    //   setLastScrollTop(headerPageOffset);
    // }
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const clearSessionDetails = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  };

  function getBasketTotalAmount() {
    let res = 0;
    if (
      cart_detail &&
      cart_detail.filter((p) => p.is_added == false).length > 0
    ) {
      cart_detail
        .filter((p) => p.is_added == false)
        .forEach((item) => {
          const amount =
            showPostTaxPrice && item?.is_sales_tax
              ? getPostSaleTaxCartPrice(item, item.total)
              : parseFloat(item.total ?? 0);
          res += amount;
        });
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {res.toFixed(2)}
      </>
    );
  }
  function getBasketTotalQuantity() {
    let res = getCartNewItemCount(cart_detail);
    return <div className="items-count"> {res} </div>;
  }

  function getItemTotalQuantity(item, type) {
    let res = 0;
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    if (cartItemKey !== -1) {
      res = cart_detail
        .filter((cartItem) => cartItem.id == item.id)
        .reduce(function (sum, current) {
          return sum + parseInt(current.quantity);
        }, 0);
    }
    return res > 0 ? <span className="txt-green">x{res}</span> : null;
  }

  function getItemTags(item) {
    let res = null;
    if (item?.is_popular && item?.is_popular === 1) {
      res = <span className="tag">Popular</span>;
    } else if (item?.is_trending && item?.is_trending === 1) {
      res = <span className="tag blue">Trending</span>;
    }
    return res;
  }

  function addFilter(id) {
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex === -1) {
        applyFiltersArr.push(id);
      } else {
        applyFiltersArr.splice(checkIndex, 1);
      }
      setApplyFilters(applyFiltersArr.join());
    }
  }

  function countActiveFilter() {
    let res = null;
    let applyFiltersArr = filters === "" ? [] : filters.split(",");
    const checkIndex = applyFiltersArr.findIndex((p) => p == id);
    if (applyFiltersArr.length > 0) {
      res = <span className="count">{applyFiltersArr.length}</span>;
    }
    return res;
  }

  function checkActiveFilter(id) {
    let res = false;
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex != -1) {
        res = true;
      }
    }
    return res;
  }

  function getFilterModalTags() {
    let res = null;
    if (filter_tag_detail && filter_tag_detail.length > 0) {
      res = (
        <ul>
          {filter_tag_detail.map(function (tag, index) {
            const checkActive = checkActiveFilter(tag.id);
            let style = {
              border: `2px solid ${tag?.color ?? "#000"}`,
              color: `${tag?.color ?? "#000"}`,
            };
            if (checkActive) {
              style.background = tag?.color ?? "";
              style.color = "#fff";
            }
            return (
              <li style={style} key={index} onClick={() => addFilter(tag.id)}>
                {tag?.name ? tag?.name.replace("Contains", "No") : ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            return (
              <li style={style} className={className} key={index}>
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function getItemDiv(item, itemKey, type) {
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      const checkAutoPay =
        promotionArr.length > 0 && item?.promotions?.id === undefined
          ? promotionArr.findIndex(
              (p) => p.id == item.id && p?.promotions?.auto_apply == "Yes"
            )
          : -1;
      if (checkAutoPay > -1) {
        item = promotionArr[checkAutoPay];
      }
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    const isPromotion = item?.promotions?.id ? true : false;
    const checkAvailable =
      item?.is_86_item == "false" && !items86Arr.includes(item?.id)
        ? true
        : false;
    const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(item?.image);
    return (
      <div
        className={`menu-box ${cartItemKey !== -1 ? " active" : ""} ${
          checkAvailable === false ? " disabled" : ""
        }`}
        key={itemKey}
        id={`${type === "promotion" ? "promo" : "element"}_${
          item?.id !== undefined ? "item_id_" + item?.id : "null"
        }`}
      >
        {getItemTags(item)}
        <div
          onClick={() => {
            if (checkAvailable) {
              getSelectedItemDetail(item);
              emptyEditedItemDetail();
              if (item?.menu_item_type == "bundled")
                history.push("/bundle-add-item");
              else history.push("/add-item");
            }
          }}
          className="row mx-0 align-items-start"
        >
          {checkValidImg && (
            <figure className="col-3 menu-img">
              <ImageTag
                src={item?.image}
                defaultimage={table_detail?.Merchant?.logo}
                removeImage={true}
                removeCol={true}
                replaceColName={"col-12"}
                removeColName={"col-7"}
              />
            </figure>
          )}

          <div
            className={`${
              checkValidImg ? "col-9" : "col-12 px-0"
            }  menu-content`}
          >
            <h4>
              {getItemTotalQuantity(item, type)} {item?.menu_item_name}
            </h4>
            <p> {item?.description}</p>
            <div className="label_row">
              <p className={`pricing ${isPromotion ? "strike-off" : ""}`}>
                {" "}
                <small>{`${table_detail?.currency ?? ""}`}</small>
                {isPromotion
                  ? showPostTaxPrice
                    ? item?.promotions?.accutalPostTaxPrice
                      ? parseFloat(
                          item?.promotions?.accutalPostTaxPrice
                        ).toFixed(2)
                      : "0.00"
                    : item?.promotions?.accutalPrice
                    ? parseFloat(item?.promotions?.accutalPrice).toFixed(2)
                    : "0.00"
                  : showPostTaxPrice
                  ? item?.dinein_price?.DineInPostTax
                    ? parseFloat(item?.dinein_price?.DineInPostTax).toFixed(2)
                    : "0.00"
                  : item?.dinein_price?.DineIn
                  ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                  : "0.00"}
              </p>
              {isPromotion && (
                <p className="ml-2 promotion-price">
                  <small>{`${table_detail?.currency ?? ""}`}</small>
                  {showPostTaxPrice
                    ? item?.dinein_price?.DineInPostTax
                      ? parseFloat(item?.dinein_price?.DineInPostTax).toFixed(2)
                      : "0.00"
                    : item?.dinein_price?.DineIn
                    ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                    : "0.00"}
                </p>
              )}
              {getItemFilterTags(item)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getHomePage() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className={`wrapper custom_pb ${hideHeader ? "scroll_active" : ""}`}
        >
          <Motion
            defaultStyle={{ x: 170 }}
            style={{
              x: spring(hideHeader === false ? 170 : 0, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <header
                style={{
                  height: `${x}px`,
                }}
              >
                <div className="container">
                  <div className="row mx-0 profile-row d-none">
                    <div className="circle-img col-2">
                      <figure className="">
                        <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                      </figure>
                    </div>
                    <div className="naming-sec col-6">
                      <h4> {table_detail?.Merchant?.account_name} </h4>
                      <p className="md-txt h5">
                        {" "}
                        Table #{" "}
                        <span className="h3">
                          {" "}
                          {table_detail?.PosTableMaster?.table_number}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-4 notify">
                      <a href="#_" className="notification">
                        {/* <span className="counts"> 10 </span> */}
                      </a>
                      <a href="#_" className="profile">
                        {" "}
                      </a>
                    </div>
                  </div>

                  <div className="row mx-0 welcome-sec">
                    <div className="circle-img-lg">
                      <figure className="logo">
                        <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                      </figure>
                    </div>
                    <div className="right_col">
                      <div className="notify">
                        <a
                          href="#_"
                          onClick={() => {
                            // history.push("/cart")
                            if (cart_detail?.length > 0) {
                              toggleCartPopUp();
                            }
                          }}
                          className="notification"
                          style={{
                            marginRight: `${loyaltyEnabled ? "20px" : "0px"}`,
                          }}
                        >
                          {cartCount > 0 ? (
                            <span className="counts"> {cartCount} </span>
                          ) : null}
                        </a>
                        {loyaltyEnabled && (
                          <a
                            href="#"
                            onClick={() => setShowAccountPopUp(1)}
                            className="profile"
                          >
                            {" "}
                          </a>
                        )}
                      </div>

                      <div className="naming-sec">
                        <p> WELCOME TO </p>
                        <h3> {table_detail?.Merchant?.account_name} </h3>
                        {/* <p className="powered_text">
                          {" "}
                          Powered By{" "}
                          <span className="powered_logo">
                            {" "}
                            <ImageTag src={ordrrLogoNew} />
                          </span>
                        </p> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="rating_row">
                    <p>Tap to rate, slide for half star</p>
                    <div className="rating_outer">
                      <RatingInput
                        star={parseFloat(user_detail?.rating ?? 0)}
                        ratingChangeHandler={ratingChangeHandler}
                      />
                    </div>
                  </div> */}

                  <div className="row mx-0 filter_row">
                    <span className="filter_icon">
                      <ImageTag
                        src={filterImg}
                        alt="filter"
                        onClick={() => {
                          setShowFilterCon(!showFilterCon);
                          setApplyFilters(filters);
                        }}
                      />{" "}
                      {countActiveFilter()}
                    </span>
                    <input
                      type="search"
                      className="search-bar input-text"
                      placeholder="Dishes, Ingredients, or Categories"
                      value={search_text}
                      ref={searchInputContainer}
                      // onKeyDown={(e) => {
                      //   if (e.key === "Enter") {
                      //     searchInputContainer?.current.blur();
                      //     setApplySearch(e.target.value);
                      //     getTableDetailByFilter(
                      //       id,
                      //       applyFilters,
                      //       e?.target?.value
                      //     );
                      //   }
                      // }}
                      onChange={(e) => {
                        if (e?.target?.value === "") {
                          searchInputContainer?.current.blur();
                          setApplySearch("");
                          getTableDetailByFilter(id, applyFilters, "");
                        } else {
                          setApplySearch(e?.target?.value);
                          updateSearchText(e?.target?.value);
                          getTableDetailByFilter(
                            id,
                            applyFilters,
                            e?.target?.value
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </header>
            )}
          </Motion>
          <section className="middle-sec ">
            <div className="filter_row menus_row">
              <span className="filter_icon">
                <ImageTag
                  // src={filterImg}
                  src={searchIcon}
                  alt="search"
                  onClick={() => {
                    scrollToDiv("menu-sections", true);
                    searchInputContainer?.current.focus();
                    // setShowFilterCon(!showFilterCon);
                    // setApplyFilters(filters);
                  }}
                />{" "}
                {/* {countActiveFilter()} */}
              </span>

              <div className="menu-row row mx-0">
                <div className="col d-flex overflow-auto px-0 mr-2">
                  {checkArray(promotionDisplayArr) &&
                    filterPromoCatTableDetails() && (
                      <Link
                        className={`col link-box`}
                        activeClass="active"
                        to={"promotion_section"}
                        id={"promotion_section"}
                        containerId="menu-sections"
                        spy={true}
                        smooth={true}
                        // delay={100}
                        offset={0}
                        duration={300}
                        onSetActive={(to, element) =>
                          handleSetActive(to, element)
                        }
                        // onClick={() => {
                        //   setSelectSubCat(subIndex);
                        //   // const roundNum = Math.ceil((subIndex + 1) / 5);
                        //   // setPageNumber(roundNum * 5);
                        //   // setLastScrollHeight([]);
                        // }}
                      >
                        <Motion
                          defaultStyle={{ h: 65 }}
                          style={{
                            h: spring(hideHeader === false ? 65 : 35, {
                              precision: 1,
                              stiffness: 300,
                              damping: 44,
                            }),
                          }}
                        >
                          {({ h }) => (
                            <a
                              // href={
                              //   subCat?.name ? "#sub_id_" + subCat?.id : "#"
                              // }

                              className="blue"
                              style={{
                                height: `${h}px`,
                                fontSize: `${hideHeader ? "14px" : "11px"}`,
                                // WebkitTransform: `translate3d(${h}px, 0, 0)`,
                                // transform: `translate3d(${h}px, 0, 0)`,
                              }}
                            >
                              {"Promotions"}
                            </a>
                          )}
                        </Motion>
                      </Link>
                    )}
                  {reorderArr?.length > 0 &&
                    !isFilterOrSearchApplied &&
                    user_detail?.total_orders > 0 && (
                      <Link
                        className={`col link-box`}
                        activeClass="active"
                        to={"recommended_section"}
                        id={"recommended_section"}
                        containerId="menu-sections"
                        spy={true}
                        smooth={true}
                        // delay={100}
                        offset={0}
                        duration={300}
                        onSetActive={(to, element) =>
                          handleSetActive(to, element)
                        }
                        // onClick={() => {
                        //   setSelectSubCat(subIndex);
                        //   // const roundNum = Math.ceil((subIndex + 1) / 5);
                        //   // setPageNumber(roundNum * 5);
                        //   // setLastScrollHeight([]);
                        // }}
                      >
                        <Motion
                          defaultStyle={{ h: 65 }}
                          style={{
                            h: spring(hideHeader === false ? 65 : 35, {
                              precision: 1,
                              stiffness: 300,
                              damping: 44,
                            }),
                          }}
                        >
                          {({ h }) => (
                            <a
                              // href={
                              //   subCat?.name ? "#sub_id_" + subCat?.id : "#"
                              // }

                              className="blue"
                              style={{
                                height: `${h}px`,
                                fontSize: `${hideHeader ? "14px" : "11px"}`,
                                // WebkitTransform: `translate3d(${h}px, 0, 0)`,
                                // transform: `translate3d(${h}px, 0, 0)`,
                              }}
                            >
                              {"Recommended"}
                            </a>
                          )}
                        </Motion>
                      </Link>
                    )}
                  {table_detail?.SubCategory &&
                    table_detail?.SubCategory.filter(
                      (SubCat) =>
                        filterSubCatTableDetails(SubCat) &&
                        checkSubCatAvailable(SubCat, table_detail)
                    ).map(function (subCat, subIndex) {
                      return (
                        <Link
                          className={`col link-box`}
                          activeClass="active"
                          to={subCat?.name ? "sub_id_" + subCat?.id : "#"}
                          id={subCat?.name ? "sub_id_" + subCat?.id : "#"}
                          containerId="menu-sections"
                          spy={true}
                          smooth={true}
                          isDynamic={true}
                          delay={100}
                          offset={0}
                          scrolloffset={10}
                          duration={300}
                          onSetActive={(to, element) => {
                            // console.log("chekckkkk", to);
                            // alert(to);
                            handleSetActive(to, element);
                          }}
                          key={subIndex}
                          onClick={(e) => {
                            setSelectSubCat(subIndex);

                            // infinite scroller
                            // if (subIndex > sliceHasMore) {
                            //   const sliceMoreIndex =
                            //     subIndex >= subCategoryLength - 2
                            //       ? table_detail?.SubCategory?.length -
                            //         1
                            //       : subIndex + 2;
                            //   // console.log("chekkkkkkkk=>>>", sliceMoreIndex);
                            //   setSliceHasMore(sliceMoreIndex);
                            //   console.log("innnnnnnnnnnn");
                            //   setScrollerItems(
                            //     table_detail?.SubCategory?.slice(
                            //       0,
                            //       sliceMoreIndex
                            //     )
                            //   );
                            //   console.log("outtttttttt");

                            //   setHasMoreChanged(!hasMoreChanged);
                            //   // setTimeout(() => {
                            //   //   e.target.click();
                            //   // }, 100);
                            // }
                            // infinite scroller END

                            // const roundNum = Math.ceil((subIndex + 1) / 5);
                            // setPageNumber(roundNum * 5);
                            // setLastScrollHeight([]);
                          }}
                        >
                          <Motion
                            defaultStyle={{ h: 65 }}
                            style={{
                              h: spring(hideHeader === false ? 65 : 35, {
                                precision: 1,
                                stiffness: 300,
                                damping: 44,
                              }),
                            }}
                          >
                            {({ h }) => {
                              const backStyle = subCat?.images
                                ? `url(${subCat?.images})  no-repeat center center / cover`
                                : subCat?.pos_color ?? "#2793c2";
                              const backClass = subCat?.images
                                ? "overlay-show"
                                : "";
                              return (
                                <a
                                  // href={
                                  //   subCat?.name ? "#sub_id_" + subCat?.id : "#"
                                  // }

                                  className={`blue ${backClass}`}
                                  style={{
                                    height: `${h}px`,
                                    background: backStyle,
                                    // WebkitTransform: `translate3d(${h}px, 0, 0)`,
                                    // transform: `translate3d(${h}px, 0, 0)`,
                                  }}
                                >
                                  <span>{subCat?.name}</span>
                                </a>
                              );
                            }}
                          </Motion>
                        </Link>
                      );
                    })}
                </div>
                {table_detail?.SubCategory &&
                  table_detail?.SubCategory.filter(
                    (SubCat) =>
                      filterSubCatTableDetails(SubCat) &&
                      checkSubCatAvailable(SubCat, table_detail)
                  ).length > 0 && (
                    <div className="menu_sec col-1 px-0">
                      <div
                        id="basic-button"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                      >
                        <MoreHorizIcon
                          // sx={{ color: `${hideHeader ? "#1ebdc3" : "#2793c2"}` }}
                          sx={{ color: "#1ebdc3" }}
                        />
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem>Jump to:</MenuItem>
                        {table_detail?.SubCategory &&
                          table_detail?.SubCategory.filter(
                            (SubCat) =>
                              filterSubCatTableDetails(SubCat) &&
                              checkSubCatAvailable(SubCat, table_detail)
                          ).map(function (subCat, subIndex) {
                            const backStyle = subCat?.images
                              ? `url(${subCat?.images})  no-repeat center center / cover`
                              : subCat?.pos_color ?? "#2793c2";
                            const backClass = subCat?.images
                              ? "overlay-show"
                              : "";
                            return (
                              <li>
                                <Link
                                  // className={`listing-anchor${
                                  //   !hideHeader ? " blue" : ""
                                  // }`}
                                  className={`listing-anchor`}
                                  activeClass="active"
                                  to={
                                    subCat?.name ? "sub_id_" + subCat?.id : "#"
                                  }
                                  id={
                                    subCat?.name ? "sub_id_" + subCat?.id : "#"
                                  }
                                  containerId="menu-sections"
                                  spy={true}
                                  smooth={true}
                                  isDynamic={true}
                                  delay={100}
                                  offset={0}
                                  scrolloffset={10}
                                  duration={300}
                                  onSetActive={(to, element) => {
                                    // console.log("chekckkkk", to);
                                    // alert(to);
                                    handleSetActive(to, element);
                                  }}
                                  key={subIndex}
                                  onClick={(e) => {
                                    setSelectSubCat(subIndex);
                                    handleClose();
                                  }}
                                >
                                  <span>{subCat?.name}</span>
                                </Link>
                              </li>
                            );
                          })}
                      </Menu>
                    </div>
                  )}
              </div>
            </div>

            <div
              className="menu-sections"
              id="menu-sections"
              onScroll={scrolledElement}
              {...handlers}
            >
              {/* <InfiniteScroll
                dataLength={scrollerItems.length}
                next={() => fetchMoreData()}
                // style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                // inverse={true}
                hasMore={hasMore}
                // loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                scrollableTarget="menu-sections"
              > */}
              {checkArray(promotionDisplayArr) &&
                filterPromoCatTableDetails() && (
                  <Element
                    name={"promotion_section"}
                    id={"promotion_section_con"}
                  >
                    <h3 className="title"> Promotions</h3>
                    {promotionDisplayArr.map(function (item, itemKey) {
                      return getItemDiv(item, itemKey, "promotion");
                    })}
                  </Element>
                )}
              {reorderArr?.length > 0 &&
                !isFilterOrSearchApplied &&
                user_detail?.total_orders > 0 && (
                  <Element name={"recommended_section"}>
                    <div className="recommended_section">
                      <h3 className="title">Recommended for you</h3>
                      <div className="recommended_slider">
                        {reorderArr?.map((recomItem, recomIdx) => {
                          // console.log("recomItem", recomItem);
                          return (
                            <div className="swiper-slide">
                              <div className="box recomItem">
                                <div className="title_row">
                                  <div className="item_title">
                                    <span>
                                      x{recomItem?.recomItem?.quantity ?? "1"}
                                    </span>{" "}
                                    <text>{recomItem?.menu_item_name}</text>
                                  </div>
                                  <div className="price">
                                    <small>$</small>
                                    {showPostTaxPrice
                                      ? recomItem?.dinein_price?.DineInPostTax
                                      : recomItem?.dinein_price?.DineIn}
                                  </div>
                                </div>

                                <div className="date_row">
                                  <p className="date">
                                    {recomItem?.recomItem?.created_on ?? ""}
                                  </p>
                                  <p
                                    className="reorder"
                                    onClick={() => {
                                      const checkPeriodAvailable =
                                        recomItem?.menu_item_type == "bundled"
                                          ? true
                                          : checkItemAvailable(
                                              recomItem,
                                              table_detail?.Merchant
                                                ?.timezone_offset ?? "00:00",
                                              table_detail
                                            );
                                      const checkAvailable =
                                        recomItem?.is_86_item == "false" &&
                                        !items86Arr.includes(recomItem?.id)
                                          ? true
                                          : false;
                                      if (
                                        checkAvailable &&
                                        checkPeriodAvailable
                                      ) {
                                        getSelectedItemDetail(recomItem);
                                        emptyEditedItemDetail();
                                        if (
                                          recomItem?.menu_item_type == "bundled"
                                        )
                                          history.push("/bundle-add-item");
                                        else history.push("/add-item");
                                      }
                                    }}
                                  >
                                    <ImageTag src={reorder} />
                                    Reorder
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Element>
                )}
              {/* .slice(
                  0,
                  isFilterOrSearchApplied ? subCatLength : pageNumber
                ) */}
              {table_detail?.SubCategory.filter(
                (SubCat) =>
                  filterSubCatTableDetails(SubCat) &&
                  checkSubCatAvailable(SubCat, table_detail)
              ).map(function (subCat, index) {
                const menuItem = subCat?.MenuItem ?? undefined;
                return (
                  <Element
                    key={index}
                    className={`${
                      index ===
                      table_detail?.SubCategory?.filter(
                        (SubCatt) =>
                          filterSubCatTableDetails(SubCatt) &&
                          checkSubCatAvailable(SubCatt, table_detail)
                      ).length -
                        1
                        ? "last-menu-items"
                        : ""
                    }`}
                    name={subCat?.name ? "sub_id_" + subCat?.id : "null"}
                  >
                    <h3 className="title"> {subCat?.name}</h3>
                    {subCat?.description && subCat?.description !== "" && (
                      <p className="px-4 bold mb-0 pb-3 desSec">
                        {subCat?.description ?? ""}
                      </p>
                    )}

                    {menuItem.length > 0 &&
                      menuItem
                        .filter(
                          (item) =>
                            filterItemTableDetails(subCat, item) &&
                            checkItemAvailable(
                              item,
                              table_detail?.Merchant?.timezone_offset ??
                                "00:00",
                              table_detail
                            )
                        )
                        .map(function (item, itemKey) {
                          return getItemDiv(item, itemKey);
                        })}
                  </Element>
                );
              })}
              {/* </InfiniteScroll> */}
            </div>
          </section>
          <section
            className={`bottom-sec ${
              cart_detail?.length <= 0 ? "disabled" : ""
            }`}
          >
            <div
              onClick={() => {
                // history.push("/cart")
                if (cart_detail?.length > 0) {
                  toggleCartPopUp();
                }
              }}
              className="green-sec"
            >
              <div className="col-3">{getBasketTotalQuantity()}</div>
              <div className="col-5 text-center">
                <strong> View Basket </strong>
              </div>
              <div className="col-4 px-0">
                <div className="total-price">
                  <strong> {getBasketTotalAmount()}</strong> Total{" "}
                </div>
              </div>
            </div>
          </section>
          {/* add show class  */}
          <div className={`filter_modal ${showFilterCon ? "show" : ""}`}>
            <div className="modal_header">
              <div className="filter_icon">
                <ImageTag src={filterImg} />
                {countActiveFilter()}
              </div>
              <h4>Filters</h4>
              <div className="close">
                <ImageTag
                  src={close_dark}
                  onClick={() => {
                    setApplyFilters(filters);
                    setShowFilterCon(false);
                  }}
                />
              </div>
            </div>
            <div className="modal_body">
              <h5 className="title">By Tags</h5>
              {getFilterModalTags()}
              <button
                className="apply_btn"
                disabled={filters === applyFilters}
                onClick={() => {
                  setShowFilterCon(false);
                  getTableDetailByFilter(id, applyFilters, applySearch);
                }}
              >
                Apply
              </button>
            </div>
          </div>
          {/* account modal */}
          {showCartPage === false && (
            <AccountPopup
              show={showAccountPopUp}
              onCancel={() => setShowAccountPopUp(0)}
            />
          )}
          {/* {showCartPage === false && (
            <XgatePointsPopup
              show={xgate_points_popup}
              onCancel={() => toggleXgatePointsPopup(false)}
            />
          )} */}
          <Modal
            show={show_table_already_inuse_modal}
            title="There is another device currently ordering for this table, do you wish to create a separate order in addition to that device’s order?"
            onCancel={() => {
              // emptyTableDetail();
              // changeTableNotFound();
              emptyUserDetail();
            }}
            OnSubmit={() => emptyChangeTableAlreadyInuse()}
            cancelBtnText="No"
            submitBtnText="Yes"
          />
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(showCartPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return (
                <Cart
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowCartPage={toggleCartPopUp}
                />
              );
            }}
          </Motion>
        </div>
      );
    } else if (loading === false) {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <PleaseWait />
        </div>
      );
    }
  }

  return <>{getHomePage()}</>;
}

const mapStateToProps = (state) => ({
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  table_detail: state.tableDetailReducer.table_detail,
  items_86_detail: state.tableDetailReducer.items_86_detail,
  clear_cache: state.tableDetailReducer.clear_cache,
  show_table_already_inuse_modal:
    state.tableDetailReducer.show_table_already_inuse_modal,
  show_table_not_found_modal:
    state.tableDetailReducer.show_table_not_found_modal,
  filter_tag_detail: state.tableDetailReducer.filter_tag_detail,
  show_item_unavailable_modal:
    state.tableDetailReducer.show_item_unavailable_modal,
  show_item_unavailable_text:
    state.tableDetailReducer.show_item_unavailable_text,
  filters: state.tableDetailReducer.filters,
  search_input: state.tableDetailReducer.search_input,
  search_text: state.tableDetailReducer.search_text,
  cart_detail: state.cartDetailReducer.cart_detail,
  show_complete_page: state.cartDetailReducer.show_complete_page,
  reorder_item_ids: state.cartDetailReducer.reorder_item_ids,
  show_cart_popup: state.cartDetailReducer.show_cart_popup,
  user_detail: state.authDetailReducer.user_detail,
  card_detail: state.authDetailReducer.card_detail,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  show_session_expired_page: state.tableDetailReducer.show_session_expired_page,
  xgate_points_popup: state.authDetailReducer.xgate_points_popup,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  getTableDetail: getTableDetail,
  emptyTableDetail: emptyTableDetail,
  getTableDetailByFilter: getTableDetailByFilter,
  getSelectedItemDetail: getSelectedItemDetail,
  emptyEditedItemDetail: emptyEditedItemDetail,
  updateSearchText: updateSearchText,
  emptyChangeTableAlreadyInuse: emptyChangeTableAlreadyInuse,
  changeTableNotFound: changeTableNotFound,
  updateRatingDetail: updateRatingDetail,
  checkOpenOrder: checkOpenOrder,
  emptyUserDetail: emptyUserDetail,
  dismissItemAvailableModal: dismissItemAvailableModal,
  toggleXgatePointsPopup: toggleXgatePointsPopup,
  changeRedirectHome: changeRedirectHome,
};

Home = connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;
