import { put, takeLatest, select } from "redux-saga/effects";
import {
  GET_TABLE_DETAIL,
  TABLE_DETAIL_RECEIVED,
  LOADING_STATUS,
  FILTER_TAG_DETAIL_RECEIVED,
  ADD_FILTER_TAG,
  ADD_FILTER_TAG_RECEIVED,
  ADD_SEARCH_TAG_RECEIVED,
  ADD_SEARCH_TEXT,
  GET_SELECTED_ITEM_DETAIL,
  SELECTED_ITEM_DETAIL_RECEIVED,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
  SEND_RATING_INPUT,
  RATING_INPUT_RECEIVED,
  CHECK_ACTIVE_ORDER,
  RECENT_ORDER_ID_RECEIVED,
  CART_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  CHANGE_ORDER_TIP,
  CHANGE_SESSION_EXPIRED_PAGE,
} from "../actions/types";
import {
  changePosCartDetail,
  changeSessionExpiredPage,
  clearCacheStatus,
  emptyPosCartDetail,
  emptyUserDetail,
  item86DetailRecieved,
  showItemAvailableModal,
  showNotificationWithTimeout,
} from "../actions";
import {
  checkArray,
  checkNull,
  getCartSubmittedItems,
  getDeviceId,
  getPosCartSubmittedItems,
  pluckArray,
} from "../../helpers";

const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
const defaultErrorMsg = "Something went wrong! try again later.";

function* updateRating(action) {
  console.log("base_url", base_url);
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;
  const json = yield fetch(`${base_url}/qr_code_apis/qr_rating`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action?.payload ?? {}),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("updateRating failed : " + error.message));
  if (json?.status) {
    yield put({ type: RATING_INPUT_RECEIVED, payload: action.star });

    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : "success"}`,
        "success"
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* checkActiveOrder(action) {
  console.log("base_url", base_url);
  const cart_detail = yield select(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  const recent_order_id = yield select(
    (state) => state?.cartDetailReducer?.recent_order_id
  );
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  let items = cart_detail.filter((p) => p.is_added == false);
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const deviceId = yield getDeviceId();
  myHeaders.token = token;
  const json = yield fetch(`${base_url}/qr_code_apis/get_order_details`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({
      deviceid: deviceId ?? "",
      merchant_id: action.merchantId ?? "",
      table_number: action.tableNumber ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("checkActiveOrder failed : " + error.message)
    );
  if (json?.status) {
    if (json?.data?.open_orders !== null) {
      const orders = json?.data?.open_orders;
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      if (checkNull(recent_order_id, false)) {
        if (
          checkNull(orders?.order_offline_id, false) === false ||
          (orders?.order_offline_id &&
            recent_order_id != orders?.order_offline_id)
        ) {
          console.log("Sessionnnn Expiredddd");
          yield put(changeSessionExpiredPage(true));
        }
      }
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders?.order_offline_id ?? "",
      });
      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders?.tips ?? 0,
      });
      // console.log("ordersssss===>", orders);
      const orderItems = getCartSubmittedItems(orders, table_detail);
      // console.log("ordersssss===>items", items);
      // debugger;
      if (orderItems?.length > 0) {
        items = [...items, ...orderItems];
      }
      const posItems = getPosCartSubmittedItems(orders, table_detail);
      if (posItems?.length > 0) {
        yield put(changePosCartDetail(posItems));
      } else {
        yield put(emptyPosCartDetail());
      }
    }
    if (checkArray(json?.data?.["86_items"])) {
      const items_86 = json?.data?.["86_items"];
      yield put(item86DetailRecieved(items_86));
      // check already 86 items
      const items86Ids = pluckArray(items_86, "id");
      const already86ItemsName = [];
      const already86ItemsId = [];
      cart_detail
        .filter((p) => p.is_added == false)
        .map((cartItem) => {
          if (items86Ids.includes(cartItem.id)) {
            already86ItemsId.push(cartItem.id);
            already86ItemsName.push(cartItem.pos_name);
          }
        });
      if (checkArray(already86ItemsName)) {
        yield put(
          showItemAvailableModal(
            `${already86ItemsName.join(", ")} ${
              already86ItemsName.length > 1 ? "are" : "is"
            } no longer available, removed from your basket.`
          )
        );
      }
      if (checkArray(already86ItemsId)) {
        items = items.filter(
          (p) => p.is_added == true || !already86ItemsId.includes(p.id)
        );
      }
      // check already 86 items END
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `checkActiveOrder error : ${
        json?.message ? json.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
  yield put({ type: LOADING_STATUS, status: false });
}

function* fetchTableDetail(action) {
  localStorage.clear();
  yield put({ type: LOADING_STATUS, status: true });
  if (action?.resetAuth == true) {
    yield put(emptyUserDetail());
  }
  const deviceId = yield getDeviceId();
  const json = yield fetch(
    `${base_url}/qr_code_apis/get_table_details/${action.id}`,
    {
      method: "POST",
      mode: "cors",
      headers: myHeaders,
      body: JSON.stringify({
        device_id: deviceId ?? "",
      }),
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("fetchTableDetail failed : " + error.message)
    );
  const tagsJson = yield fetch(`${base_url}/qr_code_apis/get_tags`, {
    mode: "cors",
    headers: myHeaders,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("get_tagsDetail failed : " + error.message));
  if (json?.status && json?.data) {
    sessionStorage.setItem("isTableDataLoaded", JSON.stringify(true));
    sessionStorage.setItem(
      "servicePeriodExpire",
      JSON.stringify(json?.data?.service_period?.date)
    );
    yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
    if (json?.data?.occupied == 1) {
      yield put({ type: CHANGE_TABLE_ALREADY_INUSE_MODAL, payload: true });
    }
    if (tagsJson?.status && tagsJson?.data)
      yield put({ type: FILTER_TAG_DETAIL_RECEIVED, json: tagsJson?.data });
  } else {
    if (json?.message !== undefined) {
      yield put({ type: CHANGE_TABLE_NOT_FOUND_MODAL, payload: true });
    }
    console.log(
      `fetchTableDetail error : ${
        json?.message ? json.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* fetchTableDetailByfilter(action) {
  yield put({ type: ADD_FILTER_TAG_RECEIVED, payload: action.ids });
  yield put({ type: ADD_SEARCH_TAG_RECEIVED, input: action.input });
  yield put({ type: ADD_SEARCH_TEXT, input: action.input });
  // NOT IN USE
  // yield put({ type: LOADING_STATUS, status: true });
  // const json = yield fetch(
  //   `${base_url}/qr_code_apis/get_table_details/${action.table_id}?tag_ids=${action.ids}&search=${action.input}`,
  //   {
  //     mode: "cors",
  //     headers: myHeaders,
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((json) => json)
  //   .catch((error) =>
  //     console.log("fetchTableDetail failed : " + error.message)
  //   );
  // yield put({ type: LOADING_STATUS, status: false });
  // if (json?.status && json?.data) {
  //   yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
  //   yield put({ type: ADD_FILTER_TAG_RECEIVED, payload: action.ids });
  //   yield put({ type: ADD_SEARCH_TAG_RECEIVED, input: action.input });
  // } else {
  //   console.log(
  //     `fetchTableDetail error : ${
  //       json?.message ? json.message : defaultErrorMsg
  //     }`
  //   );
  // }
}

function* fetchSelectedItemDetail(action) {
  yield put({ type: SELECTED_ITEM_DETAIL_RECEIVED, payload: action.id });
}

export default function* tableDetailActionWatcher() {
  yield takeLatest(GET_TABLE_DETAIL, fetchTableDetail);
  yield takeLatest(ADD_FILTER_TAG, fetchTableDetailByfilter);
  yield takeLatest(GET_SELECTED_ITEM_DETAIL, fetchSelectedItemDetail);
  yield takeLatest(SEND_RATING_INPUT, updateRating);
  yield takeLatest(CHECK_ACTIVE_ORDER, checkActiveOrder);
}
