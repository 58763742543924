import { put, takeLatest, select, call } from "redux-saga/effects";
import { encode } from "base-64";
import axios from "axios";
import {
  SEND_OTP_DETAIL,
  SEND_OTP_DETAIL_RECEIVED,
  EMPTY_OTP_DETAIL,
  EMPTY_CARD_DETAIL,
  LOADING_STATUS,
  GET_USER_DETAIL,
  USER_DETAIL_RECEIVED,
  OTP_INPUT_RECEIVED,
  SHOW_OTP_INPUT_TOGGLE,
  GET_LOYALITY_DETAIL,
  CARD_DETAIL_RECEIVED,
  CART_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  RECENT_ORDER_ID_RECEIVED,
  SEND_PHONE_DETAIL,
  PHONE_PIN_INPUT_RECEIVED,
  SHOW_PHONE_PIN_INPUT_TOGGLE,
  PHONE_AGREEMENT_DETAIL_RECEIVED,
  AVOCADO_DETAIL_RECEIVED,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_REORDER_ORDER_IDS,
  REORDER_ORDER_IDS_RECEIVED,
  CHANGE_ORDER_TIP,
  GET_XGATE_CUSTOMER,
  XGATE_DETAILS_RECEIVED,
  GET_XGATE_COUPON,
  XGATE_COUPON_RECEIVED,
  XGATE_POINTS_RECEIVED,
  GET_XGATE_POINTS,
} from "../actions/types";
import {
  showNotificationWithTimeout,
  getUserDetail,
  togglePhoneAgreementInput,
  updatePhoneAgreementInput,
  changePosCartDetail,
  emptyPosCartDetail,
  emptyXgateDetails,
  getXgateCustomer,
  emptyXgateCoupon,
  getXgateCoupon,
  emptyXgatePoints,
  changeRedirectHome,
} from "../actions";
import {
  apiCall,
  avocadoAuthToken,
  checkNull,
  getCartSubmittedItems,
  getDeviceId,
  getPosCartSubmittedItems,
  getReOrderItemIds,
  orderHistoryApi,
} from "../../helpers";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;

console.log(" process?.env", process?.env);
// Advocado API
const ad_api_url = process?.env?.REACT_APP_AVOCADO_API_URL;
const ad_username = process?.env?.REACT_APP_AVOCADO_API_USERNAME;
const ad_password = process?.env?.REACT_APP_AVOCADO_API_PASSWORD;

let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
function* sendOtpSms(action) {
  console.log("base_url", base_url);
  // TODO
  // const authReducer = yield select((state) => state.authDetailReducer);
  // console.log("authReducer in saga", authReducer);
  const table_detail = yield select(
    (state) => state?.tableDetailReducer?.table_detail
  );
  const loyaltyPackage =
    table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage ?? null;
  yield put({ type: EMPTY_OTP_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const json = yield fetch(`${base_url}/qr_code_apis/verifyOtp`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      email: action?.email ?? "",
      otp: action?.otp ?? "",
      merchant_id: action?.merchantId ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("sendOtpSms failed : " + error.message));
  if (json?.status) {
    if (action?.otp !== undefined && action?.otp !== "") {
      yield put(
        getUserDetail({
          email: action?.email ?? "",
          merchantId: action?.merchantId ?? "",
          deviceId: action?.deviceId ?? "",
          tableNumber: action?.tableNumber ?? "",
        })
      );
      // if (loyaltyPackage.code == "xgate")
      //   yield put(
      //     getXgateCustomer(
      //       {
      //         email: action?.email ?? "",
      //         merchantId: action?.merchantId,
      //         tableNumber: action?.tableNumber,
      //       },
      //       false
      //     )
      //   );
    } else {
      yield put(
        showNotificationWithTimeout(
          `${json?.message ? json.message : "success"}`,
          "success"
        )
      );
      yield put({ type: SHOW_OTP_INPUT_TOGGLE, input: true });
      yield put({ type: OTP_INPUT_RECEIVED, input: "" });
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `sendOtpSms error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* loginUser(action) {
  console.log("base_url", base_url);
  yield put({ type: EMPTY_OTP_DETAIL });
  yield put({ type: EMPTY_REORDER_ORDER_IDS });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: true });
  const deviceId = yield getDeviceId();
  const json = yield fetch(`${base_url}/qr_code_apis/login`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      merchant_id: action?.merchantId ?? "",
      email: action?.email ?? "",
      deviceid: deviceId ?? "",
      table_number: action?.tableNumber ?? "",
      phone_detail: action?.phoneDetail ?? null,
      code: action?.code ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("loginUser failed : " + error.message));
  if (json?.status && json?.data) {
    const token = json?.data?.token;
    const customerId = json?.data?.id;
    if (json?.data?.open_orders !== null) {
      const orders = json?.data?.open_orders;
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders?.order_offline_id ?? "",
      });
      // console.log("ordersssss===>", orders);
      const items = getCartSubmittedItems(orders, table_detail);
      // console.log("ordersssss===>items", items);
      // debugger;
      if (items?.length > 0) {
        yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
      }
      const posItems = getPosCartSubmittedItems(orders, table_detail);
      if (posItems?.length > 0) {
        yield put(changePosCartDetail(posItems));
      } else {
        yield put(emptyPosCartDetail());
      }
      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders?.tips ?? 0,
      });
    }
    yield put({ type: USER_DETAIL_RECEIVED, payload: json?.data });
    yield put(changeRedirectHome(true));
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : "success"}`,
        "success"
      )
    );
    /* Order history API */
    const orderHistoryData = yield orderHistoryApi({
      deviceId: deviceId,
      token: token,
      merchantId: action?.merchantId ?? "",
      customerId: customerId ?? "",
    });
    if (orderHistoryData?.status && orderHistoryData?.data) {
      yield put({
        type: ORDER_HISTORY_DETAIL_RECEIVED,
        payload: orderHistoryData?.data,
      });
      const reOrderIds = getReOrderItemIds(orderHistoryData?.data);
      if (reOrderIds.length > 0) {
        yield put({ type: REORDER_ORDER_IDS_RECEIVED, payload: reOrderIds });
      }
    } else {
      yield put(
        showNotificationWithTimeout(
          `${
            orderHistoryData?.message
              ? orderHistoryData.message
              : defaultErrorMsg
          }`,
          "error"
        )
      );
      console.log(
        `ORDER_HISTORY error : ${
          orderHistoryData?.message ? orderHistoryData.message : defaultErrorMsg
        }`
      );
    }
    /* Order history API END */
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `loginUser error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

// async function avocadoAuthToken() {
//   myHeaders.set(
//     "Authorization",
//     "Basic " + encode(ad_username + ":" + ad_password)
//   );
//   const json = await fetch(`${ad_api_url}/oauth2/tokenCredential`, {
//     method: "POST",
//     mode: "cors",
//     headers: myHeaders,
//     body: JSON.stringify({
//       grant_type: "client_credentials",
//     }),
//   })
//     .then((response) => response.json())
//     .then((json) => json)
//     .catch((error) => console.log("authToken failed : " + error.message));
//   return json;
// }

async function avocadoShowAggreement(token) {
  myHeaders.set("Authorization", "Bearer " + token);
  const json = await fetch(`${ad_api_url}/v1.1/merchants/pdpa`, {
    mode: "cors",
    headers: myHeaders,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

function* sendPhoneDetail(action) {
  let phoneDetail = null;
  yield put({ type: LOADING_STATUS, status: true });
  const authToken = yield avocadoAuthToken();
  console.log("authTokenDetail:", authToken);
  if (authToken?.access_token?.value) {
    myHeaders.set("Authorization", "Bearer " + authToken?.access_token?.value);

    if (action?.phone_pin_input !== "") {
      const phonePinDetail = yield fetch(
        `${ad_api_url}/v1.1/customers/verify-pin`,
        {
          method: "POST",
          mode: "cors",
          headers: myHeaders,
          body: JSON.stringify({
            countryCallingCode: action?.phone_code,
            phoneNumber: action?.phone,
            pin: action?.phone_pin_input,
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) => console.log("authToken failed : " + error.message));
      console.log("phonePinDetail:", phonePinDetail);
      if (
        phonePinDetail?.code !== 200 ||
        (phonePinDetail?.code == 200 && phonePinDetail?.data == false)
      ) {
        yield put(
          showNotificationWithTimeout(`Invalid pin provided!`, "error")
        );
        yield put({ type: LOADING_STATUS, status: false });
        return;
      }
    }

    if (action?.phone_agreement_input === true) {
      yield put(updatePhoneAgreementInput(true));
      phoneDetail = yield fetch(`${ad_api_url}/v1.1/customers/pdpa`, {
        method: "POST",
        mode: "cors",
        headers: myHeaders,
        body: JSON.stringify({
          countryCallingCode: action?.phone_code,
          phoneNumber: action?.phone,
          pdpa: 1,
        }),
      })
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) => console.log("authToken failed : " + error.message));
      console.log("phonePdpaDetail:", phoneDetail);
      if (
        phoneDetail?.code !== 200 ||
        (phoneDetail?.code == 200 && phoneDetail?.status !== "success")
      ) {
        yield put(showNotificationWithTimeout(`${defaultErrorMsg}`, "error"));
        yield put({ type: LOADING_STATUS, status: false });
        return;
      }
    } else {
      phoneDetail = yield fetch(
        `${ad_api_url}/v1.1/customers/phone-number/${action?.phone_code}/${action?.phone}`,
        {
          mode: "cors",
          headers: myHeaders,
        }
      )
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) => console.log("authToken failed : " + error.message));
      console.log("phoneDetail:", phoneDetail);
    }

    if (phoneDetail?.code == 200) {
      if (phoneDetail?.data?.pdpa == "0") {
        const authAgreement = yield avocadoShowAggreement(
          authToken?.access_token?.value
        );
        if (authAgreement?.code == 200 && authAgreement?.data?.pdpaContent) {
          yield put({
            type: PHONE_AGREEMENT_DETAIL_RECEIVED,
            input: authAgreement?.data?.pdpaContent ?? "",
          });
          yield put(togglePhoneAgreementInput(true));
        } else {
          yield put(
            showNotificationWithTimeout(
              `Unable to fetch agreement. Try again later!`,
              "error"
            )
          );
        }
      } else {
        yield put({
          type: AVOCADO_DETAIL_RECEIVED,
          payload: phoneDetail?.data ?? null,
        });
        yield put(
          getUserDetail({
            email: phoneDetail?.data?.email ?? "",
            merchantId: action?.merchantId ?? "",
            deviceId: action?.deviceId ?? "",
            tableNumber: action?.tableNumber ?? "",
            phoneDetail: phoneDetail?.data,
          })
        );
      }
    } else if (phoneDetail?.code == 501) {
      yield put({ type: SHOW_PHONE_PIN_INPUT_TOGGLE, input: true });
      yield put({ type: PHONE_PIN_INPUT_RECEIVED, input: "" });
      yield put(showNotificationWithTimeout(`Enter login pin!`, "success"));
    } else {
      yield put(
        showNotificationWithTimeout(`Invalid phone number provided!`, "error")
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

// XGATE
const xgateHeaders = new Headers({
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
});
function* getXgateCustomerDetail(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyXgateDetails());
  yield put({ type: LOADING_STATUS, status: true });
  const details = {
    merchant_id: action?.payload?.merchantId ?? "",
    device_id: action?.payload?.deviceId ?? "",
  };
  if (checkNull(action?.payload?.email, false)) {
    details.email = action?.payload?.email;
  }
  if (checkNull(action?.payload?.mobile, false)) {
    details.mobile = action?.payload?.mobile;
  }
  if (checkNull(action?.payload?.card, false)) {
    details.card_number = action?.payload?.card;
  }
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const customerDetails = yield fetch(
    `${base_url}/xgate_loyalty_apis/get_customer`,
    {
      method: "POST",
      mode: "cors",
      headers: xgateHeaders,
      body: formBody,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("sendOtpSms failed : " + error.message));

  // create customer if not find
  // if (
  //   checkNull(action?.payload?.mobile, false) &&
  //   customerDetails?.data?.status &&
  //   customerDetails?.data?.status != "success"
  // ) {
  //   const createCustomer = yield fetch(
  //     `${base_url}/xgate_loyalty_apis/customer_create`,
  //     {
  //       method: "POST",
  //       mode: "cors",
  //       headers: xgateHeaders,
  //       body: formBody,
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((json) => json)
  //     .catch((error) =>
  //       console.log("createCustomer failed : " + error.message)
  //     );
  //   if (createCustomer?.data?.status == "success") {
  //     yield put(getXgateCustomer(action?.payload));
  //   }
  // }

  if (customerDetails?.data?.status == "success") {
    yield put({ type: XGATE_DETAILS_RECEIVED, payload: customerDetails?.data });
    yield put(
      getXgateCoupon({
        merchantId: action?.payload?.merchantId ?? "",
        code: customerDetails?.data?.membership?.code ?? "",
      })
    );
    if (action?.loginUser) {
      yield put(
        getUserDetail({
          email: customerDetails?.data?.email ?? "",
          merchantId: action?.payload?.merchantId ?? "",
          deviceId: action?.payload?.deviceId ?? "",
          tableNumber: action?.payload?.tableNumber ?? "",
          phoneDetail: customerDetails?.data?.mobile ?? null,
          code: customerDetails?.data?.membership?.code ?? "",
        })
      );
    }
  } else {
    const isMobile = checkNull(action?.payload?.mobile, false);
    const isCard = checkNull(action?.payload?.card, false);
    let errMsg = ` There is no member account with the provided ${
      isMobile && !isCard ? "mobile number" : ""
    }${!isMobile && isCard ? "loyalty member id" : ""}${
      isMobile && isCard ? "mobile number and loyalty member id" : ""
    }. Please try again`;
    if (action?.loginUser == false) {
      errMsg = `There is no loyalty member account with the provided email. Please setup`;
    }
    yield put(showNotificationWithTimeout(`${errMsg}`, "error"));
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* getXgateCouponDetail(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyXgateCoupon());
  // yield put({ type: LOADING_STATUS, status: true });
  const details = {
    merchant_id: action?.payload?.merchantId ?? "",
    code: action?.payload?.code ?? "",
  };
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const couponDetail = yield fetch(
    `${base_url}/xgate_loyalty_apis/get_coupon_codes`,
    {
      method: "POST",
      mode: "cors",
      headers: xgateHeaders,
      body: formBody,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("get_coupon_codes failed : " + error.message)
    );

  if (couponDetail?.data?.status == "success") {
    yield put({ type: XGATE_COUPON_RECEIVED, payload: couponDetail?.data });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          couponDetail?.data?.message
            ? couponDetail?.data?.message
            : defaultErrorMsg
        }`,
        "error"
      )
    );
  }
  // yield put({ type: LOADING_STATUS, status: false });
}

function* getXgatePointsDetail(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyXgatePoints());
  yield put({ type: LOADING_STATUS, status: true });
  const details = {
    ...action.payload,
  };
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const pointsDetail = yield fetch(
    `${base_url}/xgate_loyalty_apis/redeemable_cash_preview`,
    {
      method: "POST",
      mode: "cors",
      headers: xgateHeaders,
      body: formBody,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("redeemable_cash_preview failed : " + error.message)
    );

  if (pointsDetail?.data?.status == "success") {
    yield put({ type: XGATE_POINTS_RECEIVED, payload: pointsDetail?.data });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          pointsDetail?.data?.message
            ? pointsDetail?.data?.message
            : defaultErrorMsg
        }`,
        "error"
      )
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

// loyality API

const loyality_api_url =
  "https://uatcrm.ascentis.com.sg/APIsWrapperIdentity/connect/token";
const loyality_instance_url =
  "https://uatcrm.ascentis.com.sg/APIsWrapperGateway/apiv1";
const loyality_grant_type = "client_credentials";
const loyality_scope = "crmapi";
const loyality_client_id = "HarrysBar_POS-GoGMGo\\testuser";
const loyality_client_secret = "qUDVNPN3MgFp3u7R";
let loyalityHeaders = new Headers({
  // "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
});

function* loyalityCardCheck(action) {
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_CARD_DETAIL });
  const deviceId = yield getDeviceId();
  const cardDetails = yield fetch(`${base_url}/ascentis/card_enquiry`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      CardNo: action?.card ?? "",
      CVC: "",
      // CVC: action?.cvv ?? "",
      merchant_id: action?.merchantId ?? "",
      deviceid: deviceId ?? "",
      table_number: action?.tableNumber ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("cardDetails failed : " + error.message));

  console.log("cardDetails", cardDetails);

  if (cardDetails?.status && cardDetails?.data) {
    console.log("cardDetails===>", cardDetails);
    if (cardDetails?.data?.orders !== null) {
      const orders = cardDetails?.data?.orders;
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders?.order_offline_id ?? "",
      });
      // console.log("ordersssss===>", orders);
      const items = getCartSubmittedItems(orders, table_detail);
      // console.log("ordersssss===>items", items);
      // debugger;
      if (items?.length > 0) {
        yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
      }
      const posItems = getPosCartSubmittedItems(orders, table_detail);
      if (posItems?.length > 0) {
        yield put(changePosCartDetail(posItems));
      } else {
        yield put(emptyPosCartDetail());
      }
      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders?.tips ?? 0,
      });
    }
    let userDetails = cardDetails?.data;
    userDetails.card_cvc = action?.cvv ?? "";
    yield put({ type: USER_DETAIL_RECEIVED, payload: userDetails });
    yield put(
      showNotificationWithTimeout(
        `${cardDetails?.message ? cardDetails.message : "success"}`,
        "success"
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${cardDetails?.message ? cardDetails.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `cardDetails error : ${
        cardDetails?.message ? cardDetails.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

export default function* authDetailActionWatcher() {
  yield takeLatest(SEND_OTP_DETAIL, sendOtpSms);
  yield takeLatest(SEND_PHONE_DETAIL, sendPhoneDetail);
  yield takeLatest(GET_USER_DETAIL, loginUser);
  yield takeLatest(GET_LOYALITY_DETAIL, loyalityCardCheck);
  yield takeLatest(GET_XGATE_CUSTOMER, getXgateCustomerDetail);
  yield takeLatest(GET_XGATE_COUPON, getXgateCouponDetail);
  yield takeLatest(GET_XGATE_POINTS, getXgatePointsDetail);
}
