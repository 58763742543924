import React from "react";
import { userIcon } from "../../Assets";

const ImageTag = (props) => {
  const src = props.src !== undefined ? props.src : "";
  const defaultimage =
    props.defaultimage !== undefined ? props.defaultimage : userIcon;
  return (
    <img
      onError={(ev) => {
        if (props?.removeImage === true) {
          const textEl = ev.target?.parentElement?.parentElement;
          if (
            props?.removeCol === true &&
            props?.replaceColName !== "" &&
            props?.removeColName !== "" &&
            textEl &&
            textEl.querySelector(".menu-content")
          ) {
            textEl
              .querySelector(".menu-content")
              .classList.remove(props?.removeColName);
            textEl
              .querySelector(".menu-content")
              .classList.add(props?.replaceColName);
          }
          ev.target.parentElement.remove();
        } else {
          ev.target.src = defaultimage;
        }
      }}
      src={src}
      {...props}
    />
  );
};

export default ImageTag;
