import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import PDFViewer from "pdf-viewer-reactjs";
import DraggableItem from "../Components/DraggableItem";
import {} from "../../Assets";
import Header from "../Components/Header";
import CartHeader from "../Components/CartHeader";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import AccountPopup from "../Components/AccountPopup";
import {
  showNotificationWithTimeout,
  changeStatusCartDetail,
  saveCartTaxDetail,
  orderPlaceDetail,
  removeItemCartDetail,
  getSelectedItemDetail,
  changeTipDetail,
  dismissItemAvailableModal,
} from "../../Redux/actions";
import {
  checkArray,
  checkItemAvailable,
  checkNull,
  checkSubCatAvailable,
  countDecimals,
  getCartBody,
  getCartNewItemCount,
  parseRound,
  parseTwoDigitNumber,
  sortCartItems,
} from "../../helpers";
import {
  APPLY_VOUCHER_DETAIL_RECEIVED,
  TOGGLE_CART_PAGE,
} from "../../Redux/actions/types";
import PromocodePopup from "../Components/PromocodePopup";
import Modal from "../Components/Modal";
import TipPopup from "../Components/TipPopup";
import { Terms } from "../Components/Terms";

function Cart(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const { show_item_unavailable_modal, show_item_unavailable_text } =
    useSelector((state) => state.tableDetailReducer);
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const pos_cart_detail = useSelector(
    (state) => state.cartDetailReducer?.pos_cart_detail
  );
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const show_cart_popup = useSelector(
    (state) => state.cartDetailReducer?.show_cart_popup
  );
  const { xgate_details: xgateDetails, xgate_coupons: xgateCoupons } =
    useSelector((state) => state?.authDetailReducer);
  // console.log("cart_detail", JSON.stringify(cart_detail));
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const recent_order_id = useSelector(
    (state) => state.cartDetailReducer?.recent_order_id
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer?.user_detail
  );
  const showPostTaxPrice =
  table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
    ? true
    : false;
  const total_tip = useSelector((state) => state.cartDetailReducer?.total_tip);
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total ?? 0);
        }, 0)
      : 0;
  let defaultDiscountTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const serviceChargePer = parseFloat(table_detail?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;

  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const [showPromocodePopup, setShowPromocodePopup] = useState(0);
  const [showTipPopup, setShowTipPopup] = useState(0);
  const [accountTabType, setAccountTabType] = useState(0);
  const [isCartChanged, setIsCartChanged] = useState(false);
  const [showSubmittedItem, setShowSubmittedItem] = useState(false);
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount - 0
  );
  const [totalServiceCharge, setTotalServiceCharge] =
    useState(defaultServiceCharge);
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [totalTip, setTotalTip] = useState(total_tip);
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  useEffect(() => {
    if (show_cart_popup) {
      dispatch({ type: TOGGLE_CART_PAGE, payload: false });
    }
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    let defaultPromoDiscount = 0;
    // promotion discount
    if (
      cart_detail &&
      checkArray(
        cart_detail.filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
      )
    ) {
      cart_detail
        .filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
        .map((item) => {
          defaultPromoDiscount =
            defaultPromoDiscount +
            parseFloat(item?.promotions?.discountPerItem ?? 0) *
              parseInt(item?.quantity ?? 0);
        });
    }
    // promotion discount END
    let defaultTotalDiscount = 0;
    //applied_voucher
    if (applied_voucher && applied_voucher.length > 0) {
      const newApplyVoucher = applied_voucher.map((applyVoucher) => {
        if (applyVoucher?.Type == "Cash") {
          const discountAmt = parseFloat(applyVoucher?.TypeValue ?? 0);
          applyVoucher.discountAmt = discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        } else if (applyVoucher?.Type == "Discount") {
          const discountAmt =
            (parseFloat(applyVoucher?.TypeValue ?? 0) *
              defaultDiscountTotalCartAmount) /
            100;
          applyVoucher.discountAmt = discountAmt;
          defaultDiscountTotalCartAmount =
            defaultDiscountTotalCartAmount - discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        }
        return applyVoucher;
      });
      dispatch({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload: newApplyVoucher,
      });
    }
    if (applied_promocode && applied_promocode !== null) {
      defaultTotalDiscount = defaultTotalDiscount + getPromotionTotalDis();
    }
    // applied_voucher END
    setTotalDiscount(defaultTotalDiscount);
    setPromoDiscount(defaultPromoDiscount);
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [showAccountPopUp, showPromocodePopup, cart_detail]);

  function removeItemHandler(index) {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      dispatch(removeItemCartDetail(itemIndx));
    }
    setIsCartChanged(true);
  }

  function getCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return sortCartItems(cart_detail)
        .filter((p) =>
          showSubmittedItem ? p.is_added == true : p.is_added == false
        )
        .map(function (item, itemIndex) {
          return (
            <DraggableItem
              item={item}
              itemIndex={item.offline_id}
              defaultimage={table_detail?.Merchant?.logo}
              removeItemHandler={removeItemHandler}
              key={item.offline_id}
            />
          );
        });
    }
    return null;
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) =>
          checkSubCatAvailable(subCat, table_detail) &&
          subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter(
            (item) =>
              checkItemAvailable(
                item,
                table_detail?.Merchant?.timezone_offset ?? "00:00",
                table_detail
              ) && item.is_popular == 1
          ).map(function (item, itemKey) {
            res.push(item);
          });
        }
      });
    }
    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              dispatch(getSelectedItemDetail(item));
              dispatch({ type: TOGGLE_CART_PAGE, payload: true });
              if (item?.menu_item_type == "bundled")
                history.push("/bundle-add-item");
              else history.push("/add-item");
            }}
          >
            <figure className="menu-img mx-auto mb-3">
              <ImageTag
                src={item?.image}
                defaultimage={table_detail?.Merchant?.logo}
                alt=""
              />
            </figure>
            <h5 className="sm-h5"> {item?.menu_item_name ?? ""} </h5>
            <p>
              {" "}
              @
              {showPostTaxPrice? item?.dinein_price?.DineInPostTax !== undefined
                ? parseRound(item?.dinein_price?.DineInPostTax)
                : "0.00" :item?.dinein_price?.DineIn !== undefined
                ? parseRound(item?.dinein_price?.DineIn)
                : "0.00"}
            </p>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  const getPromotionTotalDis = () => {
    let res = getBasketTotalAmount(true);
    let promotionDiscount = 0;
    if (applied_promocode && applied_promocode !== null) {
      if (applied_promocode.type == "Amount") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Percentage") {
        promotionDiscount = (res * parseFloat(applied_promocode.amount)) / 100;
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Re-Price") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = promotionDiscount;
      }
    }
    return res;
  };

  function getBasketTotalAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }

    if (onlyTotal) {
      return res;
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {parseRound(res)}
      </>
    );
  }

  const tipChangeHandler = (newTipVal) => {
    setTotalTip(newTipVal);
    if (recent_order_id !== "") {
      orderPlaceHandler(true, newTipVal);
    }
    setShowTipPopup(0);
  };

  function getBasketTotalQuantity() {
    let res = getCartNewItemCount(cart_detail);
    return <div className="items-count"> {res} </div>;
  }

  function orderPlaceHandler(onlyTip = false, newTip = totalTip) {
    let body = getCartBody({
      recent_order_id,
      cart_detail,
      pos_cart_detail,
      user_detail,
      table_detail,
      totalTip: newTip,
      applied_voucher,
      totalDiscount,
      applied_promocode,
      xgateDetails,
      onlyTip,
    });
    dispatch(orderPlaceDetail(body, onlyTip));
    if (onlyTip == false) {
      history.push("/new-items");
    }
  }

  function getCartDetail() {
    // console.log("defaultTotalCartAmount", defaultTotalCartAmount);
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className="wrapper cart_wrapper custom_pb cart_sidebar"
          onScroll={scrolledElement}
          {...handlers}
          style={props.style}
        >
          <Motion
            defaultStyle={{ x: 270 }}
            style={{
              x: spring(
                hideHeader === false
                  ? cart_detail.findIndex((p) => p.is_added == true) > -1
                    ? 165
                    : 130
                  : 0,
                {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }
              ),
            }}
          >
            {({ x }) => (
              <CartHeader
                backTitle={"Back to Order"}
                onTabProfile={() => setShowAccountPopUp(1)}
                backPath={() => {
                  props.setShowCartPage();
                  // setHideHeader(true);
                }}
                style={{
                  height: `${x}px`,
                }}
                rtComponent={
                  <>
                    <div className="hotel_name">
                      <div className="circle-img">
                        <figure className="">
                          <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                        </figure>
                      </div>
                      <div className="naming-sec text-center">
                        <h4> {table_detail?.Merchant?.account_name} </h4>
                        <p>
                          {" "}
                          Table #{
                            table_detail?.PosTableMaster?.table_number
                          }{" "}
                          Basket{" "}
                        </p>
                      </div>
                      {cart_detail.findIndex((p) => p.is_added == true) >
                        -1 && (
                        <div className="col-12 pt-5 py-3 px-0">
                          <h5
                            className={`green-title ${
                              cart_detail.findIndex((p) => p.is_added == true) >
                              -1
                                ? ""
                                : "grey"
                            }`}
                            onClick={() => {
                              if (
                                cart_detail.findIndex(
                                  (p) => p.is_added == true
                                ) > -1
                              )
                                setShowSubmittedItem(!showSubmittedItem);
                            }}
                          >
                            {showSubmittedItem
                              ? "View Items In Basket"
                              : "View Previously Submitted Items"}
                          </h5>
                        </div>
                      )}
                    </div>
                  </>
                }
              />
            )}
          </Motion>
          <Motion
            defaultStyle={{ x: 270 }}
            style={{
              x: spring(hideHeader === false ? 0 : 60, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <CartHeader
                backTitle={"Back to Order"}
                onTabProfile={() => setShowAccountPopUp(1)}
                backPath={() => {
                  props.setShowCartPage();
                  // setHideHeader(true);
                }}
                style={{
                  height: `${x}px`,
                  position: "fixed",
                  top: "0px",
                  width: "100%",
                  zIndex: 99,
                  right: `${props.style.right}`,
                  backgroundColor: "#f5f6f7",
                }}
                rtComponent={<></>}
              />
            )}
          </Motion>
          {/* 
                    <header>
                        <div className="container">
                            <div className="row mx-0">
                                <div className="col-8">
                                    <a href="index.html" className="back"> Back to Order </a>
                                </div>
                                <div className="col-4 notify">
                                    <a href="#_" className="notification">
                                        <span className="counts"> 10 </span>
                                    </a>
                                    <a href="#_" className="profile"> </a>
                                </div>
                            </div>
        
        
        
                            <div className="row hotel_name">
                                <div className="circle-img col-2">
                                    <figure className=""><img src={userImgIcon} alt="" /></figure>
                                </div>
                                <div className="naming-sec col-6 px-0 text-center">
                                    <h4> Harry's Boat Quay </h4>
                                    <p> Table #17 Basket </p>
                                </div>
                            </div>
                            <div className="col-12 py-3">
                                <h5 className="green-title"> View Submitted Items </h5>
                            </div>
                        </div>
                <div className="items-count"> 2 </div>
                    </header> */}

          <div className={`outerScroll ${hideHeader ? "scrollup" : ""}`}>
            <section className="middle-sec cart-sec-ms new_items_page recommended">
              <h3 class="title">
                {" "}
                {showSubmittedItem
                  ? "Previously Submitted Items"
                  : "Items in Basket"}{" "}
              </h3>
              {getCartItems()}
            </section>

            {table_detail?.SubCategory &&
              table_detail.SubCategory.findIndex(
                (subCat) =>
                  subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
              ) > -1 && (
                <section className="recommended">
                  <h3 className="title mt-3"> You Might Also Like </h3>
                  <div className="row also-like-sec">{getPopularItems()}</div>
                </section>
              )}

            <section className="billing-details mb-5">
              <ul className="billing-list">
                <li>
                  {" "}
                  <span> Sub Total </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`} */}
                    {parseRound(
                      totalCartAmount + totalDiscount + promoDiscount
                    )}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Discounts </span>{" "}
                  <strong>
                    {" "}
                    (<small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {`${parseFloat(totalDiscount).toFixed(3).slice(0, -1)}`}) */}
                    {`${parseRound(totalDiscount + promoDiscount)}`})
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Service Charges </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalServiceCharge)}
                  </strong>
                </li>

                <li>
                  {" "}
                  <span> Tax </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {/* {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)} */}
                    {parseRound(totalSaleTax)}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Gratuity </span>{" "}
                  <input
                    type="text"
                    className="type-text"
                    value={`$${parseTwoDigitNumber(totalTip)}`}
                    onClick={() => {
                      setShowTipPopup(1);
                    }}
                    readOnly={true}
                  />
                </li>
                <li className="total">
                  {" "}
                  <span> Total </span>{" "}
                  <strong> {getBasketTotalAmount()}</strong>
                </li>
              </ul>
              <div className="row mx-0 justify-content-between px-4">
                <div
                  // onClick={() => {
                  //   setShowPromocodePopup(1);
                  // }}
                  className="link"
                >
                  {" "}
                  Use Promo Code{" "}
                </div>
                {loyaltyEnabled && (
                  <div
                    onClick={() => {
                      setAccountTabType(1);
                      setShowAccountPopUp(1);
                    }}
                    className="link"
                  >
                    {" "}
                    Use Voucher{" "}
                  </div>
                )}
              </div>
            </section>
          </div>

          <section
            className={`bottom-sec ${
              cart_detail?.filter((p) => p?.is_added == false)?.length <= 0
                ? "disabled"
                : ""
            }`}
            style={props.style}
          >
            <div
              onClick={() => {
                if (
                  cart_detail?.filter((p) => p?.is_added == false)?.length > 0
                ) {
                  orderPlaceHandler();
                }
              }}
              className="green-sec py-3"
            >
              <div className="col-2">{getBasketTotalQuantity()}</div>
              <div className="col-8 text-center">
                <strong> Submit New Items </strong>
              </div>
              <div className="col-2"></div>
            </div>
            <div>
              {/* <div className="col-12">
                <p className="my-1 text-center">
                  By clicking <b>'Submit New Items'</b>. I agree and accept to
                  GoGMGo{" "}
                  <b onClick={() => setShowTermsPopup(true)}>Terms of Uses</b>{" "}
                  and{" "}
                  <b
                    onClick={() =>
                      window.open(
                        "https://www.gogmgo.com/privacy-policy/",
                        "_newtab"
                      )
                    }
                  >
                    Privacy Policy
                  </b>
                </p>
              </div> */}
            </div>
          </section>
          {/* account modal */}

          {/* {(showAccountPopUp === 0 || showAccountPopUp === 1) && ( */}
          <AccountPopup
            show={showAccountPopUp}
            tabType={accountTabType}
            onCancel={() => {
              setShowAccountPopUp(0);
              setAccountTabType(0);
            }}
          />
          {/* )} */}
          <PromocodePopup
            show={showPromocodePopup}
            onCancel={() => setShowPromocodePopup(0)}
          />
          <TipPopup
            show={showTipPopup}
            totalTip={totalTip}
            onCancel={() => setShowTipPopup(0)}
            tipChangeHandler={tipChangeHandler}
          />
          <Modal
            show={show_item_unavailable_modal}
            title={show_item_unavailable_text}
            OnSubmit={() => dispatch(dismissItemAvailableModal())}
            submitBtnText="ok"
          />
          <Modal
            show={showTermsPopup}
            title={""}
            extraClass={"top50"}
            modalbody={
              <Terms />
            }
            OnSubmit={() => setShowTermsPopup(false)}
            submitBtnText="ok"
          />
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default Cart;
