import React, { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
  checkItemAvailable,
  deepCopy,
  checkArray,
  pluckArray,
  checkNull,
} from "../../helpers";
import { image2Icon, userImgIcon } from "../../Assets";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  emptyEditedItemDetail,
  getSelectedItemDetail,
} from "../../Redux/actions";

function LoyalityAccount(params) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetail = useSelector(
    (state) => state?.authDetailReducer?.user_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer.table_detail
  );
  const { xgate_details: xgateDetails, xgate_coupons: xgateCoupons } =
    useSelector((state) => state?.authDetailReducer);
  const avocadoDetail = useSelector(
    (state) => state?.authDetailReducer?.avocado_detail
  );
  const avocadoMemberList = useSelector(
    (state) => state?.authDetailReducer?.avocado_member_list
  );
  const reorder_item_ids = useSelector(
    (state) => state.cartDetailReducer.reorder_item_ids
  );
  const items_86_detail = useSelector(
    (state) => state.tableDetailReducer.items_86_detail
  );

  const items86Arr = checkArray(items_86_detail)
    ? pluckArray(items_86_detail, "id")
    : [];

  const [reorderArr, setReorderArr] = useState([]);

  // reorder array
  const makeReorderItems = (reorder) => {
    let copyReorderArr = [];

    if (reorder_item_ids?.length > 0) {
      reorder_item_ids.map((itemKey, promoItemIndex) => {
        const promoSubKey = table_detail?.SubCategory?.findIndex(
          (p) => p.MenuItem.findIndex((q) => q.id == itemKey?.menu_item_id) > -1
        );
        const promoItemKey = table_detail?.SubCategory?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.menu_item_id);
        if (
          promoSubKey > -1 &&
          table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey] &&
          checkItemAvailable(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey],
            table_detail?.Merchant?.timezone_offset ?? "00:00",
            table_detail
          )
        ) {
          const promoItem = deepCopy(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          promoItem.recomItem = itemKey;
          copyReorderArr.push(promoItem);
        }
      });
    }
    if (copyReorderArr.length > 0)
      setReorderArr([...reorderArr, ...copyReorderArr]);
  };

  useEffect(() => {
    makeReorderItems();
  }, []);

  const getActiveVoucher = () => {
    let res = 0;
    if (xgateCoupons?.general?.["ISSUED"]) {
      res = xgateCoupons?.general?.["ISSUED"];
    } else if (avocadoDetail?.campaignBalances) {
      res = avocadoDetail?.campaignBalances?.length;
    } else if (userDetail?.TotalActiveVoucherCount) {
      res = parseInt(userDetail?.TotalActiveVoucherCount);
    }
    return res;
  };

  const getPointBalance = () => {
    let res = 0;
    if (xgateDetails?.membership?.point_balance?.[0]?.balance) {
      res = parseFloat(xgateDetails?.membership?.point_balance?.[0]?.balance);
    } else if (
      avocadoDetail?.campaignBalances &&
      avocadoDetail?.campaignBalances?.filter(
        (p) => p?.campaignType == "loyalty"
      ).length > 0
    ) {
      res = avocadoDetail?.campaignBalances
        ?.filter((p) => p?.campaignType == "loyalty")
        .reduce(function (sum, current) {
          return sum + parseFloat(current.points);
        }, 0);
    } else if (userDetail?.TotalPointsBAL) {
      res = userDetail?.TotalPointsBAL;
    }
    return nFormatter(res);
  };

  const getPointUsed = () => {
    let res = (
      <>
        <p> Points Used </p>
        <h4>N/A</h4>
      </>
    );
    if (xgateDetails?.membership?.tlv) {
      res = (
        <>
          <p> Total Spends </p>
          <h4>
            {`${table_detail?.currency ?? ""}`}
            {nFormatter(parseFloat(xgateDetails?.membership?.tlv))}
          </h4>
        </>
      );
    }
    if (
      avocadoDetail?.campaignBalances &&
      avocadoDetail?.campaignBalances?.filter(
        (p) => p?.campaignType == "loyalty"
      ).length > 0
    ) {
      let usedVal = avocadoDetail?.campaignBalances
        ?.filter((p) => p?.campaignType == "loyalty")
        .reduce(function (sum, current) {
          return sum + parseFloat(current.points);
        }, 0);
      res = (
        <>
          <p> Points Used </p>
          <h4>{nFormatter(parseFloat(usedVal))}</h4>
        </>
      );
    } else if (userDetail?.TotalPointsBAL) {
      res = (
        <>
          <p> Points Used </p>
          <h4>{nFormatter(parseFloat(userDetail?.TotalPointsBAL))}</h4>
        </>
      );
    }
    return res;
  };

  const getTotalVisits = () => {
    let res = "N/A";
    if (avocadoDetail !== null) {
      res = "N/A";
    } else if (userDetail?.TotalVisits) {
      res = userDetail?.TotalVisits;
    }
    return res;
  };

  const getMembershipTier = () => {
    let res = checkNull(xgateDetails, false) ? "MEMBER" : "N/A";
    if (xgateDetails?.membership?.tiername) {
      res = xgateDetails?.membership?.tiername;
    } else if (
      avocadoDetail?.membership &&
      avocadoDetail?.membership?.length > 0
    ) {
      res = avocadoDetail?.membership?.[0]?.membershipTagName;
    } else if (userDetail?.TierCode) {
      res = userDetail?.TierCode;
    }
    return res;
  };

  const getMemberSince = () => {
    let res = (
      <>
        <label> Member Since </label>
        <p>N/A</p>
      </>
    );
    if (xgateDetails?.membership?.begindate) {
      res = (
        <>
          <label> Member Since </label>
          <p>
            {moment(xgateDetails?.membership?.begindate).format("MMMM D, YYYY")}
          </p>
        </>
      );
    } else if (
      avocadoDetail?.membership &&
      avocadoDetail?.membership?.length > 0
    ) {
      res = (
        <>
          <label> Expire At </label>
          <p>
            {moment(avocadoDetail?.membership?.[0]?.expiredDate).format(
              "MMMM D, YYYY"
            )}
          </p>
        </>
      );
    } else if (userDetail?.IssueDate) {
      res = (
        <>
          <label> Member Since </label>
          <p>{getUnixDateFormat(userDetail?.IssueDate)}</p>
        </>
      );
    }
    return res;
  };

  const getMemberStatus = () => {
    let res = "N/A";
    if (xgateDetails?.membership?.memberstatus) {
      res = xgateDetails?.membership?.memberstatus;
    } else if (
      avocadoDetail?.membership &&
      avocadoDetail?.membership?.length > 0
    ) {
      res = "Active";
    } else if (userDetail?.MembershipStatusCode) {
      res = capitalizeFirstLetter(userDetail?.MembershipStatusCode);
    }
    return res;
  };

  const getLastVisitDate = () => {
    let res = "N/A";
    if (avocadoDetail !== null) {
      res = "N/A";
    } else if (userDetail?.LastVisitedDate) {
      res = getUnixDateFormat(userDetail?.LastVisitedDate);
    }
    return res;
  };

  const getRegularStore = () => {
    let res = "N/A";
    if (xgateDetails?.membership?.issuestore) {
      res = xgateDetails?.membership?.issuestore;
    } else if (avocadoDetail !== null) {
      res = "N/A";
    } else if (userDetail?.MostCycleVisitedOutletCode) {
      res = userDetail?.MostCycleVisitedOutletCode;
    }
    return res;
  };

  const getBirthDate = () => {
    let res = "N/A";
    if (xgateDetails?.birthday) {
      res = moment(xgateDetails?.birthday).format("MMMM D, YYYY");
    } else if (avocadoDetail?.dateOfBirth !== "") {
      res = moment(avocadoDetail?.dateOfBirth).format("MMMM D, YYYY");
    } else if (userDetail?.date_of_birth) {
      res = getUnixDateFormat(userDetail?.date_of_birth);
    }
    return res;
  };

  return (
    <div>
      <section className="info-sec">
        <div className="account-info">
          <div className="acc-info-con row mx-0">
            <h4 className="title col-12"> Account Info </h4>
            {avocadoDetail !== null && avocadoMemberList.length > 0 && (
              <div className="col-6">
                <button className="btn btn-sm float-right mt-2">UPGRADE</button>
              </div>
            )}
          </div>

          {avocadoDetail !== null && avocadoMemberList.length > 0 && (
            <div className="info d-flex align-items-end flex-wrap">
              {avocadoMemberList.map((memberShip, memInx) => {
                return (
                  <div
                    className={`col-3 my-2 ${
                      memInx % 3 !== 0 || memInx == 0 ? "border-right" : ""
                    }`}
                  >
                    <button className="btn btn-sm btn-primary">
                      {memberShip?.membershipTierName ?? ""}
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          <div className="info d-flex align-items-end">
            <div className="col-3 border-right my-2">
              <p> Active Vouchers </p>
              <h4>{getActiveVoucher()}</h4>
            </div>
            <div className="col-3 border-right my-2">
              <p> Points Balance </p>
              <h4>{getPointBalance()}</h4>
            </div>
            <div className="col-3 border-right my-2">{getPointUsed()}</div>
            <div className="col-3 my-2 visits">
              <p> # of Visits </p>
              <h4> {getTotalVisits()} </h4>
            </div>
          </div>

          <div className="info-details row mx-0">
            <div className="col-6">
              <label> Member Tier </label>
              <div className="tag green">{getMembershipTier()}</div>
            </div>
            <div className="col-6">{getMemberSince()}</div>
            <div className="col-6">
              <label> Status </label>
              <p>{getMemberStatus()}</p>
            </div>
            <div className="col-6">
              <label> Last Visit </label>
              <p> {getLastVisitDate()}</p>
            </div>
            <div className="col-6">
              <label> Regular Store </label>
              <p>{getRegularStore()}</p>
            </div>
            <div className="col-6">
              <label> Birth Date </label>
              <p>{getBirthDate()}</p>
            </div>
          </div>
        </div>
      </section>
      {checkArray(reorderArr) && (
        <>
          <section className="fav-sec">
            <div className="fav-info">
              <h4 className="title"> Favorites </h4>
            </div>
            {reorderArr.map((reorderItem, reorderIdx) => {
              console.log("reorderItem", reorderItem);
              const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(
                reorderItem?.image
              );
              return (
                <div
                  className="menu-box row"
                  key={reorderIdx}
                  onClick={() => {
                    const checkPeriodAvailable =
                      reorderItem?.menu_item_type == "bundled"
                        ? true
                        : checkItemAvailable(
                            reorderItem,
                            table_detail?.Merchant?.timezone_offset ?? "00:00",
                            table_detail
                          );
                    const checkAvailable =
                      reorderItem?.is_86_item == "false" &&
                      !items86Arr.includes(reorderItem?.id)
                        ? true
                        : false;
                    if (checkAvailable && checkPeriodAvailable) {
                      dispatch(getSelectedItemDetail(reorderItem));
                      dispatch(emptyEditedItemDetail());
                      if (reorderItem?.menu_item_type == "bundled")
                        history.push("/bundle-add-item");
                      else history.push("/add-item");
                    }
                  }}
                >
                  {checkValidImg && (
                    <figure className="col-3 menu-img py-2">
                      <img src={reorderItem?.image} />
                    </figure>
                  )}

                  <div className={`${checkValidImg?"col-9":"col"} menu-content pl-2`}>
                    <h4>{reorderItem?.menu_item_name}</h4>
                    <p>{reorderItem?.description}</p>
                    <p className="pricing">
                      {" "}
                      <small>{`${table_detail?.currency ?? ""}`}</small>
                      {`${reorderItem?.dinein_price?.DineIn}`}
                    </p>
                  </div>
                  {/* <div className="col-3 px-0">
                <a href="#_" className="square-img">
                  <h5> 4 </h5>
                  <p> Promos </p>
                </a>
              </div> */}
                </div>
              );
            })}
          </section>
        </>
      )}
    </div>
  );
}
export default LoyalityAccount;
