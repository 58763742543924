export const LOADING_STATUS = "LOADING_STATUS";
// USER_DETAIL
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const EMPTY_USER_DETAIL = "EMPTY_USER_DETAIL";
export const USER_DETAIL_RECEIVED = "USER_DETAIL_RECEIVED";
export const EMAIL_INPUT_RECEIVED = "EMAIL_INPUT_RECEIVED";
export const OTP_INPUT_RECEIVED = "OTP_INPUT_RECEIVED";
export const SHOW_OTP_INPUT_TOGGLE = "SHOW_OTP_INPUT_TOGGLE";
export const SHOW_PHONE_PIN_INPUT_TOGGLE = "SHOW_PHONE_PIN_INPUT_TOGGLE";
export const SHOW_PHONE_AGREEMENT_INPUT_TOGGLE =
  "SHOW_PHONE_AGREEMENT_INPUT_TOGGLE";
export const PHONE_AGREEMENT_DETAIL_RECEIVED =
  "PHONE_AGREEMENT_DETAIL_RECEIVED";
export const PHONE_AGREEMENT_INPUT_RECEIVED = "PHONE_AGREEMENT_INPUT_RECEIVED";
export const PHONE_PIN_INPUT_RECEIVED = "PHONE_PIN_INPUT_RECEIVED";
export const PHONE_INPUT_RECEIVED = "PHONE_INPUT_RECEIVED";
export const SEND_PHONE_DETAIL = "SEND_PHONE_DETAIL";
export const PHONE_CODE_INPUT_RECEIVED = "PHONE_CODE_INPUT_RECEIVED";
export const SEND_RATING_INPUT = "SEND_RATING_INPUT";
export const CHECK_ACTIVE_ORDER = "CHECK_ACTIVE_ORDER";
export const RATING_INPUT_RECEIVED = "RATING_INPUT_RECEIVED";
export const XGATE_INPUT_RECEIVED = "XGATE_INPUT_RECEIVED";
export const XGATE_INPUT_EMPTY = "XGATE_INPUT_EMPTY";
export const GET_XGATE_CUSTOMER = "GET_XGATE_CUSTOMER";
export const XGATE_DETAILS_RECEIVED = "XGATE_DETAILS_RECEIVED";
export const XGATE_DETAILS_EMPTY = "XGATE_DETAILS_EMPTY";
export const GET_XGATE_COUPON = "GET_XGATE_COUPON";
export const XGATE_COUPON_EMPTY = "XGATE_COUPON_EMPTY";
export const XGATE_COUPON_RECEIVED = "XGATE_COUPON_RECEIVED";
export const GET_XGATE_POINTS = "GET_XGATE_POINTS";
export const XGATE_POINTS_EMPTY = "XGATE_POINTS_EMPTY";
export const XGATE_POINTS_RECEIVED = "XGATE_POINTS_RECEIVED";
export const TOGGLE_XGATE_POINTS_POPUP = "TOGGLE_XGATE_POINTS_POPUP";
// PDF_MENU
export const GET_PDF_MENU = "GET_PDF_MENU";
export const EMPTY_PDF_MENU = "EMPTY_PDF_MENU";
export const PDF_MENU_RECEIVED = "PDF_MENU_RECEIVED";
// TOAST_MSG
export const EMPTY_TOAST_MSG = "EMPTY_TOAST_MSG";
export const TOAST_MSG = "TOAST_MSG";
export const HIDE_TOAST_MSG = "HIDE_TOAST_MSG";
export const SHOW_NOTIFICATION_WITH_TIMEOUT = "SHOW_NOTIFICATION_WITH_TIMEOUT";
// TABLE DETAIL
export const GET_TABLE_DETAIL = "GET_TABLE_DETAIL";
export const TABLE_DETAIL_RECEIVED = "TABLE_DETAIL_RECEIVED";
export const EMPTY_TABLE_DETAIL = "EMPTY_TABLE_DETAIL";
export const FILTER_TAG_DETAIL_RECEIVED = "FILTER_TAG_DETAIL_RECEIVED";
export const ADD_FILTER_TAG = "ADD_FILTER_TAG";
export const ADD_FILTER_TAG_RECEIVED = "ADD_FILTER_TAG_RECEIVED";
export const ADD_SEARCH_TAG_RECEIVED = "ADD_SEARCH_TAG_RECEIVED";
export const ADD_SEARCH_TEXT = "ADD_SEARCH_TEXT";
export const GET_SELECTED_ITEM_DETAIL = "GET_SELECTED_ITEM_DETAIL";
export const SELECTED_ITEM_DETAIL_RECEIVED = "SELECTED_ITEM_DETAIL_RECEIVED";
export const CHANGE_TABLE_NOT_FOUND_MODAL = "CHANGE_TABLE_NOT_FOUND_MODAL";
export const CHANGE_TABLE_ALREADY_INUSE_MODAL =
  "CHANGE_TABLE_ALREADY_INUSE_MODAL";
export const EDITED_ITEM_DETAIL_RECEIVED = "EDITED_ITEM_DETAIL_RECEIVED";
export const EMPTY_EDITED_ITEM_DETAIL = "EMPTY_EDITED_ITEM_DETAIL";
export const CHANGE_CLEAR_CACHE_STATUS = "CHANGE_CLEAR_CACHE_STATUS";
export const CHANGE_REDIRECT_HOME_PAGE = "CHANGE_REDIRECT_HOME_PAGE";
export const CHANGE_SESSION_EXPIRED_PAGE = "CHANGE_SESSION_EXPIRED_PAGE";
export const ITEM_86_DETAIL_RECIEVED = "ITEM_86_DETAIL_RECIEVED";
export const EMPTY_ITEM_86_DETAIL = "EMPTY_ITEM_86_DETAIL";
// OTP DETAIL
export const SEND_OTP_DETAIL = "SEND_OTP_DETAIL";
export const SEND_OTP_DETAIL_RECEIVED = "SEND_OTP_DETAIL_RECEIVED";
export const EMPTY_OTP_DETAIL = "EMPTY_OTP_DETAIL";
// Cart DETAIL
export const ADD_ITEM_CART_DETAIL = "ADD_ITEM_CART_DETAIL";
export const UPDATE_ITEM_CART_DETAIL = "UPDATE_ITEM_CART_DETAIL";
export const REMOVE_ITEM_CART_DETAIL = "REMOVE_ITEM_CART_DETAIL";
export const CART_DETAIL_RECEIVED = "CART_DETAIL_RECEIVED";
export const EMPTY_CART_DETAIL = "EMPTY_CART_DETAIL";
export const POS_CART_DETAIL_RECEIVED = "POS_CART_DETAIL_RECEIVED";
export const EMPTY_POS_CART_DETAIL = "EMPTY_POS_CART_DETAIL";
export const TOGGLE_CART_PAGE = "TOGGLE_CART_PAGE";
export const CART_TAX_DETAIL_RECEIVED = "CART_TAX_DETAIL_RECEIVED";
export const EMPTY_CART_TAX_DETAIL = "EMPTY_CART_TAX_DETAIL";
export const CART_DETAIL_STATUS_CHANGED = "CART_DETAIL_STATUS_CHANGED";
export const CHANGE_ORDER_SOCKET_EMIT = "CHANGE_ORDER_SOCKET_EMIT";
export const DELETE_RECENT_AND_CART_ORDER = "DELETE_RECENT_AND_CART_ORDER";
export const ACTIVE_ITEM_UNAVALIABLE_MODAL = "ACTIVE_ITEM_UNAVALIABLE_MODAL";
export const INACTIVE_ITEM_UNAVALIABLE_MODAL =
  "INACTIVE_ITEM_UNAVALIABLE_MODAL";
export const SHOW_COMPLETE_PAGE_TOGGLE = "SHOW_COMPLETE_PAGE_TOGGLE";
// Order DETAIL
export const ORDER_PLACE_DETAIL = "ORDER_PLACE_DETAIL";
export const ORDER_PLACE_DETAIL_RECEIVED = "ORDER_PLACE_DETAIL_RECEIVED";
export const EMPTY_ORDER_PLACE_DETAIL = "EMPTY_ORDER_PLACE_DETAIL";
export const POS_SOCKET_ORDER_RECIEVE = "POS_SOCKET_ORDER_RECIEVE";
// Loyality
export const GET_LOYALITY_DETAIL = "GET_LOYALITY_DETAIL";
export const CARD_DETAIL_RECEIVED = "CARD_DETAIL_RECEIVED";
export const EMPTY_CARD_DETAIL = "EMPTY_CARD_DETAIL";
export const MEMBER_DETAIL_RECEIVED = "MEMBER_DETAIL_RECEIVED";
export const EMPTY_MEMBER_DETAIL = "EMPTY_MEMBER_DETAIL";
// Avocado Loyality
export const AVOCADO_DETAIL_RECEIVED = "AVOCADO_DETAIL_RECEIVED";
export const AVOCADO_MEMBER_LIST_RECEIVED = "AVOCADO_MEMBER_LIST_RECEIVED";
// Order History
export const GET_ORDER_HISTORY_DETAIL = "GET_ORDER_HISTORY_DETAIL";
export const ORDER_HISTORY_DETAIL_RECEIVED = "ORDER_HISTORY_DETAIL_RECEIVED";
export const EMPTY_ORDER_HISTORY_DETAIL = "EMPTY_ORDER_HISTORY_DETAIL";
export const CHANGE_ORDER_TIP = "CHANGE_ORDER_TIP";
// ORDER ID
export const RECENT_ORDER_ID_RECEIVED = "RECENT_ORDER_ID_RECEIVED";
export const EMPTY_RECENT_ORDER_ID = "EMPTY_RECENT_ORDER_ID";
// REORDER IDS
export const REORDER_ORDER_IDS_RECEIVED = "REORDER_ORDER_IDS_RECEIVED";
export const EMPTY_REORDER_ORDER_IDS = "EMPTY_REORDER_ORDER_IDS";
// Voucher List
export const GET_VOUCHER_LIST_DETAIL = "GET_VOUCHER_LIST_DETAIL";
export const VOUCHER_LIST_DETAIL_RECEIVED = "VOUCHER_LIST_DETAIL_RECEIVED";
export const EMPTY_VOUCHER_LIST_DETAIL = "EMPTY_VOUCHER_LIST_DETAIL";
// Apply Voucher
export const ADD_DETAIL_CARD_TOKEN = "ADD_DETAIL_CARD_TOKEN";
export const GET_APPLY_VOUCHER_DETAIL = "GET_APPLY_VOUCHER_DETAIL";
export const REMOVE_ITEM_VOUCHER_DETAIL = "REMOVE_ITEM_VOUCHER_DETAIL";
export const APPLY_VOUCHER_DETAIL_RECEIVED = "APPLY_VOUCHER_DETAIL_RECEIVED";
export const EMPTY_APPLY_VOUCHER_DETAIL = "EMPTY_APPLY_VOUCHER_DETAIL";
export const ADD_PROMOCODE = "ADD_PROMOCODE";
export const EMPTY_PROMOCODE = "EMPTY_PROMOCODE";
