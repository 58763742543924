import React, { useRef } from "react";
import { useState } from "react";
import Collapsible from "react-collapsible";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getApplyVoucherDetail,
  removeItemVoucherDetail,
} from "../../Redux/actions";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
} from "../../helpers";
import { voucherPngIcon } from "../../Assets";

function PromotionDetails({
  applied_voucher,
  voucher,
  activeTab,
  isValid,
  merchantId,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const voucherKey =
    applied_voucher.length > 0
      ? applied_voucher.findIndex((p) => p.VoucherNo == voucher?.VoucherNo)
      : -1;
  function getVoucherType(show = false) {
    let res = "$";
    if (voucher?.Type == "Discount") {
      res = "%";
    } else if (voucher?.Type == "VTSKU") {
      res = `${show ? "OFF" : ""}`;
    }
    return res;
  }

  function getVoucherName() {
    let res = "";
    if (voucher?.Type == "Discount") {
      res = "Discount";
    } else if (voucher?.Type == "Cash") {
      res = "Cash";
    }
    return res;
  }

  function getVoucherValue() {
    let res = "OFF";
    if (voucher?.Type == "Discount") {
      res = parseFloat(voucher?.TypeValue);
    } else if (voucher?.Type == "Cash") {
      res = parseFloat(voucher?.TypeValue);
    }
    return res;
  }

  return (
    <div className="promotion_details">
      <div className="row mx-0 justify-content-start">
        <div className="squre-img col-4 mx-0 px-0">
          <figure className="">
            <img src={voucherPngIcon} alt="" />
          </figure>
        </div>
        <div className="naming-sec col-8 pr-0">
          <h4>
            {" "}
            {voucher?.VoucherTypeName != ""
              ? voucher?.VoucherTypeName
              : "N/A"}{" "}
          </h4>
          <p>
            <em>
              {" "}
              Expiry Date:{" "}
              {voucher?.ValidTo
                ? getUnixDateFormat(voucher?.ValidTo, "DD/MM/YYYY")
                : "N/A"}{" "}
            </em>
          </p>
          <div className="btns d-flex pt-3">
            <button className="more-btn" onClick={() => setOpen(!open)}>
              {" "}
              More{" "}
            </button>
            {activeTab === 0 && (
              <button
                className={`green-btn btn-sm ${
                  voucherKey > -1 || !isValid ? "disabled" : ""
                }`}
                onClick={() => {
                  if (!isValid) {
                    return;
                  } else if (voucherKey > -1) {
                    dispatch(removeItemVoucherDetail(voucherKey));
                  } else {
                    dispatch(getApplyVoucherDetail(voucher, merchantId));
                  }
                }}
              >
                {`${voucherKey > -1 ? "Applied" : "Apply"}`}
              </button>
            )}
          </div>
        </div>
      </div>
      <Collapsible open={open}>
        <div className="row">
          <div className="col-4 pr-0">
            <label> Promotion Code </label>
            <p> {voucher?.VoucherNo != "" ? voucher?.VoucherNo : "N/A"} </p>
          </div>
          <div className="col-4 pr-0">
            <label> Promotion Type </label>
            <p> {`${getVoucherType(true)} ${getVoucherName()}`}</p>
          </div>
          <div className="col-4 pr-0">
            <label> Amount </label>
            <p>
              {`${getVoucherType()}`}
              {getVoucherValue()}
            </p>
          </div>
          <div className="col-4 pr-0">
            <label> Qualifying Items </label>
            <p> Order </p>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

export default PromotionDetails;
