import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import {
  cameraIcon,
  imagePlaceholderIcon,
  qrCodeIcon,
  userImgIcon,
} from "../../Assets";
import QrInput from "../Components/QR_code/QrInput";
import QrCamera from "../Components/QR_code/QrCamera";
import ImageTag from "../Components/ImageTag";
import { Motion, spring, presets } from "react-motion";
import {
  updateEmailInput,
  sendOtpDetail,
  getUserDetail,
  updateOtpInput,
  showNotificationWithTimeout,
  getLoyalityDetail,
  updatePhoneInput,
  sendPhoneDetail,
  updatePhoneCodeInput,
  updatePhonePinInput,
  togglePhonePinInput,
  updatePhoneAgreementInput,
  togglePhoneAgreementInput,
  changeXgateInput,
  getXgateCustomer,
} from "../../Redux/actions";
import CountryCode from "../../Assets/country_codes.json";
import { connect, useSelector, useDispatch } from "react-redux";
import Modal from "../Components/Modal";
import LoginLayout from "../Components/LoginLayout";
import LoginInput from "../Components/LoginInput";
import LoginAcentis from "./LoginAcentis";
import { Terms } from "../Components/Terms";

let Login = ({}) => {
  var uuid = "not-valid";
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const loyaltyEnabled =
    tableReducer?.table_detail?.Merchant?.loyalty_enabled ?? false;
  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;
  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  useEffect(() => {
    dispatch(updatePhonePinInput(""));
    dispatch(updatePhoneInput(""));
    dispatch(updatePhoneCodeInput(""));
    dispatch(togglePhonePinInput(false));
    dispatch(updatePhoneAgreementInput(false));
    dispatch(togglePhoneAgreementInput(false));
    document.body.classList.add("login");
    return () => {
      document.body.classList.remove("login");
    };
  }, []);

  const guestSubmitHandler = (el) => {
    dispatch(
      getUserDetail({
        email: "",
        merchantId: merchantId,
        deviceId: deviceId,
        tableNumber: tableNumber,
      })
    );
  };

  const [customerType, setCustomerType] = useState("");
  const backBtnHandler = () => {
    setCustomerType("");
  };

  useEffect(() => {
    if (
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
      tableReducer?.redirect_home_page
    ) {
      history.push(
        `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
  }, [tableReducer?.redirect_home_page]);

  const LoginNewCustomer = ({ style }) => {
    const authReducer = useSelector((state) => state.authDetailReducer);
    const email = authReducer.email_input;
    const otp = authReducer.otp_input;

    useEffect(() => {
      // redirect / if not have table detail
      if (
        loaderReducer?.loading === false &&
        tableReducer?.table_detail?.show_table_not_found_modal === false &&
        (tableReducer?.table_detail?.Merchant?.id === "" ||
          tableReducer?.table_detail?.Merchant?.id === null ||
          tableReducer?.table_detail?.Merchant?.id === undefined ||
          tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
          tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
          tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
      ) {
        history.push("/404");
      }
      // if already login send to menu page
      if (
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
        loaderReducer?.loading === false &&
        authReducer?.user_detail !== null &&
        authReducer?.user_detail !== undefined
      ) {
        history.push(
          `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
        );
      }
    }, []);

    const emailSubmitHandler = (el) => {
      el.preventDefault();
      dispatch(sendOtpDetail(email, otp, merchantId, deviceId, tableNumber));
    };

    const resendSubmitHandler = (el) => {
      dispatch(sendOtpDetail(email, "", merchantId, deviceId));
    };
    const emailChangeHandler = (el) => {
      dispatch(updateEmailInput(el.target.value));
    };

    const otpChangeHandler = (el) => {
      dispatch(updateOtpInput(el.target.value));
    };
    return (
      <LoginLayout
        style={style}
        divClass="cart_sidebar"
        backHandler={backBtnHandler}
        showBackBtn={customerType !== ""}
      >
        <div className="login-form pt-4 px_15">
          <form onSubmit={emailSubmitHandler}>
            <div className="group-sec">
              <div className="title-heading bold text-green">NEW CUSTOMER</div>
              <div className="green-box">
                For <strong>new customers,</strong> you may choose to provide
                your email to receive non-member future offers and promotions or
                simply continue as a guest
              </div>
            </div>
            <div className="title-heading bold">
              SUBMIT EMAIL FOR OTP VERIFICATION{" "}
            </div>
            <LoginInput
              placeholder="Email"
              value={authReducer?.email_input ?? ""}
              onChange={emailChangeHandler}
            />
            {authReducer?.show_otp_input === true && (
              <LoginInput
                placeholder="Enter OTP"
                value={authReducer?.otp_input ?? ""}
                onChange={otpChangeHandler}
              />
            )}
            <div className="resend_btn_row my-4">
              {authReducer?.show_otp_input === true && (
                <button
                  type="button"
                  className="btn green-btn new-green"
                  onClick={resendSubmitHandler}
                >
                  Resend
                </button>
              )}
              <button type="submit" className="btn green-btn new-green">
                Submit
              </button>
            </div>
            <div className="col-12">
              <p className="my-1 text-center">
                By clicking <b>'Submit'</b>, I agree and accept to
                GoGMGo's{" "}
                <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
                <b
                  onClick={() =>
                    window.open(
                      "https://www.gogmgo.com/privacy-policy/",
                      "_newtab"
                    )
                  }
                >
                  Privacy Policy
                </b>
              </p>
            </div>
          </form>
          <div className="or">
            {" "}
            <span> Or </span>{" "}
          </div>
          <div className="guest_login mt-3 mb-5">
            <a
              href="javascript:void(0)"
              className="btn green-btn new-green"
              onClick={guestSubmitHandler}
            >
              Continue as a guest
            </a>
          </div>
        </div>
      </LoginLayout>
    );
  };

  const LoginExistCustomer = ({ style }) => {
    const authReducer = useSelector((state) => state.authDetailReducer);
    const { xgate_inputs } = useSelector((state) => state.authDetailReducer);
    useEffect(() => {
      // redirect / if not have table detail
      if (
        loaderReducer?.loading === false &&
        tableReducer?.table_detail?.show_table_not_found_modal === false &&
        (tableReducer?.table_detail?.Merchant?.id === "" ||
          tableReducer?.table_detail?.Merchant?.id === null ||
          tableReducer?.table_detail?.Merchant?.id === undefined ||
          tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
          tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
          tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
      ) {
        history.push("/404");
      }
      // if already login send to menu page
      if (
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
        loaderReducer?.loading === false &&
        authReducer?.user_detail !== null &&
        authReducer?.user_detail !== undefined
      ) {
        history.push(
          `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
        );
      }
    }, []);
    const xgateSubmitHandler = (el) => {
      el.preventDefault();
      const xgateInputs = {
        ...xgate_inputs,
        merchantId: merchantId,
        tableNumber: tableNumber,
      };
      dispatch(getXgateCustomer(xgateInputs));
    };
    return (
      <LoginLayout
        style={style}
        divClass="cart_sidebar"
        backHandler={backBtnHandler}
        showBackBtn={customerType !== ""}
      >
        <div className="login-form pt-4 px_15">
          <form onSubmit={xgateSubmitHandler}>
            <div className="group-sec">
              <div className="title-heading bold text-green">
                {" "}
                EXISTING CUSTOMER{" "}
              </div>
              <div className="green-box">
                For <strong>existing customers,</strong> get your customer
                benefits by providing one of the info.
              </div>
            </div>

            <div className="title-heading bold">LOGIN BY MOBILE NUMBER </div>
            <LoginInput
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              step="1"
              className="input-text"
              placeholder="Mobile Number"
              value={xgate_inputs?.mobile ?? ""}
              onChange={(e) =>
                dispatch(changeXgateInput("mobile", e?.target?.value))
              }
            />
            <div className="or">
              {" "}
              <span> Or </span>{" "}
            </div>
            <div className="title-heading bold">LOGIN BY EMAIL</div>
            <LoginInput
              placeholder="Email"
              value={xgate_inputs?.email ?? ""}
              onChange={(e) =>
                dispatch(changeXgateInput("email", e?.target?.value))
              }
            />
            <div className="or">
              {" "}
              <span> Or </span>{" "}
            </div>
            <div className="title-heading bold">LOGIN BY LOYALTY MEMBER ID</div>
            <LoginInput
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              step="1"
              className="input-text"
              placeholder="Loyalty Member ID"
              value={xgate_inputs?.card ?? ""}
              onChange={(e) =>
                dispatch(changeXgateInput("card", e?.target?.value))
              }
            />

            <div className="resend_row my-4">
              <button type="submit" className="btn green-btn new-green">
                Submit
              </button>
            </div>
            <div className="col-12">
              <p className="my-1 text-center">
                By clicking <b>'Submit'</b>, I agree and accept to
                GoGMGo's{" "}
                <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
                <b
                  onClick={() =>
                    window.open(
                      "https://www.gogmgo.com/privacy-policy/",
                      "_newtab"
                    )
                  }
                >
                  Privacy Policy
                </b>
              </p>
            </div>
          </form>
          <div className="or">
            {" "}
            <span> Or </span>{" "}
          </div>
          <div className="guest_login mt-3 mb-5">
            <a
              href="javascript:void(0)"
              className="btn green-btn new-green"
              onClick={guestSubmitHandler}
            >
              Continue as a guest
            </a>
          </div>
        </div>
      </LoginLayout>
    );
  };

  return (
    <LoginLayout backHandler={backBtnHandler} showBackBtn={false}>
      <>
        <div className="login-form pt-4 px_15">
          {loyaltyEnabled == true ? (
            <>
              {loyaltyPackage?.code == "xgate" && (
                <>
                  <div className="group-sec">
                    <div className="title-heading bold text-green">
                      WELCOME TO{" "}
                      {tableReducer?.table_detail?.Merchant?.account_name}
                    </div>
                    <p>
                      {" "}
                      Are you a <strong>new customer</strong> or an{" "}
                      <strong>existing customer?</strong>{" "}
                    </p>
                  </div>

                  <div className="customers-selection">
                    <div className="group">
                      <input
                        type="radio"
                        id="newCustomer"
                        className=""
                        value=""
                        name="customer"
                        checked={customerType === "new"}
                        onClick={() => {
                          const setVal = customerType !== "new" ? "new" : "";
                          setCustomerType(setVal);
                        }}
                      ></input>
                      <label for="newCustomer">
                        {" "}
                        <span className="newCust"></span>{" "}
                        <h4> New Customer </h4>{" "}
                      </label>
                    </div>

                    <div className="group">
                      <input
                        type="radio"
                        id="existingCustomer"
                        className=""
                        value=""
                        name="customer"
                        checked={customerType === "exist"}
                        onClick={() => {
                          const setVal =
                            customerType !== "exist" ? "exist" : "";
                          setCustomerType(setVal);
                        }}
                      ></input>
                      <label for="existingCustomer">
                        {" "}
                        <span className="existCust"></span>{" "}
                        <h4> Existing Customer </h4>{" "}
                      </label>
                    </div>
                  </div>

                  {/* {customerType === "new" && (
                    <form onSubmit={emailSubmitHandler}>
                      <div className="group-sec">
                        <div className="title-heading bold text-green">
                          NEW CUSTOMER
                        </div>
                        <div className="green-box">
                          For <strong>new customers,</strong> you may choose to
                          provide your email to receive non-member future offers
                          and promotions or simply continue as a guest
                        </div>
                      </div>
                      <div className="title-heading bold">
                        SUBMIT EMAIL FOR OTP VERIFICATION{" "}
                      </div>
                      <LoginInput
                        placeholder="Email"
                        value={authReducer?.email_input ?? ""}
                        onChange={emailChangeHandler}
                      />
                      {authReducer?.show_otp_input === true && (
                        <LoginInput
                          placeholder="Enter OTP"
                          value={authReducer?.otp_input ?? ""}
                          onChange={otpChangeHandler}
                        />
                      )}
                      <div className="resend_btn_row my-4">
                        {authReducer?.show_otp_input === true && (
                          <button
                            type="button"
                            className="btn green-btn new-green"
                            onClick={resendSubmitHandler}
                          >
                            Resend
                          </button>
                        )}
                        <button
                          type="submit"
                          className="btn green-btn new-green"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  )} */}
                  {/* {customerType === "exist" && (
                    <>
                      <form onSubmit={xgateSubmitHandler}>
                        <div className="group-sec">
                          <div className="title-heading bold text-green">
                            {" "}
                            EXISTING CUSTOMER{" "}
                          </div>
                          <div className="green-box">
                            For <strong>existing customers,</strong> get your
                            customer benefits by providing one of the info.
                          </div>
                        </div>

                        <div className="title-heading bold">
                          LOGIN BY MOBILE NUMBER{" "}
                        </div>
                        <LoginInput
                          type="number"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          step="1"
                          className="input-text"
                          placeholder="Mobile Number"
                          value={xgate_inputs?.mobile ?? ""}
                          onChange={(e) =>
                            dispatch(
                              changeXgateInput("mobile", e?.target?.value)
                            )
                          }
                        />
                        <div className="or">
                          {" "}
                          <span> Or </span>{" "}
                        </div>
                        <div className="title-heading bold">LOGIN BY EMAIL</div>
                        <LoginInput
                          placeholder="Email"
                          value={xgate_inputs?.email ?? ""}
                          onChange={(e) =>
                            dispatch(
                              changeXgateInput("email", e?.target?.value)
                            )
                          }
                        />
                        <div className="or">
                          {" "}
                          <span> Or </span>{" "}
                        </div>
                        <div className="title-heading bold">
                          LOGIN BY LOYALTY MEMBER ID
                        </div>
                        <LoginInput
                          type="number"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          step="1"
                          className="input-text"
                          placeholder="Loyalty Member ID"
                          value={xgate_inputs?.card ?? ""}
                          onChange={(e) =>
                            dispatch(changeXgateInput("card", e?.target?.value))
                          }
                        />

                        <div className="resend_row mt-4">
                          <button
                            type="submit"
                            className="btn green-btn new-green"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </>
                  )} */}
                  <div className="or">
                    {" "}
                    <span> Or </span>{" "}
                  </div>
                  <div className="guest_login mt-3 mb-5">
                    <a
                      href="javascript:void(0)"
                      className="btn green-btn new-green"
                      onClick={guestSubmitHandler}
                    >
                      Continue as a guest
                    </a>
                  </div>
                </>
              )}
              {loyaltyPackage?.code == "acsentis" && <LoginAcentis />}
            </>
          ) : (
            <div className="guest_login my-3">
              <a
                href="javascript:void(0)"
                className="btn green-btn new-green"
                onClick={guestSubmitHandler}
              >
                Continue as a guest
              </a>
            </div>
          )}
        </div>
        <Modal
          show={tableReducer?.show_table_not_found_modal}
          title="Sorry, this outlet is not ready to take your order at this time. Please ask your server for assistance."
        />
        <Modal
          show={showTermsPopup}
          title={""}
          extraClass={"top50"}
          modalbody={<Terms />}
          OnSubmit={() => setShowTermsPopup(false)}
          submitBtnText="ok"
        />
        <Motion
          defaultStyle={{ x: 100 }}
          style={{
            x: spring(customerType === "new" ? 0 : 100, {
              precision: 1,
              stiffness: 300,
              damping: 44,
            }),
          }}
        >
          {({ x }) => {
            return (
              <LoginNewCustomer
                style={{
                  right: `-${x}%`,
                }}
              />
            );
          }}
        </Motion>
        <Motion
          defaultStyle={{ x: 100 }}
          style={{
            x: spring(customerType === "exist" ? 0 : 100, {
              precision: 1,
              stiffness: 300,
              damping: 44,
            }),
          }}
        >
          {({ x }) => {
            return (
              <LoginExistCustomer
                style={{
                  right: `-${x}%`,
                }}
              />
            );
          }}
        </Motion>
      </>
    </LoginLayout>
  );
};
export default Login;
