import { put, takeLatest, select } from "redux-saga/effects";
import {
  ORDER_PLACE_DETAIL,
  ORDER_PLACE_DETAIL_RECEIVED,
  EMPTY_ORDER_PLACE_DETAIL,
  CART_DETAIL_STATUS_CHANGED,
  EMPTY_CART_DETAIL,
  LOADING_STATUS,
  GET_ORDER_HISTORY_DETAIL,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_ORDER_HISTORY_DETAIL,
  DELETE_RECENT_AND_CART_ORDER,
  EMPTY_VOUCHER_LIST_DETAIL,
  GET_VOUCHER_LIST_DETAIL,
  VOUCHER_LIST_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  RECENT_ORDER_ID_RECEIVED,
  GET_APPLY_VOUCHER_DETAIL,
  APPLY_VOUCHER_DETAIL_RECEIVED,
  EMPTY_APPLY_VOUCHER_DETAIL,
  AVOCADO_MEMBER_LIST_RECEIVED,
  ADD_DETAIL_CARD_TOKEN,
  EMPTY_REORDER_ORDER_IDS,
  REORDER_ORDER_IDS_RECEIVED,
  POS_SOCKET_ORDER_RECIEVE,
} from "../actions/types";
import {
  showNotificationWithTimeout,
  changeOrderSocketEmit,
  changeTipDetail,
  changeStatusCartDetail,
  changePosCartDetail,
  emptyPosCartDetail,
  toggleShowCompletePage,
  changeSessionExpiredPage,
} from "../actions";
import {
  avocadoAuthToken,
  getCartSubmittedItems,
  getDeviceId,
  getPosCartSubmittedItems,
  getReOrderItemIds,
  orderHistoryApi,
} from "../../helpers";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;

// Advocado API
const ad_api_url = process?.env?.REACT_APP_AVOCADO_API_URL;
const ad_username = process?.env?.REACT_APP_AVOCADO_API_USERNAME;
const ad_password = process?.env?.REACT_APP_AVOCADO_API_PASSWORD;

let myHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
};

function* orderPlace(action) {
  console.log("base_url", base_url);
  console.log("action.body", action?.body);
  yield put({ type: EMPTY_ORDER_PLACE_DETAIL });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;
  console.log("myHeaders", myHeaders);
  const json = yield fetch(`${base_url}/qr_code_apis/submitOrder`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action?.body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("orderPlace failed : " + error.message));
  if (json?.status && json?.data) {
    // yield put({ type: EMPTY_CART_DETAIL });
    if (action.onlyTip !== true) {
      yield put({ type: CART_DETAIL_STATUS_CHANGED });
    }
    yield put(changeTipDetail(action?.body?.tips ?? 0));
    yield put({ type: ORDER_PLACE_DETAIL_RECEIVED, payload: json?.data });
    yield put({
      type: RECENT_ORDER_ID_RECEIVED,
      payload: json?.data?.["order_list"]?.[0]?.order_offline_id ?? "",
    });
    yield put(changeOrderSocketEmit(true));
    if (action.onlyTip === true) {
      yield put(
        showNotificationWithTimeout(
          `Tip has been updated to the order successfully`,
          "success"
        )
      );
    } else {
      yield put(
        showNotificationWithTimeout(
          `${json?.message ? json.message : "success"}`,
          "success"
        )
      );
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `orderPlace error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* orderHistory(action) {
  console.log("base_url", base_url);
  console.log("action.bodyyyyyy", action);
  yield put({ type: EMPTY_REORDER_ORDER_IDS });
  yield put({ type: EMPTY_ORDER_HISTORY_DETAIL });
  yield put({ type: EMPTY_VOUCHER_LIST_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const customerId = yield select(
    (state) => state?.authDetailReducer?.user_detail?.id
  );
  const user_detail = yield select(
    (state) => state?.authDetailReducer?.user_detail
  );
  const avocadoDetail = yield select(
    (state) => state?.authDetailReducer?.avocado_detail
  );
  const deviceId = yield getDeviceId();
  const orderHistoryData = yield orderHistoryApi({
    deviceId: deviceId,
    token: token,
    merchantId: action?.merchantId ?? "",
    customerId: customerId ?? "",
  });
  if (orderHistoryData?.status && orderHistoryData?.data) {
    yield put({
      type: ORDER_HISTORY_DETAIL_RECEIVED,
      payload: orderHistoryData?.data,
    });
    const reOrderIds = getReOrderItemIds(orderHistoryData?.data);
    if (reOrderIds.length > 0) {
      yield put({ type: REORDER_ORDER_IDS_RECEIVED, payload: reOrderIds });
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          orderHistoryData?.message ? orderHistoryData.message : defaultErrorMsg
        }`,
        "error"
      )
    );
    console.log(
      `ORDER_HISTORY error : ${
        orderHistoryData?.message ? orderHistoryData.message : defaultErrorMsg
      }`
    );
  }
  if (avocadoDetail !== null) {
    const authToken = yield avocadoAuthToken();
    console.log("authTokenDetail:", authToken);
    if (authToken?.access_token?.value) {
      myHeaders.Authorization = "Bearer " + authToken?.access_token?.value;
      const memberShipList = yield fetch(`${ad_api_url}/v1.1/memberships`, {
        mode: "cors",
        headers: new Headers(myHeaders),
      })
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) =>
          console.log("avocado_membership_list failed : " + error.message)
        );
      if (
        memberShipList?.code == 200 &&
        memberShipList?.data?.tiers &&
        memberShipList?.data?.tiers.length > 0
      ) {
        yield put({
          type: AVOCADO_MEMBER_LIST_RECEIVED,
          payload: memberShipList?.data?.tiers,
        });
      }
    }
  }
  if (user_detail?.CardNo) {
    myHeaders.token = token;
    const voucherList = yield fetch(`${base_url}/ascentis/get_vouchers`, {
      method: "POST",
      mode: "cors",
      headers: new Headers(myHeaders),
      body: JSON.stringify({
        CardNo: user_detail?.CardNo,
        merchant_id: action?.merchantId ?? "",
      }),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => console.log("VOUCHER_LIST failed : " + error.message));
    if (voucherList?.status && voucherList?.data) {
      // yield put({ type: EMPTY_CART_DETAIL });
      yield put({
        type: VOUCHER_LIST_DETAIL_RECEIVED,
        payload: voucherList?.data,
      });
    } else {
      yield put(
        showNotificationWithTimeout(
          `${voucherList?.message ? voucherList.message : defaultErrorMsg}`,
          "error"
        )
      );
      console.log(
        `VOUCHER_LIST error : ${
          voucherList?.message ? voucherList.message : defaultErrorMsg
        }`
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

function* redeemVoucher(action) {
  console.log("base_url", base_url);
  console.log("action.body", action?.body);
  // yield put({ type: EMPTY_APPLY_VOUCHER_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const user_detail = yield select(
    (state) => state?.authDetailReducer?.user_detail
  );
  const cart_detail = yield select(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  const applied_voucher = yield select(
    (state) => state?.cartDetailReducer?.applied_voucher
  );

  const TransactDetailLists =
    cart_detail?.length > 0
      ? cart_detail?.map((item) => {
          return {
            Category_Code: item?.sub_category_id ?? "",
            ItemCode: item?.id ?? "",
            Description: item?.name ?? "",
            Qty: item?.quantity ?? "",
            Price: item?.menu_item_price ?? 0,
            Points: null,
            DiscountPer: 0,
            Nett: item?.total ?? 0,
            LineNo: 1,
          };
        })
      : [];

  myHeaders.token = token;
  console.log("myHeaders", myHeaders);

  if (user_detail?.CardNo) {
    const getVoucherType = yield fetch(
      `${base_url}/ascentis/get_voucher_type`,
      {
        method: "POST",
        mode: "cors",
        headers: new Headers(myHeaders),
        body: JSON.stringify({
          CardNo: user_detail?.CardNo,
          voucherTypeCode: action?.voucher?.VoucherTypeCode,
          merchant_id: action?.merchantId ?? "",
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => console.log("APPLY_VOUCHER failed : " + error.message));
    if (getVoucherType?.data?.access_token) {
      let cardAccToken = getVoucherType?.data?.access_token;
      yield put({
        type: ADD_DETAIL_CARD_TOKEN,
        payload: cardAccToken.replace("Bearer ", ""),
      });
    }
    if (
      getVoucherType?.status &&
      getVoucherType?.data?.VoucherTypeLists &&
      getVoucherType?.data?.VoucherTypeLists.length > 0
    ) {
      const voucherListArr = getVoucherType?.data?.VoucherTypeLists.map(
        (voucherItem) => {
          voucherItem.VoucherNo = action?.voucher?.VoucherNo;
          return voucherItem;
        }
      );
      yield put({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload:
          applied_voucher.length > 0
            ? [...applied_voucher, ...voucherListArr]
            : voucherListArr,
      });
      const voucherTypeValue = parseFloat(
        getVoucherType?.data?.VoucherTypeLists?.[0].TypeValue ?? 0
      );
      /* NOT IN USE */
      // const PointsRedemption = {
      //   DollarToPointsRatio: "1:10:10:10",
      //   PointsToBeDeducted: voucherTypeValue,
      //   PointsUsage: 2,
      //   GivenRebateValue: 3,
      //   PointsToBeDeductedFromGrace: 0,
      // };
      // const applyVoucherList = yield fetch(`${base_url}/ascentis/sales`, {
      //   method: "POST",
      //   mode: "cors",
      //   headers: new Headers(myHeaders),
      //   body: JSON.stringify({
      //     CardNo: user_detail?.CardNo,
      //     VoucherNo: action?.voucher?.VoucherNo,
      //     TransactDetailLists: TransactDetailLists,
      //     PointsRedemption: PointsRedemption,
      //   }),
      // })
      //   .then((response) => response.json())
      //   .then((json) => json)
      //   .catch((error) =>
      //     console.log("APPLY_VOUCHER failed : " + error.message)
      //   );
      // if (applyVoucherList?.status && applyVoucherList?.data) {
      //   // yield put({ type: EMPTY_CART_DETAIL });
      //   yield put({
      //     type: APPLY_VOUCHER_DETAIL_RECEIVED,
      //     payload: action?.voucher,
      //   });
      // } else {
      //   yield put(
      //     showNotificationWithTimeout(
      //       `${
      //         applyVoucherList?.message
      //           ? applyVoucherList.message
      //           : defaultErrorMsg
      //       }`,
      //       "error"
      //     )
      //   );
      //   console.log(
      //     `APPLY_VOUCHER error : ${
      //       applyVoucherList?.message
      //         ? applyVoucherList.message
      //         : defaultErrorMsg
      //     }`
      //   );
      // }
    } else {
      yield put(
        showNotificationWithTimeout(
          `${
            getVoucherType?.message ? getVoucherType.message : defaultErrorMsg
          }`,
          "error"
        )
      );
      console.log(
        `APPLY_VOUCHER error : ${
          getVoucherType?.message ? getVoucherType.message : defaultErrorMsg
        }`
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

function* deletRecentAndCartOrder(action) {
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_CART_DETAIL });
  yield put({ type: EMPTY_ORDER_PLACE_DETAIL });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: false });
}
function* posOrderRecieveDetail(action) {
  const table_detail = yield select(
    (state) => state?.tableDetailReducer?.table_detail
  );
  yield put({ type: LOADING_STATUS, status: true });
  console.log("order_socket_pos_order", action.pos_order);
  yield put(changeTipDetail(action.pos_order?.tips ?? 0));
  const items = getCartSubmittedItems(action.pos_order, table_detail);
  console.log("ordersssss===>items", items);
  // debugger;
  if (items?.length > 0) {
    yield put(changeStatusCartDetail(items));
  }
  const posItems = getPosCartSubmittedItems(action.pos_order, table_detail);
  if (posItems?.length > 0) {
    yield put(changePosCartDetail(posItems));
  } else {
    yield put(emptyPosCartDetail());
  }
  if (action.pos_order?.order_status == "complete") {
    yield put(toggleShowCompletePage(true));
  }
  if (
    action.pos_order?.table_no !== table_detail?.PosTableMaster?.table_number ||
    action.pos_order?.order_status == "cancelled"
  ) {
    console.log("ordersssss===>Session Expireddd");
    yield put(changeSessionExpiredPage(true));
  }
  yield put({ type: LOADING_STATUS, status: false });
}

export default function* cartDetailActionWatcher() {
  yield takeLatest(ORDER_PLACE_DETAIL, orderPlace);
  yield takeLatest(GET_ORDER_HISTORY_DETAIL, orderHistory);
  yield takeLatest(GET_APPLY_VOUCHER_DETAIL, redeemVoucher);
  yield takeLatest(DELETE_RECENT_AND_CART_ORDER, deletRecentAndCartOrder);
  yield takeLatest(POS_SOCKET_ORDER_RECIEVE, posOrderRecieveDetail);
}
